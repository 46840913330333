var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('sidebar-fixed-toggle-button'),_c('side-bar',{directives:[{name:"show",rawName:"v-show",value:(this.$route.path !== '/consultant'),expression:"this.$route.path !== '/consultant'"}],attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.home'),
          icon: 'fa fa-home',
          path: _vm.userLogged.isConsultant ? '/consultant' : '/home'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.locations'),
          icon: 'fa fa-location-dot',
          path: _vm.userLogged.isConsultant
            ? `/companies/${_vm.userLogged.compId}/locations`
            : '/locations'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.accounts'),
          icon: 'fa fa-laptop',
          path: _vm.userLogged.isConsultant
            ? `/companies/${_vm.userLogged.compId}/accounts`
            : '/accounts'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.bills'),
          icon: 'fa fa-envelope',
          path: _vm.userLogged.isConsultant
            ? `/companies/${_vm.userLogged.compId}/bills`
            : '/bills'
        }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.userLogged.isConsultant ||
          (_vm.isAlertsClient && !_vm.userLogged.isLimitedClient)
        ),expression:"\n          userLogged.isConsultant ||\n          (isAlertsClient && !userLogged.isLimitedClient)\n        "}],attrs:{"link":{
          name: _vm.$t('sidebar.alerts'),
          icon: 'fa fa-bell',
          path: _vm.alertsPath
        }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(!_vm.userLogged.isLimitedClient),expression:"!userLogged.isLimitedClient"}],attrs:{"link":{
          name: _vm.$t('sidebar.contracts'),
          icon: 'fa fa-file',
          path: _vm.userLogged.isConsultant
            ? `/companies/${_vm.userLogged.compId}/contracts`
            : '/contracts'
        }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.userLogged.isConsultant ||
          (_vm.isBudgetsClient && !_vm.userLogged.isLimitedClient)
        ),expression:"\n          userLogged.isConsultant ||\n          (isBudgetsClient && !userLogged.isLimitedClient)\n        "}],attrs:{"link":{
          name: _vm.$t('sidebar.budgets'),
          icon: 'fa fa-scale-balanced',
          path: _vm.userLogged.isConsultant
            ? `/companies/${_vm.userLogged.compId}/budgets`
            : '/budgets'
        }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.showApPage),expression:"showApPage"}],attrs:{"link":{
          name: _vm.$t('sidebar.ap'),
          icon: 'fa fa-dollar-sign sidebar-bill-deliveries-icon',
          path: _vm.userLogged.isConsultant
            ? `/companies/${_vm.userLogged.compId}/billdeliveries`
            : '/billdeliveries'
        }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isBillPayClient && !_vm.userLogged.isLimitedClient),expression:"isBillPayClient && !userLogged.isLimitedClient"}],attrs:{"link":{
          name: _vm.$t('sidebar.billpay'),
          icon: 'fa fa-money-check',
          path: _vm.userLogged.isConsultant
            ? `/companies/${_vm.userLogged.compId}/billpay`
            : '/billpay'
        }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(!_vm.userLogged.isLimitedClient),expression:"!userLogged.isLimitedClient"}],attrs:{"link":{
          name: _vm.$t('sidebar.reports'),
          icon: 'fa fa-chart-simple',
          path: _vm.userLogged.isConsultant
            ? `/companies/${_vm.userLogged.compId}/reports`
            : '/reports'
        }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpenCloseClient && !_vm.userLogged.isLimitedClient),expression:"isOpenCloseClient && !userLogged.isLimitedClient"}],attrs:{"link":{
          name: _vm.$t('sidebar.opencloseaccounts'),
          icon: 'fas fa-clipboard-list-check',
          path: _vm.userLogged.isConsultant
            ? `/companies/${_vm.userLogged.compId}/opencloseaccounts`
            : '/opencloseaccounts'
        }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnergyStarClient),expression:"isEnergyStarClient"}],attrs:{"link":{
          name: _vm.$t('sidebar.companyenergystar'),
          icon: 'fas fa-star',
          path: _vm.userLogged.isConsultant
            ? `/companies/${_vm.userLogged.compId}/companyEnergyStar`
            : '/companyEnergyStar'
        }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.companyHasSupportAccess),expression:"companyHasSupportAccess"}],attrs:{"link":{
          name: _vm.$t('sidebar.companysupport'),
          icon: 'fa fa-solid fa-user-headset',
          path: _vm.userLogged.isConsultant
            ? `/companies/${_vm.userLogged.compId}/tickets`
            : '/tickets'
        }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.userLogged.isSuperConsultant),expression:"userLogged.isSuperConsultant"}],attrs:{"link":{
          name: _vm.$t('sidebar.users'),
          icon: 'fa fa-users',
          path: `/companies/${_vm.userLogged.compId}/users`
        }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.userLogged.isConsultant),expression:"userLogged.isConsultant"}],attrs:{"link":{
          name: _vm.$t('sidebar.inviteuser'),
          icon: 'fa fa-user-plus',
          path: _vm.userLogged.isConsultant
            ? `/companies/${_vm.userLogged.compId}/inviteuser`
            : '/inviteuser'
        }}})],1)],2),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }