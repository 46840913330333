import axios from 'axios';

export function _uuid() {
  var d = Date.now();
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now(); //use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function exportCsv(data, columns, title) {
  return;
}

export function deepCopy(obj) {
  let obj_cp = JSON.parse(JSON.stringify(obj));
  return obj_cp;
}

export function debounce(fun, t = 0) {
  let st;
  if (typeof fun !== 'function') {
    throw new TypeError('Not a function');
  }
  return function () {
    if (st) {
      clearTimeout(st);
    }
    st = setTimeout(() => {
      fun.apply(this, arguments);
    }, t);
  };
}

export async function saveGridSettings(
  updatedSettings,
  gridName,
  checkList,
  isLoading
) {
  await axios
    .post('/user/saveGridSettings', updatedSettings)
    .then((res) => {
      if (res.status === 200) {
        const settings = JSON.parse(localStorage.getItem('gridSettings'));
        settings[gridName] = checkList;
        localStorage.setItem('gridSettings', JSON.stringify(settings));
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
