export function capitalizeFirstLetter(item) {
  if (!Boolean(item)) {
    return '';
  }
  return `${item[0].toUpperCase()}${item.substring(1)}`;
}

export function checkBlank(value) {
  return value === null || value === undefined || value === '';
}

//This would function would format the data into a Date by default unless isString = true
export function formatBillMonth(billMonth, isString = false) {
  if (!Boolean(billMonth)) {
    return '';
  }
  return isString
    ? new Date(billMonth).toLocaleString('default', {
        month: 'short',
        year: 'numeric'
      })
    : new Date(billMonth);
}

export function formatGridText(text) {
  if (!Boolean(text)) {
    return '';
  }
  return text
    .split(',')
    .map((value) => {
      return value
        .split('_')
        .map((subValue) => {
          subValue = subValue.replace(' ', '');
          return `${capitalizeFirstLetter(subValue)}`;
        })
        .join(' ');
    })
    .join(',');
}

// Convert string to Camel Case with space
export function camelCaseWithSpace(inputString) {
  return inputString.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
    return index == 0 ? word.toLowerCase() : word.toUpperCase();
  });
}

// Convert string to Camel Case with space
export function pascalCaseWithSpace(inputString) {
  return inputString.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
    return word.toUpperCase();
  });
}

export function formatSortingBillMonth(billMonth) {
  if (!!billMonth) {
    billMonth = new Date(billMonth);
    return `${billMonth.getUTCFullYear()}-${
      billMonth.getUTCMonth() + 1
    }-${billMonth.getUTCDate()}`;
  }
}

//Sorting Reports from  OLDEST TO NEWEST
export function sortReportsByDate(data, reportType) {
  if (reportType === 'Usages' || reportType === 'Sustainability') {
    const sortedData = data.sort((first, second) => {
      first.bill_month = formatBillMonth(first.bill_month, true);
      second.bill_month = formatBillMonth(second.bill_month, true);
      return new Date(first.bill_month) - new Date(second.bill_month);
    });
    return sortedData;
  } else if (reportType === 'Charges') {
    const sortedData = data.sort((first, second) => {
      return new Date(first.invoice_date) - new Date(second.invoice_date);
    });
    return sortedData;
  } else if (reportType === 'Energy Star Meter Usages') {
    const sortedData = data.sort((first, second) => {
      return new Date(first.start_date) - new Date(second.start_date);
    });
    return sortedData;
  } else if (reportType === 'Energy Star Location Meters') {
    const sortedData = data.sort((first, second) => {
      return (
        new Date(first.date_meter_became_active) -
        new Date(second.date_meter_became_active)
      );
    });
    return sortedData;
  } else {
    return data;
  }
}

export function sortStrings(firstString, secondString) {
  return firstString < secondString ? -1 : firstString > secondString ? 1 : 0;
}

// Michael Austin - 9/2/2021: we are not using UTC time on the backend or DB, we're converting the date to UTC here
// because it keeps the date consistent with user input / invoice data regardless of time zone or local time
export function formatDate(date) {
  if (!!date) {
    //Case when date doesn't have any formatting and looks like mmDDyyyy (8 character length)
    if (date.length === 8) {
      return date.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
    }
    date = new Date(date);
    return `${
      date.getUTCMonth() + 1
    }/${date.getUTCDate()}/${date.getUTCFullYear()}`;
  }
}

//Function to convert date into yyyy-MM-DD format
export function parseDateToYearMonthDayFormat(date) {
  if (!Boolean(date)) {
    return;
  }
  return [
    date.getFullYear(),
    padToTwoDigits(date.getMonth() + 1),
    padToTwoDigits(date.getUTCDate())
  ].join('-');
}

export function setLocalTime(date, format = false) {
  return format
    ? new Date(`${date}Z`)
        .toLocaleString('default', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        })
        .replace(',', ' at')
    : new Date(`${date}Z`);
}

// Set date as: "MM/DD/YYYY, hh:mm"
export function setLocalUSTime(date) {
  return new Date(date).toLocaleString('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
}

export function formatCurrency(number, currencyType) {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyType
  }).format(number);
}

export function formatForGrid(value, dollar = '') {
  if (checkBlank(value)) return;
  return Number(value) < 0
    ? `-${dollar}${parseFloat(Math.abs(Number(value)).toFixed(2))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    : `${dollar}${parseFloat(Number(value).toFixed(2))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export function assignCurrencySign(value, sign) {
  if (checkBlank(value)) return;
  return sign + value;
}

export function formatServiceType(serviceType) {
  if (!Boolean(serviceType)) {
    return '';
  }
  switch (serviceType) {
    case 'electric':
      return 'Electricity';
    case 'natural_gas':
      return 'Gas';
    case 'water':
      return 'Water';
    case 'trash':
      return 'Trash';
    case 'telecom':
      return 'Telecom';
    case 'sewer':
      return 'Sewer';
    case 'steam':
      return 'Steam';
    case 'drag_reducing_agent':
      return 'Drag Reducing Agent';
    default:
      return serviceType;
  }
}

export function convertToNumNoPunctuation(value) {
  if (!Boolean(value) || typeof value !== 'string') {
    return null;
  }
  return convertToNumber(value.replace(/\$|,/gi, ''));
}

export function convertToNumber(value) {
  if (!Boolean(Number(value))) {
    return value;
  }
  return Number(value);
}

// Remove comma and '$' symbol
export function sanitizeString(value) {
  return value.replace(/\$|,/gi, '');
}

// Returns number if input is in number or string form; else returns 0
export function convertInputToNumber(value) {
  if (typeof value === 'number') return value;

  if (typeof value === 'string') {
    const sanitizedString = sanitizeString(value);
    return Boolean(Number(sanitizedString)) ? Number(sanitizedString) : 0;
  }

  return 0;
}

export function convertExportDataToNumber(value) {
  return value === null ? 'NULL' : value === 0 ? '0' : Number(value);
}

export function convertExportDataToDate(value) {
  return !Boolean(value) || value.toLowerCase() === 'null'
    ? value
    : formatDate(value);
}

export function convertExportDataToString(value) {
  return !Boolean(value) ? value : value.toString(value);
}

export function convertExportDataToStringStrict(value) {
  return value === null || value === undefined || value === ''
    ? value
    : value.toString();
}

export function convertExportDataToNumNoPunctuation(value) {
  if (!Boolean(value)) return value;

  if (typeof value !== 'string') {
    value = value.toString();
  }
  return Number(value.replace(/\$|,/gi, ''));
}

export function convertExportDataToYesNo(value) {
  return Boolean(value) ? 'Yes' : 'No';
}

export function convertToNumKeepValue(value) {
  if (!Boolean(Number(value))) {
    return value;
  }
  return Number(value);
}

export function getServiceIcon(item, serviceType) {
  if (item[serviceType] === null) {
    delete item[serviceType];
  } else {
    switch (serviceType) {
      case 'electric':
        item.electricIcon = 'fas fa-bolt';
        break;
      case 'naturalGas':
        item.gasIcon = 'fas fa-fire';
        break;
      case 'water':
        item.waterIcon = 'fad fa-tint';
        break;
      case 'sewer':
        item.sewerIcon = 'fad fa-manhole';
        break;
      case 'telecom':
        item.telecomIcon = 'fas fa-phone';
        break;
      case 'trash':
        item.trashIcon = 'fad fa-trash-can';
        break;
      case 'taxesFees':
        item.taxesFeesIcon = 'fas fa-dollar-sign taxes-fees';
        break;
      case 'totalCharges':
        item.totalChargesIcon = 'fas fa-dollar-sign total-charges';
        break;
      case 'dragReducingAgent':
        item.dragReducingAgentIcon = 'fa-solid fa-flask';
        break;
    }
  }
}

export function getStatusIcon(item) {
  let status = {
    good: {
      statusText: '',
      statusIcon: 'fas fa-check',
      statusColor: { color: '#99c23c' }
    },
    open: {
      statusIcon: 'fas fa-exclamation',
      statusText: 'Open',
      statusColor: { color: '#e35b5f' }
    },
    pending: {
      statusIcon: 'fas fa-minus',
      statusText: 'Pending',
      statusColor: { color: '#ffcc00' }
    },
    closed: {
      statusIcon: 'fas fa-check',
      statusText: 'Closed',
      statusColor: { color: '#99c23d' }
    },
    'closed with savings': {
      statusIcon: 'fa fa-dollar-sign',
      statusText: 'Closed with Savings',
      statusColor: { color: '#9fe2bf' }
    },
    'pending - client input': {
      statusIcon: 'fas fa-minus',
      statusText: 'Pending - Client Input',
      statusColor: { color: '#ffcc00' }
    },
    'closed - public': {
      statusIcon: 'fas fa-check',
      statusText: 'Closed',
      statusColor: { color: '#99c23d' }
    }
  };
  try {
    item.statusText = status[item.status].statusText;
    item.statusIcon = status[item.status].statusIcon;
    item.statusColor = status[item.status].statusColor;
  } catch (err) {
    console.log(err);
  }
  return item;
}

export function getMonthDifference(dateFrom, dateTo) {
  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear()) +
    1
  );
}

export function getDayDifference(dateFrom, dateTo) {
  return Math.round(
    Math.abs((new Date(dateFrom) - new Date(dateTo)) / (24 * 60 * 60 * 1000))
  );
}

export function replaceCollectionType(collectionType, icon = false) {
  if (!Boolean(collectionType)) {
    return '';
  }
  return icon
    ? collectionType
        .replace('EBILL-PAPERLESS', 'fad fa-leaf')
        .replace('PAPER', 'fad fa-file collection-type-file')
        .replace('EMAIL', 'fad fa-envelope collection-type-envelope')
        .replace('MANUAL EBILL', 'fad fa-laptop collection-type-laptop')
        .split(',')
    : collectionType
        .replace('EBILL-PAPERLESS', 'E-Bill Paperless')
        .replace('PAPER', 'Paper')
        .replace('EMAIL', 'Email')
        .replace('MANUAL EBILL', 'Manual E-Bill');
}

export function replaceServiceType(serviceType, icon = false) {
  if (!Boolean(serviceType)) {
    return '';
  }
  return icon
    ? serviceType
        .replace('electric', 'fas fa-bolt')
        .replace('natural_gas', 'fad fa-fire')
        .replace('natural gas', 'fad fa-fire')
        .replace('water', 'fad fa-tint')
        .replace('telecom', 'fas fa-phone')
        .replace('trash', 'fad fa-trash-can')
        .replace('sewer', 'fad fa-manhole')
        .replace('sanitation', 'fas fa-soap')
        .replace('lighting', 'fas fa-lightbulb')
        .replace('irrigation', 'fas fa-shower')
        .replace('steam', 'fas fa-cloud')
        .replace('district_heat', 'fas fa-thermometer-full')
        .replace('propane', 'fas fa-rocket')
        .replace('fire_protection', 'fas fa-fire-extinguisher')
        .replace('internet', 'fas fa-wifi')
        .replace('landline_phone', 'fas fa-phone')
        .replace('television', 'fas fa-tv')
        .replace('drag_reducing_agent', 'fa-solid fa-flask')
        .split(',')
    : serviceType
        .replace('electric', 'Electricity')
        .replace('natural_gas', 'Gas')
        .replace('natural gas', 'Gas')
        .replace('water', 'Water')
        .replace('telecom', 'Telecom')
        .replace('trash', 'Trash')
        .replace('sewer', 'Sewer')
        .replace('sanitation', 'Sanitation')
        .replace('lighting', 'Lighting')
        .replace('irrigation', 'Irrigation')
        .replace('steam', 'Steam')
        .replace('district_heat', 'District Heat')
        .replace('propane', 'Propane')
        .replace('fire_protection', 'Fire Protection')
        .replace('internet', 'Internet')
        .replace('landline_phone', 'Landline Phone')
        .replace('television', 'Television')
        .replace('drag_reducing_agent', 'Drag Reducing Agent');
}

export function zeroCheck(value) {
  return value === 0 ? 1 : value;
}

//Function to Set Alert Status on the alert grid based on different percentage variances.
export function setGridAlertStatusToOpen(item) {
  //store all the variances in an array.
  let variances = [
    item.variancePercentageChargeMoM,
    item.variancePercentageChargeYoY,
    item.variancePercentageUsageMoM,
    item.variancePercentageUsageYoY
  ];

  return (
    Boolean(item.lateFee) ||
    isVarianceCountGreaterThanOne(variances) ||
    (item.variancePercentageChargeMoM &&
      item.variancePercentageChargeMoM > 100) ||
    (item.variancePercentageChargeYoY &&
      item.variancePercentageChargeYoY > 100) ||
    (item.variancePercentageUsageMoM &&
      item.variancePercentageUsageMoM > 100) ||
    (item.variancePercentageUsageYoY && item.variancePercentageUsageYoY > 100)
  );
}

export function truncateEsiIds(text, length) {
  if (!Boolean(text)) {
    return '';
  }
  return text.split(' ').length > 1 || text.length < length
    ? text
    : `${text.substring(0, length)}...`;
}

export function getMonthsBetween(startDate, endDate) {
  let months = [];
  let current = startDate.split('/');
  let currentMonth = current[0];
  let date;

  while (true) {
    if (
      isSameMonthAndYear(
        stringToDate(startDate),
        generateDate(current[2], currentMonth)
      )
    ) {
      months.push({
        date: startDate,
        monthYear: getMonthYear(startDate, 'short')
      });
    } else if (
      isSameMonthAndYear(
        stringToDate(endDate),
        generateDate(current[2], currentMonth)
      )
    ) {
      months.push({
        date: endDate,
        monthYear: getMonthYear(endDate, 'short')
      });
      break;
    } else if (
      !isSameMonthAndYear(
        stringToDate(startDate),
        generateDate(current[2], currentMonth)
      ) &&
      !isSameMonthAndYear(
        stringToDate(endDate),
        generateDate(current[2], currentMonth)
      )
    ) {
      date = generateDate(current[2], currentMonth);
      months.push({
        date: date,
        monthYear: getMonthYear(date, 'short')
      });
    }
    currentMonth++;
  }
  return months;
}

export function generateDate(year, month, day = 1) {
  let date = new Date(year, month - 1, day);

  return [
    formatDateNumber(date.getMonth() + 1),
    formatDateNumber(date.getDate()),
    formatDateNumber(date.getFullYear())
  ].join('/');
}

/**
 * Helper function for formatting a date into a formatted date
 * @param {date} date argument
 * @param {separator} argument passed to separate MM, DD, and YYYY.
 * @param {defaultFormat} argument passed to order MM, DD, and YYYY.
 * @returns {formatted date} a date with the required format based on the passed separator and defaultFormat args.
 */
export function stringToDate(date, separator = '/', defaultFormat = 0) {
  date = new Date(date);
  if (!Boolean(defaultFormat)) {
    return [
      formatDateNumber(date.getMonth() + 1),
      formatDateNumber(date.getDate()),
      formatDateNumber(date.getFullYear())
    ].join(separator);
  } else {
    return [
      formatDateNumber(date.getFullYear()),
      formatDateNumber(date.getMonth() + 1),
      formatDateNumber(date.getDate())
    ].join(separator);
  }
}

export function getMonthYear(date, monthType) {
  return new Date(date).toLocaleString('en-US', {
    month: monthType,
    year: 'numeric'
  });
}

export function extractYear(date) {
  return new Date(date).getFullYear();
}
export function extractMonth(date) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  return months[new Date(date).getMonth()];
}

// Get the percentage dfference between the startDate and endDate
export function getProgressBetweenTwoDates(startDate, endDate) {
  const total = +endDate - +startDate;
  const elapsedTime = Date.now() - startDate;
  return Math.round((elapsedTime / total) * 100);
}

// Get total days of current month
export function totalDaysInMonth() {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  ).getDate();
}

export function formatDateNumber(number) {
  let formattedNumber = number.toString();
  if (formattedNumber.length === 1) {
    formattedNumber = padToTwoDigits(number);
  }
  return formattedNumber;
}

export function isSameMonthAndYear(date1, date2 = null) {
  let firstDate = new Date(date1);
  let secondDate = !Boolean(date2) ? new Date() : new Date(date2);

  return (
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getFullYear() === secondDate.getFullYear()
  );
}

export function isDateLessThanOrEqualToCurrentDate(date1, date2 = null) {
  let firstDate = new Date(date1);
  let secondDate = !Boolean(date2) ? new Date() : new Date(date2);
  return firstDate <= secondDate;
}

export function isMonthGreaterEqualTo(date1, date2 = null) {
  let firstDate = new Date(date1);
  let secondDate = !Boolean(date2) ? new Date() : new Date(date2);

  return (
    firstDate.getMonth() >= secondDate.getMonth() &&
    firstDate.getFullYear() > secondDate.getFullYear()
  );
}

// Format string from camelCase -> Title Case
export function formatCamelCaseToTitleCase(stringValue) {
  try {
    if (!stringValue) return;
    // Regex to format camelCase -> camel Case
    const result = stringValue.replace(/([A-Z])/g, ' $1');
    //  performs string manipulation to capitalize the first character of the string
    return result.charAt(0).toUpperCase() + result.slice(1);
  } catch (err) {
    throw new TypeError('Failed to format value', err);
  }
}
// Removes header columns that are not required for Excel exports
export function formatPricingDataForExport(data) {
  let filtered = Object.entries(data).filter(([key, value]) => {
    return (
      value !== 'color' &&
      value !== 'Electricity' &&
      value !== 'Lighting' &&
      value !== 'Water' &&
      value !== 'Sewage' &&
      value !== 'Irrigation' &&
      value !== 'Natural Gas' &&
      value !== 'Propane' &&
      value !== 'Drag Reducing Agent' &&
      value !== 'Steam'
    );
  });
  return Object.fromEntries(filtered);
}

/**
 * A helper function for formatting the vendorName string into a formatted vendorName string
 * @param {string} vendorName  The vendorName string
 * @returns {string}           The formatted vendorName string which removes the words after the whitespace (if exists) or
 *                             The vendorName string if the string is one word only (does not have a whitespace).
 */
export function formatVendorName(vendorName) {
  return hasWhitespace(vendorName)
    ? vendorName.substring(0, vendorName.indexOf(' '))
    : vendorName;
}

/**
 * Helper function to check if the input string has a whitespace
 * @param {string} stringValue  The stringValue input string
 * @returns {boolean}           Returns true if an index for a whitespace is found or
 *                              Returns false otherwise.
 */
export function hasWhitespace(stringValue) {
  return stringValue.indexOf(' ') >= 0;
}

//Sanitize filename before saving
export function sanitizeFilename(filename) {
  const invalidCharacters = /[^a-zA-Z0-9.]/g;
  // Replace invalid characters with underscores
  const sanitizedFilename = filename.replace(invalidCharacters, '_');
  // Remove leading and trailing underscores
  return sanitizedFilename.replace(/^_+|_+$/g, '');
}

//Function to return true if the number of variances are greater than one
function isVarianceCountGreaterThanOne(variances) {
  return variances.filter((variance) => Boolean(variance)).length > 1;
}

//Function to add a leading 0 if the month or the day only contains a single digit (are less than 10)
function padToTwoDigits(num) {
  return num.toString().padStart(2, '0');
}

/**
 * Helper function to convert File Bytes to Base64
 * @param {object} fileBytes  Bytes
 */
export function convertFileBytesToBase64(fileBytes) {
  var fileBuffer = new Uint8Array(fileBytes),
    length = fileBuffer.byteLength,
    binary = '';

  for (var i = 0; i < length; i++) {
    binary += String.fromCharCode(fileBuffer[i]);
  }
  fileBuffer = window.btoa(binary);
  return fileBuffer;
}
