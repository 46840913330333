import axios from 'axios';
import {
  getStatusIcon,
  formatDate,
  formatForGrid,
  replaceServiceType,
  setLocalTime,
  setGridAlertStatusToOpen
} from '@/util/dataFormatting';

const state = {
  newAlertsToday: 0,
  alerts: [],
  appendedNotes: [],
  isAppendedNotesGridLoad: true,
  error: null
};

const mutations = {
  SET_NEW_ALERTS_TODAY(state, data) {
    state.newAlertsToday = data;
  },
  SET_ALERTS(state, data) {
    state.alerts = data;
  },
  SET_APPENDED_NOTES_GRID(state, data) {
    state.appendedNotes = data;
  },
  SET_APPENDED_NOTES_GRID_LOAD(state, load) {
    state.isAppendedNotesGridLoad = load;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

const actions = {
  async setAlerts({ commit }, url) {
    let alertsApi = url.isConsultant
      ? `/companies/${url.companyId}/alerts?fromDate=${url.fromDate}&toDate=${url.toDate}&isAlertMinDate=${url.isAlertMinDate}`
      : `/alerts?fromDate=${url.fromDate}&toDate=${url.toDate}`;
    if (url.showPublicOnly) {
      alertsApi += `&showPublicOnly=${url.showPublicOnly}`;
    }
    await axios
      .get(alertsApi)
      .then((res) => {
        let newAlertsToday = 0;
        if (res.status === 200) {
          let alerts = res.data.data.map((item) => {
            if (item.status === 'open' && !setGridAlertStatusToOpen(item)) {
              item.status = 'good';
            }
            if (formatDate(item.importDate) === formatDate(new Date())) {
              newAlertsToday++;
            }

            getStatusIcon(item);

            item.closedAt = Boolean(item.closedAt)
              ? setLocalTime(item.closedAt, true)
              : '';
            item.serviceIcons = replaceServiceType(item.serviceType, true);
            item.services = replaceServiceType(item.serviceType);
            item.sortableNoteIcon = Boolean(item.notes.length) ? 1 : 0;

            if (item.usageUoM !== 'kWh') {
              item.usageUoM =
                item.usageUoM.charAt(0).toUpperCase() + item.usageUoM.slice(1);
            }

            item.chargeVarianceMoM = !Boolean(item.chargeVarianceMoM)
              ? item.chargeVarianceMoM
              : `${item.chargeVarianceMoM.slice(
                  0,
                  item.chargeVarianceMoM.indexOf('$') + 1
                )}${formatForGrid(
                  item.chargeVarianceMoM.slice(
                    item.chargeVarianceMoM.indexOf('$') + 1,
                    item.chargeVarianceMoM.length
                  )
                )}`;

            item.chargeVarianceYoY = !Boolean(item.chargeVarianceYoY)
              ? item.chargeVarianceYoY
              : `${item.chargeVarianceYoY.slice(
                  0,
                  item.chargeVarianceYoY.indexOf('$') + 1
                )}${formatForGrid(
                  item.chargeVarianceYoY.slice(
                    item.chargeVarianceYoY.indexOf('$') + 1,
                    item.chargeVarianceYoY.length
                  )
                )}`;

            item.usageVarianceMoM = !Boolean(item.usageVarianceMoM)
              ? item.usageVarianceMoM
              : `${item.usageVarianceMoM.slice(
                  0,
                  item.usageVarianceMoM.indexOf(' ') + 1
                )}${formatForGrid(
                  item.usageVarianceMoM.slice(
                    item.usageVarianceMoM.indexOf(' ') + 1,
                    item.usageVarianceMoM.length
                  )
                )}`;

            item.usageVarianceYoY = !Boolean(item.usageVarianceYoY)
              ? item.usageVarianceYoY
              : `${item.usageVarianceYoY.slice(
                  0,
                  item.usageVarianceYoY.indexOf(' ') + 1
                )}${formatForGrid(
                  item.usageVarianceYoY.slice(
                    item.usageVarianceYoY.indexOf(' ') + 1,
                    item.usageVarianceYoY.length
                  )
                )}`;
            item.finalizeDisabled =
              item.status === 'closed' ||
              item.status === 'closed with savings' ||
              !Boolean(item.notes.length);
            item.notes.map((note) => {
              note.createdAt = setLocalTime(note.createDate, true);
              if (Boolean(note.modifiedAt)) {
                note.modifiedAt = setLocalTime(note.modifiedAt, true);
              }
              if (!item.finalizeDisabled && !Boolean(note.closed)) {
                item.finalizeDisabled = true;
              }
              return note;
            });
            return item;
          });
          commit('SET_NEW_ALERTS_TODAY', newAlertsToday);
          commit('SET_ALERTS', alerts);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async batchResolveAlerts({ commit }, url) {
    return await axios
      .put(`/alerts/batchResolve?${url.alertIds}`, url.noteParams)
      .then((res) => {
        return res.status;
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setAppendedNotes({ commit }, noteId) {
    await axios
      .get(`/alerts/notes/${noteId}`)
      .then((res) => {
        if (res.status === 200) {
          const appendedNotes = res.data.data.map((note) => {
            note.createDate = setLocalTime(note.createDate, true);
            return note;
          });

          commit('SET_APPENDED_NOTES_GRID', appendedNotes);
          commit('SET_APPENDED_NOTES_GRID_LOAD', false);
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          refreshToken();
        } else {
          console.log(err);
          localStorage.clear();
          commit('SET_ERROR', err.status);
        }
      });
  },
  async updateAlert({ commit }, url) {
    return await axios
      .put(`/alerts/${url.alertId}`, url.alertStatus)
      .then((res) => {
        return res.status;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        console.log(err);
        return err.response.status;
      });
  },
  async addAlertNote({ commit }, url) {
    return await axios
      .post(`/alerts/${url.alertId}/notes`, url.noteData)
      .then((res) => {
        return res.status;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        console.log(err);
        return err.response.status;
      });
  },
  async appendNote({ commit }, url) {
    return await axios
      .post(`/alerts/notes/${url.noteId}`, url.noteData)
      .then((res) => {
        return res.status;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        console.log(err);
        return err.response.status;
      });
  },
  async updateAlertNote({ commit }, url) {
    return await axios
      .put(`/alerts/notes/${url.noteId}`, url.noteData)
      .then((res) => {
        return res.status;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        console.log(err);
        return err.response.status;
      });
  }
};

const getters = {
  newAlertsToday: (state) => {
    return state.newAlertsToday;
  },
  alerts: (state) => {
    return state.alerts;
  },
  appendedNotes: (state) => {
    return state.appendedNotes;
  },
  isAppendedNotesGridLoad: (state) => {
    return state.isAppendedNotesGridLoad;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
