<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" :href="homeUrl">{{ routeName }}</a>
    </div>

    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <a
        v-if="companyHasSupportAccess"
        @click="goToCustomerSupport"
        style="cursor: pointer"
      >
        <div class="user-headset">
          <i class="fa fa-solid fa-user-headset fa-2x"></i>
        </div>
      </a>
      <!--Hide for now until light theme is completed | Eron 4/21/2021-->
      <!--We still need to make improvements | Michael 8/4/2021-->
      <sidebar-share></sidebar-share>
      <a @click="logout" style="cursor: pointer">
        <div class="photo">
          <img src="/img/LogoutTransparent.png" />
        </div>
      </a>
    </ul>
  </base-nav>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';
import SidebarShare from './SidebarSharePlugin';

export default {
  components: {
    SidebarToggleButton,
    CollapseTransition,
    BaseNav,
    SidebarShare,
    Modal
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      const company = this.$store.getters.getCompany;
      return name === 'Home'
        ? this.capitalizeFirstLetter(name)
        : `${company.name} - ${this.capitalizeFirstLetter(name.split('-')[0])}`;
    },
    getCompanyId() {
      return this.$store.getters.getCompany.id;
    },
    companyHasSupportAccess() {
      return this.$store.getters.getCompany.companyHasSupportAccess;
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      homeUrl: ''
    };
  },
  async created() {
    this.homeUrl = this.$store.getters.isConsultant ? '/consultant' : '/home';
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    async logout() {
      await this.$store.dispatch('setLogout');
      location.reload();
    },
    goToCustomerSupport() {
      const path = `/companies/${this.getCompanyId}/tickets`;
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    }
  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
  background-color: #103f5f !important;
}
</style>
