import axios from 'axios';
import { formatDate } from '../../util/dataFormatting';

const state = {
  users: [],
  error: null,
  consultantCompanyAccess: []
};

const mutations = {
  SET_USERS(state, data) {
    state.users = data;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_CONSULTANT_COMPANY_ACCESS(state, data) {
    state.consultantCompanyAccess = data;
  }
};

const actions = {
  async setUsers({ commit }) {
    await axios
      .get('/users/usersAll')
      .then(async (res) => {
        if (res.status === 200) {
          const users = res.data.data.sort((first, second) => {
            first = formatUser(first);
            second = formatUser(second);
            return first.fullName > second.fullName ? 1 : -1;
          });
          await axios
            .get('/users/usersGET')
            .then((secondRes) => {
              if (secondRes.status === 200) {
                secondRes.data.data.map((userGET) => {
                  const userApp = users.find(
                    (userApp) => userApp.email === userGET.email
                  );
                  if (userApp) {
                    userApp.roles = JSON.parse(userGET.roles);
                    if (Boolean(userApp.roles.length)) {
                      userApp.roleNames = userApp.roles
                        .map((item) => item.role)
                        .join(', ');
                    }
                  }
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
          commit('SET_USERS', users);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async addRole({ commit }, roleInfo) {
    return await axios
      .post(`/users/addRole`, roleInfo)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
  async removeRole({ commit }, roleId) {
    return await axios
      .delete(`/users/removeRole?id=${roleId}`)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
  async addCompanyAccessToConsultant({ commit }, AccessInfo) {
    return await axios
      .post('/users/addCompanyAccess', AccessInfo)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
  async getCompanyAccessforConsultant({ commit }, userId) {
    return await axios
      .get(`/users/${userId}/getCompanyAccess`)
      .then((res) => {
        const companyAccess = res.data.message.map((accessInfo) => {
          accessInfo.createdAt = formatDate(accessInfo.createdAt);
          return accessInfo;
        });
        commit('SET_CONSULTANT_COMPANY_ACCESS', companyAccess);
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
  async deleteCompanyAccessforConsultant({ commit }, id) {
    return await axios
      .delete(`users/${id}/deleteConsultantCompanyAccess`)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
  async setCompanyAccess({ commit }, params) {
    return await axios
      .put(`companies/setCompanyAccess`, params)
      .then((res) => res)
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
};

const getters = {
  users: (state) => {
    return state.users;
  },
  isUsersLoad: (state) => {
    return state.isUsersLoad;
  },
  consultantCompanyAccess: (state) => {
    return state.consultantCompanyAccess;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};

//obtaining user's fullName, deleting their first & last name, & blanking their role
function formatUser(user) {
  if (!Boolean(user.fullName)) {
    user.fullName =
      Boolean(user.firstName) && Boolean(user.lastName)
        ? `${user.firstName} ${user.lastName}`
        : `${user.lastName}`;
    delete user.firstName;
    delete user.lastName;
  }
  return user;
}
