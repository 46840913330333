import axios from 'axios';
import {
  formatServiceType,
  replaceCollectionType,
  replaceServiceType,
  formatVendorName
} from '@/util/dataFormatting';

const state = {
  accountsOverviewChart: [],
  accountsTotalAccountsChart: [],
  accountsTotalChargesChart: [],
  accountsAttentionItems: {},
  accountsGrid: [],
  accountLatestBill: {},
  isAccountsChartsLoad: true,
  isAccountsAttentionItemsLoad: true,
  isAccountsGridLoad: true,
  accountsCompanyId: null,
  accountsColumns: [],
  companyBusinessUnits: [],
  error: null
};

const mutations = {
  SET_ACCOUNTS_OVERVIEW_CHART(state, data) {
    state.accountsOverviewChart = data;
  },
  SET_ACCOUNTS_TOTAL_ACCOUNTS_CHART(state, data) {
    state.accountsTotalAccountsChart = data;
  },
  SET_ACCOUNTS_TOTAL_CHARGES_CHART(state, data) {
    state.accountsTotalChargesChart = data;
  },
  SET_ACCOUNTS_ATTENTION_ITEMS(state, data) {
    state.accountsAttentionItems = data;
  },
  SET_ACCOUNTS_GRID(state, data) {
    state.accountsGrid = data;
  },
  SET_ACCOUNT_LATEST_BILL(state, data) {
    state.accountLatestBill = data;
  },
  SET_ACCOUNTS_CHARTS_LOAD(state, load) {
    state.isAccountsChartsLoad = load;
  },
  SET_ACCOUNTS_ATTENTION_ITEMS_LOAD(state, load) {
    state.isAccountsAttentionItemsLoad = load;
  },
  SET_ACCOUNTS_GRID_LOAD(state, load) {
    state.isAccountsGridLoad = load;
  },
  SET_ACCOUNTS_COMPANY_ID(state, id) {
    state.accountsCompanyId = id;
  },
  SET_ACCOUNTS_COLUMNS(state, data) {
    state.accountsColumns = data;
  },
  SET_COMPANY_BUSINESS_UNITS(state, data) {
    state.companyBusinessUnits = data;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

const actions = {
  async setAccountsOverviewChart({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/accounts/info/overview`
          : `/accounts/info/overview`
      )
      .then((res) => {
        if (res.status === 200) {
          let overview = [];
          res.data.data.overview.map((element) => {
            for (const property in element) {
              overview.push({
                type: `${property.replace('total', '')}`,
                total: element[property]
              });
            }
          });
          overview = overview.sort((first, second) => {
            return second.total - first.total;
          });
          commit('SET_ACCOUNTS_OVERVIEW_CHART', overview);
          commit('SET_ACCOUNTS_CHARTS_LOAD', false);
        }
      })
      .catch((err) => {
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setAccountsNumberOfAccountsChart({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/accounts/info/totalNumberOfAccountsByServiceTypes`
          : `/accounts/info/totalNumberOfAccountsByServiceTypes`
      )
      .then((res) => {
        if (res.status === 200) {
          let totalAccounts;
          if (res.data.data.totalNumberOfAccountsByServiceTypes > 1) {
            totalAccounts =
              res.data.data.totalNumberOfAccountsByServiceTypes.sort(
                (first, second) => {
                  if (Boolean(formatServiceType(first.serviceType))) {
                    first.serviceType = formatServiceType(first.serviceType);
                  }
                  if (Boolean(formatServiceType(second.serviceType))) {
                    second.serviceType = formatServiceType(second.serviceType);
                  }
                  return second.totalAccounts - first.totalAccounts;
                }
              );
          } else {
            totalAccounts =
              res.data.data.totalNumberOfAccountsByServiceTypes.map((item) => {
                if (Boolean(formatServiceType(item.serviceType))) {
                  item.serviceType = formatServiceType(item.serviceType);
                }
                return item;
              });
          }
          commit('SET_ACCOUNTS_TOTAL_ACCOUNTS_CHART', totalAccounts);
          commit('SET_ACCOUNTS_CHARTS_LOAD', false);
        }
      })
      .catch((err) => {
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setAccountsSpendByServiceTypeIn12Months({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/accounts/info/totalSpendByServiceTypeIn12Months`
          : `/accounts/info/totalSpendByServiceTypeIn12Months`
      )
      .then((res) => {
        if (res.status === 200) {
          const totalChargesChart =
            res.data.data.totalSpendByServiceTypeIn12Months.filter(
              (element) => {
                element.serviceType = formatServiceType(element.serviceType);
                return Boolean(element.serviceType);
              }
            );
          commit('SET_ACCOUNTS_TOTAL_CHARGES_CHART', totalChargesChart);
          commit('SET_ACCOUNTS_CHARTS_LOAD', false);
          commit('SET_ACCOUNTS_COMPANY_ID', url.companyId);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setAccountsAttentionItems({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/locations/attention`
          : '/accounts/attention'
      )
      .then((res) => {
        if (res.status === 200) {
          const attentionItems = res.data.data[0];
          commit('SET_ACCOUNTS_ATTENTION_ITEMS', attentionItems);
          commit('SET_ACCOUNTS_ATTENTION_ITEMS_LOAD', false);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setCompanyBusinessUnits({ commit }, url) {
    let api = 'companies/businessUnits';
    if (url.parentId.toString() === url.companyId.toString()) {
      api += `/${url.parentId}?isParent=true`;
    } else {
      api += `/${url.companyId}?isParent=false`;
    }
    await axios
      .get(api)
      .then((res) => {
        if (res.status === 200) {
          let businessUnits = res.data.data;
          const uniqueBusinessUnits = [
            ...businessUnits
              .reduce((map, obj) => {
                if (!map.has(obj.businessUnit)) map.set(obj.businessUnit, obj);
                return map;
              }, new Map())
              .values()
          ];
          commit('SET_COMPANY_BUSINESS_UNITS', uniqueBusinessUnits);
        }
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  async setAccounts({ commit }, url) {
    await axios
      .get(
        url.isConsultant ? `/companies/${url.companyId}/accounts` : '/accounts'
      )
      .then((res) => {
        if (res.status === 200) {
          let accounts = res.data.data;

          //Retrieve Custom Attributes From Accounts
          commit('SET_ACCOUNTS_COLUMNS', []);
          const columns = Object.keys(accounts[0]);
          let customAttributes = [];
          if (columns.length > 22) {
            for (let i = 22; i <= columns.length - 1; i++) {
              customAttributes.push({
                prop: columns[i],
                name: columns[i].toUpperCase(),
                sortable: true,
                width: 120,
                isHidden: true
              });
            }
          }
          accounts.map((account) => {
            if (!Boolean(account.serviceAddress)) {
              account.serviceAddress = account.locationAddress;
            }
            account.serviceIcons = replaceServiceType(account.services, true);
            account.services = replaceServiceType(account.services);
            account.collectionTypeIcons = replaceCollectionType(
              account.collectionType,
              true
            );
            account.collectionType = replaceCollectionType(
              account.collectionType
            );
            if (account.esiId && account.locationState !== 'TX') {
              account.meterCustomerNumbers = account.esiId;
              account.esiId = '';
            }
            if (Boolean(account.meterCustomerNumbers)) {
              account.meterCustomerNumbersArray =
                account.meterCustomerNumbers.split(',');
              account.meterCustomerNumbers =
                account.meterCustomerNumbers.replace(/,/g, ', ');
            }
            if (Boolean(account.esiId)) {
              account.esiIdArray = account.esiId.split(',');
              account.esiId = account.esiId.replace(/,/g, ', ');
            }
            account.meterNumbersArray = account.meterNumber.split(',');
            account.meterNumber = account.meterNumber.replace(/,/g, ', ');
            account.serviceZip = account.serviceZip
              .replace(/,/g, ', ')
              .replace(/^,/, '');
            return account;
          });
          commit('SET_ACCOUNTS_GRID', accounts);
          commit('SET_ACCOUNTS_COLUMNS', customAttributes);
          commit('SET_ACCOUNTS_GRID_LOAD', false);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setAccountLatestBill({ commit }, billId) {
    await axios
      .get(`/bills/${billId}`)
      .then((res) => {
        if (res.status === 200) {
          let latestBill = res.data.data[0];
          latestBill.vendorName = formatVendorName(latestBill.vendorName);
          commit('SET_ACCOUNT_LATEST_BILL', latestBill);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setAccountsLoads({ commit }) {
    commit('SET_ACCOUNTS_CHARTS_LOAD', true);
    commit('SET_ACCOUNTS_ATTENTION_ITEMS_LOAD', true);
    commit('SET_ACCOUNTS_GRID_LOAD', true);
  },
  //Update nickname for an account
  async setAccountNickname({ commit }, params) {
    await axios
      .put(`/accounts/nickname/${params.id}`, {
        nickname: params.nickname
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
        return err;
      });
  }
};

const getters = {
  accountsOverviewChart: (state) => {
    return state.accountsOverviewChart;
  },
  accountsTotalAccountsChart: (state) => {
    return state.accountsTotalAccountsChart;
  },
  accountsTotalChargesChart: (state) => {
    return state.accountsTotalChargesChart;
  },
  accountsAttentionItems: (state) => {
    return state.accountsAttentionItems;
  },
  accountsGrid: (state) => {
    return state.accountsGrid;
  },
  accountLatestBill: (state) => {
    return state.accountLatestBill;
  },
  isAccountsChartsLoad: (state) => {
    return state.isAccountsChartsLoad;
  },
  isAccountsAttentionItemsLoad: (state) => {
    return state.isAccountsAttentionItemsLoad;
  },
  isAccountsGridLoad: (state) => {
    return state.isAccountsGridLoad;
  },
  getAccountsCompanyId: (state) => {
    return state.accountsCompanyId;
  },
  getAccountsGridCustomColumns: (state) => {
    return state.accountsColumns;
  },
  companyBusinessUnits: (state) => {
    return state.companyBusinessUnits;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
