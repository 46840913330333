import axios from 'axios';
import {
  formatBillMonth,
  formatSortingBillMonth,
  zeroCheck,
  setLocalTime,
  stringToDate
} from '@/util/dataFormatting';
import { deepCopy } from '@/components/Table/utils/index';

const state = {
  energyStarLocationName: {},
  energyStarDates: {},
  energyStarBaseline: {},
  energyStarChart: [],
  energyStarGrid: [],
  isEnergyStarDataLoad: true,
  companyEnergyStarData: [],
  companyEnergyStarScoreChart: {},
  companyEnergyStarList: [],
  error: null,
  energyStarNotes: []
};

const mutations = {
  SET_ENERGY_STAR_LOCATION_NAME(state, data) {
    state.energyStarLocationName = data;
  },
  SET_ENERGY_STAR_DATES(state, data) {
    state.energyStarDates = data;
  },
  SET_ENERGY_STAR_BASELINE(state, data) {
    state.energyStarBaseline = data;
  },
  SET_ENERGY_STAR_GRID(state, data) {
    state.energyStarGrid = data;
  },
  SET_ENERGY_STAR_CHART(state, data) {
    state.energyStarChart = data;
  },
  SET_ENERGY_STAR_DATA_LOAD(state, data) {
    state.isEnergyStarDataLoad = data;
  },
  SET_COMPANY_ENERGY_STAR_DATA(state, data) {
    state.companyEnergyStarData = data;
  },
  SET_COMPANY_ENERGY_STAR_SCORE_CHART(state, data) {
    state.companyEnergyStarScoreChart = data;
  },
  SET_COMPANY_ENERGY_STAR_LIST(state, data) {
    state.companyEnergyStarList = data;
  },
  SET_ENERGY_STAR_NOTES(state, data) {
    state.energyStarNotes = data;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

const actions = {
  async setEnergyStarInfo({ commit }, url) {
    await axios
      .get(
        `/locations/${url.locationId}/energystar?fromDate=${url.fromDate}&toDate=${url.toDate}`
      )
      .then((res) => {
        if (res.status === 200) {
          const locationName = Boolean(res.data.data[0])
            ? res.data.data[0].locationName
            : '';
          commit('SET_ENERGY_STAR_LOCATION_NAME', locationName);

          const dates = { fromDate: url.fromDate, toDate: url.toDate };
          commit('SET_ENERGY_STAR_DATES', dates);

          if (!Boolean(this.getters.energyStarBaseline.score)) {
            commit(
              'SET_ENERGY_STAR_BASELINE',
              res.data.data.find((item) => item.isBaseline)
            );
          }

          const energyStarBaseline = this.getters.energyStarBaseline;
          const energyStarChart = deepCopy(res.data.data).map((item) => {
            item.metricMonth = formatBillMonth(item.metricMonth, true);
            return item;
          });

          commit('SET_ENERGY_STAR_CHART', energyStarChart);
          const energyStarGrid = res.data.data.map((item) => {
            item.metricMonth = formatSortingBillMonth(item.metricMonth);
            if (!item.isBaseline) {
              item.scoreChangeNumber = item.score - energyStarBaseline.score;
              item.scoreChangePercentage =
                (item.scoreChangeNumber / zeroCheck(energyStarBaseline.score)) *
                100;
              item.sourceEUIChangeNumber =
                item.sourceIntensity - energyStarBaseline.sourceIntensity;
              item.sourceEUIChangePercentage =
                (item.sourceEUIChangeNumber /
                  zeroCheck(energyStarBaseline.sourceIntensity)) *
                100;
              item.locationEUIChangeNumber =
                item.locationIntensity - energyStarBaseline.locationIntensity;
              item.locationEUIChangePercentage =
                (item.locationEUIChangeNumber /
                  zeroCheck(energyStarBaseline.locationIntensity)) *
                100;
              item.sourceUseChangeNumber =
                item.sourceTotal - energyStarBaseline.sourceTotal;
              item.sourceUseChangePercentage =
                (item.sourceUseChangeNumber /
                  zeroCheck(energyStarBaseline.sourceTotal)) *
                100;
              item.locationUseChangeNumber =
                item.locationTotal - energyStarBaseline.locationTotal;
              item.locationUseChangePercentage =
                (item.locationUseChangeNumber /
                  zeroCheck(energyStarBaseline.locationTotal)) *
                100;
              item.totalGHGEmissionsChangeNumber =
                item.totalGHGEmissions - energyStarBaseline.totalGHGEmissions;
              item.totalGHGEmissionsChangePercentage =
                (item.totalGHGEmissionsChangeNumber /
                  zeroCheck(energyStarBaseline.totalGHGEmissions)) *
                100;
            }

            // rounding all numerical props of each object
            Object.keys(item).forEach((prop) => {
              if (!isNaN(item[prop])) {
                item[prop] = Math.round(item[prop]);
              }
            });

            return item;
          });

          commit('SET_ENERGY_STAR_GRID', energyStarGrid);
          commit('SET_ENERGY_STAR_DATA_LOAD', false);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  setEnergyStarLoad({ commit }) {
    commit('SET_ENERGY_STAR_DATA_LOAD', true);
  },
  //Updates the Energy Star Score for a location
  async updateEnergyStarScore({ commit }, url) {
    await axios
      .put(`locations/${url.id}/updateEsScore`, {
        esScore: url.esScore,
        esScoreDate: url.esDate
      })
      .then((res) => {
        return res.status;
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  //Refreshes the Energy Star Score per location id
  async refreshEsScoreHistory({ commit }, siteId) {
    return await axios
      .get(`locations/${siteId}/refreshEsScore`)
      .catch((err) => {
        console.log(
          'ERROR: ${err.status} - ${err.message} Failed to refresh the Es Score History'
        );
        commit('SET_ERROR', err.status);
      });
  },
  //Set Energy star scores based on the company
  async setCompanyEnergyStarData({ commit }, companyId) {
    await axios.get(`/companies/${companyId}/energyStarScore`).then((res) => {
      let chartData = {};

      //List of energy star scores
      const energyStarData = res.data.data.map((item) => {
        let { locationName, metricMonth, score } = item;
        //Format data
        item.metricMonth = formatBillMonth(metricMonth, true);

        //Set chart data
        let data = { metricMonth: metricMonth };
        data['score'] = score;
        if (!chartData[locationName]) {
          chartData[locationName] = [data];
        } else {
          chartData[locationName].push(data);
        }

        return item;
      });

      // This function returns the last duplicated item in the list. It will contain a list of unique locations with their energy star data.
      const getUniqueLocationLatestEnergyStar = [
        ...new Map(
          res.data.data.map((item, idx, array) => {
            item.scoreChange =
              item.yearType === 'Baseline'
                ? 0
                : item.score - (array[idx - 1]?.score ?? 0);

            return [item['locationName'], item];
          })
        ).values()
      ];
      commit('SET_COMPANY_ENERGY_STAR_DATA', energyStarData);
      commit('SET_COMPANY_ENERGY_STAR_SCORE_CHART', chartData);
      commit('SET_COMPANY_ENERGY_STAR_LIST', getUniqueLocationLatestEnergyStar);
    });
  },
  async setEnergyStarNotes({ commit }, energyStarMetricId) {
    return await axios
      .get(`/api/energystar/${energyStarMetricId}/getEnergyStarNotes`)
      .then((response) => {
        let esNotesData = response.data.data
          .sort((first, second) => {
            return new Date(second.createDate) - new Date(first.createDate);
          })
          .map((item) => {
            item.createDate = stringToDate(item.createDate);
            return item;
          });
        commit('SET_ENERGY_STAR_NOTES', esNotesData);
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
  // Add a note for an energy star
  async addEnergyStarNotes({ commit }, params) {
    return await axios
      .post('/api/energystar/addnote', params)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        commit('SET_ERROR', err.response.status);
        console.log(error);
        return error;
      });
  },
  // Update a note for an energy star
  async updateEnergyStarNote({ commit }, params) {
    return await axios
      .put('/api/energystar/updateNote', params)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        commit('SET_ERROR', err.response.status);
        return error;
      });
  },
  // Delete an energy star note
  async deleteEnergyStarNote({ commit }, noteId) {
    return await axios
      .delete(`/api/energystar/${noteId}/deleteNote`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        commit('SET_ERROR', err.response.status);
        console.log(error);
        return error;
      });
  }
};

const getters = {
  energyStarLocationName: (state) => {
    return state.energyStarLocationName;
  },
  energyStarDates: (state) => {
    return state.energyStarDates;
  },
  energyStarBaseline: (state) => {
    return state.energyStarBaseline;
  },
  energyStarChart: (state) => {
    return state.energyStarChart;
  },
  energyStarGrid: (state) => {
    return state.energyStarGrid;
  },
  isEnergyStarDataLoad: (state) => {
    return state.isEnergyStarDataLoad;
  },
  companyEnergyStarData: (state) => {
    return state.companyEnergyStarData;
  },
  companyEnergyStarScoreChart: (state) => {
    return state.companyEnergyStarScoreChart;
  },
  companyEnergyStarList: (state) => {
    return state.companyEnergyStarList;
  },
  energyStarNotes: (state) => {
    return state.energyStarNotes;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
