/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.2.3
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import axios from 'axios';
import * as VueGoogleMaps from 'vue2-google-maps';
import store from './store/store';

// router setup
import router from './routes/router';
import i18n from './i18n';
import './registerServiceWorker';
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBLAJMGldGcyp7XJdqbIdO4Lfb8c8Rlehg'
  },
  installComponents: true
});
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;
axios.interceptors.request.use(async (config) => {
  const isAuthorized = localStorage.getItem('isAuthorized');
  const now = new Date();
  const expireIn = new Date(store.getters.getExpirationDate);
  if (
    config.url !== '/api/refresh' &&
    isAuthorized === 'true' &&
    expireIn.valueOf() < now.valueOf()
  ) {
    await store.dispatch('setRefreshAuth').then((res) => {
      if (res.status === 200) {
        config.headers['Authorization'] =
          axios.defaults.headers['Authorization'];
      }
    });
  }
  return config;
});
axios.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      originalRequest.url === '/api/refresh'
    ) {
      return error.response;
    }
    return error.response;
  }
);

/* eslint-disable no-new */
export const EventBus = new Vue({
  el: '#app',
  store,
  render: (h) => h(App),
  router,
  i18n
});
