import swal from 'sweetalert2';
export function checkBlankInput(field, passed) {
  if (field !== '') {
    return passed;
  }
}

export function errorFire(response) {
  swal.fire({
    title: `Error: ${response}. Please try again.`,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'btn btn-warning'
    }
  });
}

export function getContractStatus(contract) {
  if (contract.daysTillExpire <= 0) {
    contract.status = contract.isExtended ? 'Expired (Extended)' : 'Expired';
    contract.statusColor = { color: '#7e94a7' };
  } else if (0 <= contract.daysTillExpire && contract.daysTillExpire < 30) {
    contract.status = contract.isExtended
      ? '< 30 Days Remaining (Extended)'
      : '< 30 Days Remaining';
    contract.statusColor = contract.isExtended
      ? { color: '#7e94a7' }
      : { color: '#e35b5f' };
  } else if (30 <= contract.daysTillExpire && contract.daysTillExpire < 90) {
    contract.status = contract.isExtended
      ? '< 90 Days Remaining (Extended)'
      : '< 90 Days Remaining';
    contract.statusColor = contract.isExtended
      ? { color: '#7e94a7' }
      : { color: '#eb8334' };
  } else if (90 <= contract.daysTillExpire && contract.daysTillExpire < 180) {
    contract.status = contract.isExtended
      ? '< 180 Days Remaining (Extended)'
      : '< 180 Days Remaining';
    contract.statusColor = contract.isExtended
      ? { color: '#7e94a7' }
      : { color: '#ffcc00' };
  } else {
    contract.status = contract.isExtended ? 'Active (Extended)' : 'Active';
    contract.statusColor = contract.isExtended
      ? { color: '#7e94a7' }
      : { color: '#99c23d' };
  }
  return contract;
}

export function getExtendedContractIcon(associatedContractId) {
  return associatedContractId ? '"fa-solid fa-circle-e' : '';
}

export function fileReader(uploadFile, component) {
  var reader = new FileReader();
  if (uploadFile) {
    reader.readAsArrayBuffer(uploadFile.raw);
    reader.onloadend = (oFREvent) => {
      var byteArray = new Uint8Array(oFREvent.target.result),
        length = byteArray.byteLength,
        binary = '';

      for (var i = 0; i < length; i++) {
        binary += String.fromCharCode(byteArray[i]);
      }

      byteArray = window.btoa(binary);

      component.file.fileName = uploadFile.name;
      component.file.fileValue = byteArray;
    };
  }
}

export async function getDocuments(component) {
  const params = Boolean(component.assocDocsContract)
    ? {
        contractId: component.assocDocsContract.id,
        associatedDocument: Boolean(component.assocDocsContract)
      }
    : {
        companyId: component.$route.params.compId,
        isConsultant: component.isConsultant,
        associatedDocument: Boolean(component.assocDocsContract)
      };
  await component.$store.dispatch('setDocuments', params);
}

export async function getLinkedLocations(component) {
  const params = { contractId: component.linkedLocation.id };
  await component.$store.dispatch('setLinkedLocations', params);
}
//Reloads the contracts data
export async function getSupplierContracts(component) {
  const params = {
    companyId: component.$route.params.compId,
    isConsultant: component.isConsultant
  };
  await component.$store.dispatch('setContracts', params);
}

export function notBlankReturnZero(value) {
  return Boolean(value) ? parseInt(value) : 0;
}

export function resetProps(obj) {
  Object.keys(obj).forEach((idx) => {
    obj[idx] = '';
  });
}

export function successFire(name, action, confirm = false) {
  return swal.fire({
    title: `${name} ${action}`,
    showConfirmButton: false,
    buttonsStyling: false,
    timer: 2000,
    icon: 'success',
    customClass: confirm
      ? {
          confirmButton: 'btn btn-fill'
        }
      : ''
  });
}

export function zeroCheck(value) {
  return Boolean(value) ? value : '';
}

export function emailArrayToString(emailArray) {
  let emails = emailArray.toString().split(',').join(';');
  return Boolean(emails) ? emails : null;
}

export function valueExistsInArray(inputArray, existingArray) {
  for (let item of inputArray) {
    return Boolean(existingArray.includes(item));
  }
  return false;
}
