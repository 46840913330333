<template>
  <div class="fixed-plugin" v-click-outside="closeDropDown">
    <div class="dropdown show-dropdown" :class="{ show: isOpen }">
      <a data-toggle="dropdown" class="settings-icon">
        <i class="fa fa-cog fa-2x" @click="toggleDropDown"> </i>
      </a>
      <ul class="dropdown-menu" :class="{ show: isOpen }">
        <li class="header-title">Theme Settings</li>
        <li class="adjustments-line">
          <div class="togglebutton">
            <base-switch
              v-model="darkMode"
              toggle="toggle"
              @input="toggleMode"
            ></base-switch>
          </div>
        </li>
        <li class="adjustment-line email-title">
          <div>
            <a
              class="text-color"
              href="mailto:getsupport@getchoice.com"
              target="_blank"
              >Contact Support</a
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { BaseSwitch } from 'src/components';
import { EventBus } from 'src/main';

export default {
  name: 'sidebar-share',
  components: {
    BaseSwitch
  },
  props: {
    backgroundColor: String
  },
  data() {
    return {
      sidebarMini: true,
      darkMode: this.$store.getters.colorScheme,
      toggle: true,
      isOpen: false,
      sidebarColors: [
        { color: 'primary', active: false, value: 'primary' },
        { color: 'vue', active: true, value: 'vue' },
        { color: 'info', active: false, value: 'blue' },
        { color: 'success', active: false, value: 'green' },
        { color: 'warning', active: false, value: 'orange' },
        { color: 'danger', active: false, value: 'red' }
      ]
    };
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen;
    },
    closeDropDown() {
      this.isOpen = false;
    },
    toggleList(list, itemToActivate) {
      list.forEach((listItem) => {
        listItem.active = false;
      });
      itemToActivate.active = true;
    },
    changeSidebarBackground(item) {
      this.$emit('update:backgroundColor', item.value);
      this.toggleList(this.sidebarColors, item);
    },
    toggleMode(type) {
      let docClasses = document.body.classList;
      if (type) {
        docClasses.remove('white-content');
      } else {
        docClasses.add('white-content');
      }
      this.$store.dispatch('setColorScheme', type);
      EventBus.$emit('colorModeChange', type);
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    }
  }
};
</script>
<style scoped lang="scss">
@import '~@/assets/sass/dashboard/custom/variables';

.settings-icon {
  cursor: pointer;
}

.badge-vue {
  background-color: $vue;
}

.text-color {
  color: #fff !important;
}
</style>
