import axios from 'axios';
import {
  formatSortingBillMonth,
  replaceServiceType,
  formatDate,
  formatForGrid
} from '@/util/dataFormatting';

const state = {
  bills: [],
  billsCompanyId: null,
  billChartData: {},
  error: null
};

const mutations = {
  SET_BILLS(state, data) {
    state.bills = data;
  },
  SET_COMPANY_ID(state, id) {
    state.billsCompanyId = id;
  },
  SET_BILL_CHART_DATA(state, data) {
    state.billChartData = data;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

const actions = {
  async setBills({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/bills?fromDate=${url.fromDate}&toDate=${url.toDate}`
          : `/bills?fromDate=${url.fromDate}&toDate=${url.toDate}`
      )
      .then((res) => {
        if (res.status === 200) {
          let bills = res.data.data;
          let billsChartData = {
            outstandingBalance: 0,
            totalNewCharges: 0,
            totalAmountDue: 0
          };
          bills.map((bill) => {
            bill.statusDate =
              bill.status?.toLowerCase() === 'cleared'
                ? formatDate(bill.statusDate)
                : '';
            bill.billMonth = formatSortingBillMonth(bill.billMonth);
            bill.serviceIcons = replaceServiceType(bill.services, true);
            bill.services = replaceServiceType(bill.services);

            //Calculate outstanding and total new charges
            billsChartData.outstandingBalance =
              billsChartData.outstandingBalance +
              (bill.outstandingBalance || 0);
            billsChartData.totalNewCharges =
              billsChartData.totalNewCharges + (bill.totalNewCharges || 0);

            const itemTotalAmountDue =
              (bill.totalNewCharges || 0) + (bill.outstandingBalance || 0);
            billsChartData.totalAmountDue =
              billsChartData.totalAmountDue + itemTotalAmountDue;

            //Added this property to sort the AP column
            bill.apFileInfoDesc = bill.apFileInfo
              ? 'Ap File Info'
              : 'No Ap File Info';
            //set ap file info modal data
            bill.apFileInfo = !!bill.apFileInfo
              ? bill.apFileInfo.map((infoItem) => {
                  infoItem.CoCd = !!infoItem.Company_Reference
                    ? infoItem.Company_Reference
                    : infoItem.CoCd;
                  infoItem['Cost Ctr'] =
                    !!infoItem.Worktag_Cost_Center_Reference_ID
                      ? infoItem.Worktag_Cost_Center_Reference_ID
                      : infoItem['Cost Ctr'];
                  infoItem['Line Text'] = !!infoItem['Line Order']
                    ? infoItem['Line Order']
                    : infoItem['Line Text'];
                  infoItem.invoice_date = !!infoItem.Invoice_Date
                    ? infoItem.Invoice_Date
                    : infoItem.invoice_date;
                  infoItem.invoice_date = !!infoItem.invoice_date
                    ? isNaN(infoItem.invoice_date)
                      ? formatDate(infoItem.invoice_date)
                      : formatDate(
                          `${infoItem.invoice_date.slice(
                            0,
                            2
                          )}/${infoItem.invoice_date.slice(
                            2,
                            4
                          )}/20${infoItem.invoice_date.slice(4, 6)}`
                        )
                    : infoItem.invoice_date;
                  infoItem.amount = !!infoItem.amount
                    ? infoItem.amount
                    : formatForGrid(infoItem.amount, '$');
                  return infoItem;
                })
              : bill.apFileInfo;
            return bill;
          });
          billsChartData.outstandingBalance =
            billsChartData.outstandingBalance.toFixed(2);
          billsChartData.totalNewCharges =
            billsChartData.totalNewCharges.toFixed(2);
          billsChartData.totalAmountDue =
            billsChartData.totalAmountDue.toFixed(2);
          commit('SET_BILLS', bills);
          commit('SET_COMPANY_ID', url.companyId);
          commit('SET_BILL_CHART_DATA', billsChartData);
        }
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  }
};

const getters = {
  bills: (state) => {
    return state.bills;
  },
  getBillCompanyId: (state) => {
    return state.billsCompanyId;
  },
  getBillsChartData: (state) => {
    return state.billChartData;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
