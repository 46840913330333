/* DEFINE IMPORTS */
import axios from 'axios';
import { formatDate, replaceServiceType } from '@/util/dataFormatting';

import { serviceTypeIcons } from '@/util/utils';

/* GLOBAL STATE */
const state = {
  // List of open and close accounts for a company
  openCloseAccountsData: [],
  openCloseAccountNotes: []
};

const mutations = {
  SET_OPEN_CLOSE_ACCOUNTS_DATA(state, data) {
    state.openCloseAccountsData = data;
  },
  SET_OPEN_CLOSE_ACCOUNT_NOTES(state, data) {
    state.openCloseAccountNotes = data;
  }
};

const actions = {
  /* GET REQUESTS */
  async setOpenCloseAccountsData({ commit }, { companyId, isConsultant }) {
    return await axios
      .get(`opencloseaccounts/${companyId}`)
      .then((res) => {
        const openCLoseAccountsData = res.data.data.map((item) => {
          //Format Dates
          item.turnoverDate = formatDate(item.turnoverDate);
          item.storeCloseDate = formatDate(item.storeCloseDate);
          item.storeOpenDate = formatDate(item.storeOpenDate);
          //AccountStatus
          item.accountStatusNumber = getAccountStatus(item.accountStatus);
          item.isOpen = item.isOpen ? 'Open' : 'Close';
          // Assuming 'item.serviceType' is a valid service type key
          item.serviceTypeIcon =
            serviceTypeIcons[item.serviceType.toLowerCase()] || '';
          return item;
        });
        commit('SET_OPEN_CLOSE_ACCOUNTS_DATA', openCLoseAccountsData);
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
  async getOpenCloseNotes({ commit }, accountId) {
    return await axios
      .get(`getOpenCloseAccountNotes/${accountId}`)
      .then((res) => {
        const openCloseAccountNotes = res.data.data
          .sort((a, b) => {
            return new Date(b.createdDate) - new Date(a.createdDate);
          })
          .map((note) => {
            note.createdDate = formatDate(note.createdDate);
            return note;
          });
        commit('SET_OPEN_CLOSE_ACCOUNT_NOTES', openCloseAccountNotes);
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
  /* POST REQUESTS */
  async openAccounts({ commit }, params) {
    return await axios
      .post(`OpenAccounts`, params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
  async closeAccounts({ commit }, params) {
    return await axios
      .post('CloseAccounts', params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
  async addOpenCloseAccountNotes({ commit }, params) {
    return await axios
      .post('AddAccountNotes', params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
  /* PUT REQUEST */
  async changeAccountStatus({ commit }, params) {
    return await axios
      .put(`changeOpenCloseStatus`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  },
  async updateOpenCloseAccount({ commit }, params) {
    return await axios
      .put(`updateOpenCloseAccount`, params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
};

const getters = {
  getOpenCloseAccountsData: (state) => {
    return state.openCloseAccountsData;
  },
  getOpenCloseAccountNotes: (state) => {
    return state.openCloseAccountNotes;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};

// Functions
const getAccountStatus = (accountStatus) => {
  let status = '';
  switch (accountStatus) {
    case 'Received':
      status = 0;
      break;
    case 'In Progress':
      status = 1;
      break;
    case 'Pending':
      status = 2;
      break;
    case 'Done':
      status = 3;
      break;
    default:
      status = null;
      break;
  }
  return status;
};
