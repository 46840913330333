import axios from 'axios';
import { formatForGrid, replaceServiceType } from '../../util/dataFormatting';

const state = {
  allClients: [],
  myClients: [],
  clientCardsData: {},
  isMyClientsLoad: true,
  isDarkMode: true,
  error: null
};

const mutations = {
  SET_ALL_CLIENTS(state, data) {
    state.allClients = data;
  },
  SET_MY_CLIENTS(state, data) {
    state.myClients = data;
  },
  SET_CLIENT_CARDS_DATA(state, data) {
    state.clientCardsData = data;
  },
  SET_MY_CLIENTS_LOAD(state, load) {
    state.isMyClientsLoad = load;
  },
  SET_COLOR_SCHEME(state, data) {
    state.isDarkMode = data;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

const actions = {
  async setMyClients({ commit }) {
    await axios
      .get('/consultant')
      .then((res) => {
        if (res.status === 200) {
          const myClients = res.data.data.map((client) => {
            client.latestContractServiceIcon = replaceServiceType(
              client.latestContractService,
              true
            );
            client.latestContractService = replaceServiceType(
              client.latestContractService
            );

            client.totalAccounts = formatForGrid(client.totalAccounts);
            client.totalActiveAccounts = formatForGrid(
              client.totalActiveAccounts
            );
            return client;
          });

          commit('SET_MY_CLIENTS', myClients);
          commit('SET_MY_CLIENTS_LOAD', false);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setAllClients({ commit }) {
    await axios
      .get('/consultant/all')
      .then((res) => {
        if (res.status === 200) {
          const allClients = res.data.data.map((client) => {
            client.latestContractServiceIcon = replaceServiceType(
              client.latestContractService,
              true
            );
            client.latestContractService = replaceServiceType(
              client.latestContractService
            );

            client.totalAccounts = formatForGrid(client.totalAccounts);
            client.totalActiveAccounts = formatForGrid(
              client.totalActiveAccounts
            );
            return client;
          });
          commit('SET_ALL_CLIENTS', allClients);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setClientCardsData({ commit }, companyId) {
    await axios
      .get(`/companies/${companyId}/homeChartsData`)
      .then((res) => {
        if (res.status === 200) {
          const clientCardsData = res.data.data[0];
          Object.keys(clientCardsData).map((item) => {
            if (item.includes('Usage') || item.includes('totalInvoices')) {
              clientCardsData[item] = formatForGrid(clientCardsData[item]);
            }
            return item;
          });
          commit('SET_CLIENT_CARDS_DATA', clientCardsData);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  setColorScheme({ commit }, colorScheme) {
    commit('SET_COLOR_SCHEME', colorScheme);
  }
};

const getters = {
  myClients: (state) => {
    return state.myClients;
  },
  allClients: (state) => {
    return state.allClients;
  },
  isMyClientsLoad: (state) => {
    return state.isMyClientsLoad;
  },
  clientCardsData: (state) => {
    return state.clientCardsData;
  },
  colorScheme: (state) => {
    return state.isDarkMode;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
