import axios from 'axios';
import { setLocalUSTime } from '@/util/dataFormatting';

const state = {
  companySupportRequestTypes: [],
  companySupportTickets: [],
  isCompanySupportTicketsGridLoading: true,
  companySupportTicketDetails: [],
  isCompanySupportTicketDetailsLoading: true,
  companySupportTicketComments: [],
  isCompanySupportTicketCommentsLoading: true,
  companySupportTicketAttachments: [],
  isCompanySupportTicketAttachmentsLoading: true,
  error: null
};

const mutations = {
  SET_COMPANY_SUPPORT_REQUEST_TYPES(state, data) {
    state.companySupportRequestTypes = data;
  },
  SET_COMPANY_SUPPORT_TICKETS(state, data) {
    state.companySupportTickets = data;
  },
  SET_COMPANY_SUPPORT_TICKETS_GRID_LOADING(state, data) {
    state.isCompanySupportTicketsGridLoading = data;
  },
  SET_COMPANY_SUPPORT_TICKET_DETAILS(state, data) {
    state.companySupportTicketDetails = data;
  },
  SET_COMPANY_SUPPORT_TICKET_DETAILS_LOADING(state, data) {
    state.isCompanySupportTicketDetailsLoading = data;
  },
  SET_COMPANY_SUPPORT_TICKET_COMMENTS(state, data) {
    state.companySupportTicketComments = data;
  },
  SET_COMPANY_SUPPORT_TICKET_COMMENTS_LOADING(state, data) {
    state.isCompanySupportTicketCommentsLoading = data;
  },
  SET_COMPANY_SUPPORT_TICKET_ATTACHMENTS(state, data) {
    state.companySupportTicketAttachments = data;
  },
  SET_COMPANY_SUPPORT_TICKET_ATTACHMENTS_LOADING(state, data) {
    state.isCompanySupportTicketAttachmentsLoading = data;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

const actions = {
  // Set data for the issue type dropdown
  async setCompanySupportRequestTypes({ commit }, companyId) {
    await axios
      .get(`/company_support/request_types/${companyId}`)
      .then((res) => {
        commit('SET_COMPANY_SUPPORT_REQUEST_TYPES', res.data.data);
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  // Set all tickets based on the given CreatedBy user id
  async setCompanySupportTickets({ commit }, url) {
    return await axios
      .get(
        `/company_support/ticket/?userId=${url.createdBy}&requestedBy=${url.requestedBy}`
      )
      .then((res) => {
        let data = res.data.data;
        data.forEach((item) => {
          item.createdAt = setLocalUSTime(item.createdAt);
        });
        commit('SET_COMPANY_SUPPORT_TICKETS', data);
        commit('SET_COMPANY_SUPPORT_TICKETS_GRID_LOADING', false);

        return res;
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  // Set ticket detail for the given Jira Issue Id
  async setCompanySupportTicketDetails({ commit }, jiraIssueId) {
    return await axios
      .get(`company_support/ticket/${jiraIssueId}/details`)
      .then((res) => {
        let data = res.data.data;
        let ticketComments = data.comments;
        ticketComments.forEach((item) => {
          item.createdAt = setLocalUSTime(item.createdAt);
        });
        let ticketAttachments = data.attachments;
        commit('SET_COMPANY_SUPPORT_TICKET_DETAILS', data);
        commit('SET_COMPANY_SUPPORT_TICKET_DETAILS_LOADING', false);
        commit('SET_COMPANY_SUPPORT_TICKET_COMMENTS', ticketComments);
        commit('SET_COMPANY_SUPPORT_TICKET_COMMENTS_LOADING', false);
        commit('SET_COMPANY_SUPPORT_TICKET_ATTACHMENTS', ticketAttachments);
        commit('SET_COMPANY_SUPPORT_TICKET_ATTACHMENTS_LOADING', false);

        return res;
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  // Create ticket
  async createTicket({ commit }, params) {
    return await axios
      .post('/company_support/ticket', params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
        return err;
      });
  },
  // Cancel Ticket
  async cancelTicket({ commit }, jiraIssueId) {
    return await axios
      .post(`company_support/cancel_ticket/?jiraIssueId=${jiraIssueId}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  // Create comment
  async createComment({ commit }, params) {
    return await axios
      .post('/company_support/comment', params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  // Temporary Ticket Attachment before submitting
  async createTemporaryAttachment({ commit }, params) {
    let formData = new FormData();
    formData.append('file', params.file.raw);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    return await axios
      .post(
        `/company_support/temporary_attachment?companyId=${params.companyId}`,
        formData,
        config
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  // Send attachment(s) with JiraIssueId to API
  async finalizeAttachment({ commit }, params) {
    return await axios
      .post('company_support/finalize_attachments', params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  setCompanySupportTicketsGridLoading({ commit }) {
    commit('SET_COMPANY_SUPPORT_TICKETS_GRID_LOADING', true);
  },
  setCompanySupportTicketCommentsLoading({ commit }) {
    commit('SET_COMPANY_SUPPORT_TICKET_COMMENTS_LOADING', true);
  },
  setCompanySupportTicketAttachmentsLoading({ commit }) {
    commit('SET_COMPANY_SUPPORT_TICKET_ATTACHMENTS_LOADING', true);
  },
  setCompanySupportTicketDetailsLoading({ commit }) {
    commit('SET_COMPANY_SUPPORT_TICKET_DETAILS_LOADING', true);
    commit('SET_COMPANY_SUPPORT_TICKET_COMMENTS_LOADING', true);
    commit('SET_COMPANY_SUPPORT_TICKET_ATTACHMENTS_LOADING', true);
  }
};

const getters = {
  getCompanySupportRequestTypes: (state) => {
    return state.companySupportRequestTypes;
  },
  getCompanySupportTickets: (state) => {
    return state.companySupportTickets;
  },
  getIsCompanySupportTicketsGridLoading: (state) => {
    return state.isCompanySupportTicketsGridLoading;
  },
  getCompanySupportTicketDetails: (state) => {
    return state.companySupportTicketDetails;
  },
  getIsCompanySupportTicketDetailsLoading: (state) => {
    return state.isCompanySupportTicketDetailsLoading;
  },
  getCompanySupportTicketComments: (state) => {
    return state.companySupportTicketComments;
  },
  getIsCompanySupportTicketCommentsLoading: (state) => {
    return state.isCompanySupportTicketCommentsLoading;
  },
  getCompanySupportTicketAttachments: (state) => {
    return state.companySupportTicketAttachments;
  },
  getIsCompanySupportTicketAttachmentsLoading: (state) => {
    return state.isCompanySupportTicketAttachmentsLoading;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
