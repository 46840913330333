<template>
  <div class="not-found-page">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <div class="centered">
        <card>
          <template slot="header">
            <img src="/img/Login.png" alt="Choice! Data Connect" />
          </template>
          <div>
            <h1>
              <i class="fas fa-exclamation-triangle not-found-icon"></i>Error:
              404
            </h1>
            <p>The page you requested could not be found.</p>
          </div>

          <el-button class="homeButton" type="success" @click="handleRedirect"
            ><i class="fa fa-home"></i>Home</el-button
          >
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import card from '../../components/Cards/Card.vue';
import { Button } from 'element-ui';

export default {
  components: {
    card,
    [Button.name]: Button
  },
  methods: {
    handleRedirect() {
      // Check if the user is consultant or client
      this.$store.getters.isConsultant
        ? this.$router.push('consultant')
        : this.$router.push('home');
    }
  }
};
</script>

<style scoped lang="scss">
.not-found-page {
  .centered {
    h1 {
      font-size: 20px;
    }
    p {
      margin-top: 0px;
      font-size: 20px;
    }
    display: flex;
    padding-top: 150px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
  }
  .homeButton {
    margin-top: 20px;
  }
  .not-found-icon {
    padding-right: 5px;
  }
}
</style>
