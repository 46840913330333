import axios from 'axios';
import {
  capitalizeFirstLetter,
  checkBlank,
  formatBillMonth,
  formatDate,
  formatForGrid,
  replaceCollectionType
} from '@/util/dataFormatting';

const state = {
  billInfo: [],
  billChargesGrid: [],
  billChargesChart: [],
  billUsagesGrid: [],
  billUsagesChart: [],
  isBillChargesGridLoad: true,
  isBillChargesChartLoad: true,
  isBillUsagesGridLoad: true,
  isBillUsagesChartLoad: true,
  error: null
};

const mutations = {
  SET_BILL_INFO(state, data) {
    state.billInfo = data;
  },
  SET_BILL_CHARGES_GRID(state, data) {
    state.billChargesGrid = data;
  },
  SET_BILL_CHARGES_CHART(state, data) {
    state.billChargesChart = data;
  },
  SET_BILL_USAGES_GRID(state, data) {
    state.billUsagesGrid = data;
  },
  SET_BILL_USAGES_CHART(state, data) {
    state.billUsagesChart = data;
  },
  SET_BILL_CHARGES_GRID_LOAD(state, load) {
    state.isBillChargesGridLoad = load;
  },
  SET_BILL_CHARGES_CHART_LOAD(state, load) {
    state.isBillChargesChartLoad = load;
  },
  SET_BILL_USAGES_GRID_LOAD(state, load) {
    state.isBillUsagesGridLoad = load;
  },
  SET_BILL_USAGES_CHART_LOAD(state, load) {
    state.isBillUsagesChartLoad = load;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

const actions = {
  async setBillInfo({ commit }, billId) {
    await axios
      .get(`/bills/${billId}`)
      .then((res) => {
        if (res.status === 200) {
          let billChargesData = [];
          let billInfo = res.data.data[0];
          let currentMonth = formatBillMonth(billInfo.invoiceDate, true);
          let previousMonth = formatBillMonth(
            new Date(billInfo.invoiceDate).setMonth(
              new Date(billInfo.invoiceDate).getMonth() - 1
            ),
            true
          );
          let previousYear = formatBillMonth(
            new Date(billInfo.invoiceDate).setYear(
              new Date(billInfo.invoiceDate).getFullYear() - 1
            ),
            true
          );
          billInfo.totalNewCharges = formatForGrid(billInfo.totalNewCharges);

          billInfo.collectionTypeIcon = replaceCollectionType(
            billInfo.collectionTypeIcon,
            true
          );
          billInfo.collectionType = replaceCollectionType(
            billInfo.collectionType
          );

          billChargesData.push({
            billMonth: previousYear,
            value: billInfo.prevYearTotalCharges
          });
          billChargesData.push({
            billMonth: previousMonth,
            value: billInfo.prevMonthTotalNewCharges
          });
          billChargesData.push({
            billMonth: currentMonth,
            value: Number(billInfo.totalNewCharges.replace(/,/g, ''))
          });

          billInfo.invoiceDate = formatDate(billInfo.invoiceDate);
          billInfo.dueDate = formatDate(billInfo.dueDate);

          billInfo.amountDue = formatForGrid(billInfo.amountDue);

          if (!checkBlank(billInfo.services)) {
            billInfo.services = billInfo.services
              .replace('account', 'fas fa-archive')
              .replace('district_heat', 'fas fa-thermometer-full')
              .replace('electric', 'fas fa-bolt')
              .replace('invoice', 'fas fa-file .bill-file')
              .replace('irrigation', 'fas fa-shower')
              .replace('lighting', 'fas fa-lightbulb')
              .replace('natural_gas', 'fad fa-fire')
              .replace('propane', 'fas fa-rocket')
              .replace('sanitation', 'fas fa-sign-language')
              .replace('sewer', 'fad fa-manhole fa-lg')
              .replace('steam', 'fas fa-cloud')
              .replace('telecom', 'fas fa-phone')
              .replace('trash', 'fad fa-trash-can')
              .replace('waste', 'fas fa-truck')
              .replace('storm_water', 'fas fa-cloud-showers')
              .replace('water', 'fad fa-tint')
              .replace('drag_reducing_agent', 'fa-solid fa-flask')
              .split(',');
          }

          commit('SET_BILL_INFO', billInfo);
          commit('SET_BILL_CHARGES_CHART', billChargesData);
          commit('SET_BILL_CHARGES_CHART_LOAD', false);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },

  async setBillChargesGrid({ commit }, billId) {
    await axios
      .get(`/bills/${billId}/charges`)
      .then((res) => {
        if (res.status === 200) {
          let billCharges = res.data.data;

          billCharges.map((charge) => {
            if (!checkBlank(charge.serviceType)) {
              charge.serviceIcon = charge.serviceType
                .replace('account', 'fas fa-archive')
                .replace('district_heat', 'fas fa-thermometer-full')
                .replace('electric', 'fas fa-bolt')
                .replace('internet', 'fas fa-wifi')
                .replace('invoice', 'fas fa-file')
                .replace('irrigation', 'fas fa-shower')
                .replace('landline_phone', 'fas fa-phone')
                .replace('lighting', 'fas fa-lightbulb-o')
                .replace('natural_gas', 'fad fa-fire')
                .replace('propane', 'fas fa-rocket')
                .replace('sanitation', 'fas fa-sign-language')
                .replace('sewer', 'fad fa-manhole fa-lg')
                .replace('steam', 'fas fa-cloud')
                .replace('telecom', 'fas fa-phone')
                .replace('television', 'fas fa-tv')
                .replace('trash', 'fad fa-trash-can')
                .replace('waste', 'fas fa-truck')
                .replace('storm_water', 'fas fa-cloud-showers')
                .replace('water', 'fad fa-tint')
                .replace('drag_reducing_agent', 'fa-solid fa-flask')
                .split(',');
            }
            if (!checkBlank(charge.tariff)) {
              charge.tariff = charge.tariff
                .replace('data', 'Data')
                .replace('unknown_tariff', 'Unknown Tariff')
                .replace('unspecified', 'Unspecified');
            }

            if (!checkBlank(charge.chargeClassification)) {
              charge.chargeClassification = charge.chargeClassification
                .replace('delivery', 'Delivery')
                .replace('demand', 'Demand')
                .replace('fixed_cost_fee', 'Fixed Cost Fee')
                .replace('tax', 'Tax');
            }

            return charge;
          });

          commit('SET_BILL_CHARGES_GRID', billCharges);
          commit('SET_BILL_CHARGES_GRID_LOAD', false);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },

  async setBillUsagesGrid({ commit }, billId) {
    await axios
      .get(`/bills/${billId}/usage`)
      .then((res) => {
        if (res.status === 200) {
          let billUsage = res.data.data;
          billUsage.map((usage) => {
            if (!checkBlank(usage.serviceName)) {
              usage.serviceIcon = usage.serviceName
                .replace('account', 'fas fa-archive')
                .replace('district_heat', 'fas fa-thermometer-full')
                .replace('electric', 'fas fa-bolt')
                .replace('internet', 'fas fa-wifi')
                .replace('invoice', 'fas fa-file')
                .replace('irrigation', 'fas fa-shower')
                .replace('landline_phone', 'fas fa-phone')
                .replace('lighting', 'fas fa-lightbulb-o')
                .replace('natural_gas', 'fad fa-fire')
                .replace('propane', 'fas fa-rocket')
                .replace('sanitation', 'fas fa-sign-language')
                .replace('sewer', 'fad fa-manhole fa-lg')
                .replace('steam', 'fas fa-cloud')
                .replace('telecom', 'fas fa-phone')
                .replace('television', 'fas fa-tv')
                .replace('trash', 'fad fa-trash-can')
                .replace('waste', 'fas fa-truck')
                .replace('storm_water', 'fas fa-cloud-showers')
                .replace('water', 'fad fa-tint')
                .replace('drag_reducing_agent', 'fa-solid fa-flask')
                .split(',');
            }
            usage.firstService = usage.serviceName.toString();
            if (!checkBlank(usage.tariff)) {
              usage.tariff = usage.tariff
                .replace('data', 'Data')
                .replace('unknown_tariff', 'Unknown Tariff')
                .replace('unspecified', 'Unspecified');
            }

            if (!checkBlank(usage.measurementType)) {
              usage.measurementType = usage.measurementType
                .replace('demand', 'Demand')
                .replace('general_consumption', 'General Consumption')
                .replace('unspecified', 'Unspecified');
            }

            usage.energyUnit = capitalizeFirstLetter(usage.energyUnit);

            return usage;
          });

          commit('SET_BILL_USAGES_GRID', billUsage);
          commit('SET_BILL_USAGES_GRID_LOAD', false);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },

  async setBillUsagesChart({ commit }, billId) {
    await axios
      .get(`/bills/${billId}/usage/chart`)
      .then((res) => {
        if (res.status === 200) {
          let billUsage = res.data.data;
          billUsage.map((usage) => {
            usage.billMonth = formatBillMonth(usage.billMonth, true);
            switch (usage.serviceGroupName) {
              case 'electric':
                usage.serviceGroupName = 'Electricity';
                break;
              case 'natural_gas':
                usage.serviceGroupName = 'Gas';
                break;
              case 'water':
                usage.serviceGroupName = 'Water';
                break;
              case 'telecom':
                usage.serviceGroupName = 'Telecom';
                break;
              case 'trash':
                usage.serviceGroupName = 'Trash';
                break;
              case 'sewer':
                usage.serviceGroupName = 'Sewer';
                break;
              case 'drag_reducing_agent':
                usage.serviceGroupName = 'Drag Reducing Agent';
            }
            usage.value = usage.totalUsage;
            return usage;
          });

          commit('SET_BILL_USAGES_CHART', billUsage);
          commit('SET_BILL_USAGES_CHART_LOAD', false);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  setBillLoad({ commit }) {
    commit('SET_BILL_CHARGES_CHART_LOAD', true);
    commit('SET_BILL_USAGES_GRID_LOAD', false);
  }
};

const getters = {
  billInfo: (state) => {
    return state.billInfo;
  },
  billChargesGrid: (state) => {
    return state.billChargesGrid;
  },
  billChargesChart: (state) => {
    return state.billChargesChart;
  },
  billUsagesGrid: (state) => {
    return state.billUsagesGrid;
  },
  billUsagesChart: (state) => {
    return state.billUsagesChart;
  },
  isBillChargesGridLoad: (state) => {
    return state.isBillChargesGridLoad;
  },
  isBillChargesChartLoad: (state) => {
    return state.isBillChargesChartLoad;
  },
  isBillUsagesGridLoad: (state) => {
    return state.isBillUsagesGridLoad;
  },
  isBillUsagesChartLoad: (state) => {
    return state.isBillUsagesChartLoad;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
