import axios from 'axios';
import {
  capitalizeFirstLetter,
  getServiceIcon,
  formatDate,
  formatForGrid,
  formatBillMonth,
  formatSortingBillMonth,
  getMonthDifference,
  replaceCollectionType,
  replaceServiceType,
  formatVendorName,
  formatServiceType
} from '@/util/dataFormatting';
import { getContractStatus } from '../../pages/Contracts/contractsUtils';
import { deepCopy } from '@/components/Table/utils/index';

const budgetUnits = {
  Electric: 'kWh',
  electric: 'kWh',
  'Natural Gas': 'Therms',
  'natural gas': 'Therms',
  natural_gas: 'Therms',
  Water: 'Gallons',
  water: 'Gallons'
};

const state = {
  locationInfo: {},
  locationTotalChargesChart: [],
  locationAttentionItems: {},
  locationChargesChart: [],
  locationUsagesChart: [],
  locationAccountsGrid: [],
  locationAccountLatestBill: [],
  locationUsagesGrid: [],
  locationChargesGrid: [],
  locationContractsGrid: [],
  locationBudgetsGrid: [],
  locationTotalCharges: '',
  locationTotalUsages: {},
  locationTotalChargesDate: '',
  locationTotalUsagesDate: '',
  //now doing single check for loading of all location cards
  isLocationCardsLoad: true,
  //now doing single check for loading of all location grids
  isLocationGridsLoad: true,
  error: null
};

const mutations = {
  SET_LOCATION_INFO(state, data) {
    state.locationInfo = data;
  },
  SET_LOCATIONS_TOTAL_CHARGES_CHART(state, data) {
    state.locationTotalChargesChart = data;
  },
  SET_LOCATION_ATTENTION_ITEMS(state, data) {
    state.locationAttentionItems = data;
  },
  SET_LOCATION_CHARGES_CHART(state, data) {
    state.locationChargesChart = data;
  },
  SET_LOCATION_USAGES_CHART(state, data) {
    state.locationUsagesChart = data;
  },
  SET_LOCATION_ACCOUNTS_GRID(state, data) {
    state.locationAccountsGrid = data;
  },
  SET_LOCATION_ACCOUNT_LATEST_BILL(state, data) {
    state.locationAccountLatestBill = data;
  },
  SET_LOCATION_USAGES_GRID(state, data) {
    state.locationUsagesGrid = data;
  },
  SET_LOCATION_CHARGES_GRID(state, data) {
    state.locationChargesGrid = data;
  },
  SET_LOCATION_CONTRACTS_GRID(state, data) {
    state.locationContractsGrid = data;
  },
  SET_LOCATION_BUDGETS_GRID(state, data) {
    state.locationBudgetsGrid = data;
  },
  SET_LOCATION_CARDS_LOAD(state, load) {
    state.isLocationCardsLoad = load;
  },
  SET_LOCATION_GRIDS_LOAD(state, load) {
    state.isLocationGridsLoad = load;
  },
  SET_LOCATION_TOTAL_CHARGES(state, data) {
    state.locationTotalCharges = data;
  },
  SET_LOCATION_TOTAL_USAGES(state, data) {
    state.locationTotalUsages = data;
  },
  SET_LOCATION_TOTAL_CHARGES_DATE(state, data) {
    state.locationTotalChargesDate = data;
  },
  SET_LOCATION_TOTAL_USAGES_DATE(state, data) {
    state.locationTotalUsagesDate = data;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

const actions = {
  async setLocationTotalSpendByServiceType({ commit }, locationId) {
    await axios
      .get(`/locations/${locationId}/totalSpendByServiceType`)
      .then((res) => {
        if (res.status === 200) {
          const chargesChart = deepCopy(res.data.data)
            .sort((first, second) => {
              first.billMonth = formatBillMonth(first.billMonth, true);
              second.billMonth = formatBillMonth(second.billMonth, true);
              return new Date(first.billMonth) - new Date(second.billMonth);
            })
            .map((item) => {
              Object.keys(item).forEach((key) => {
                if (!Boolean(item[key])) {
                  item[key] = 0;
                }
              });
              return item;
            });

          let location12MonthsTotalCharges = 0;
          const chargesGrid = res.data.data
            .filter((item) => !item.isInvoiceMissing)
            .map((item) => {
              item.billMonth = formatSortingBillMonth(item.billMonth);
              getServiceIcon(item, 'electric');
              getServiceIcon(item, 'naturalGas');
              getServiceIcon(item, 'water');
              getServiceIcon(item, 'sewer');
              getServiceIcon(item, 'telecom');
              getServiceIcon(item, 'trash');
              getServiceIcon(item, 'taxesFees');
              getServiceIcon(item, 'totalCharges');
              getServiceIcon(item, 'dragReducingAgent');

              return item;
            });

          let limit =
            chargesChart.length >= 12
              ? chargesChart.length - 12
              : chargesChart.length - 1;

          //Only set the charges if there is some data
          if (chargesChart.length) {
            for (let i = chargesChart.length - 1; i >= limit; i--) {
              location12MonthsTotalCharges += chargesChart[i]?.totalCharges;
            }
          }

          commit('SET_LOCATION_TOTAL_CHARGES', location12MonthsTotalCharges);
          commit('SET_LOCATION_CHARGES_CHART', chargesChart);
          commit('SET_LOCATION_CHARGES_GRID', chargesGrid);
        }
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  async setLocationTotalSpendByServiceTypeIn12Months({ commit }, locationId) {
    await axios
      .get(`/locations/${locationId}/totalSpendByServiceTypeIn12Months`)
      .then((res) => {
        if (res.status === 200) {
          const totalChargesChart = res.data.data.filter((element) => {
            switch (element.serviceType) {
              case 'electric':
                element.serviceType = 'Electricity';
                return true;
              case 'natural_gas':
                element.serviceType = 'Gas';
                return true;
              case 'water':
                element.serviceType = 'Water';
                return true;
              case 'telecom':
                element.serviceType = 'Telecom';
                return true;
              case 'trash':
                element.serviceType = 'Trash';
                return true;
              case 'sewer':
                element.serviceType = 'Sewer';
                return true;
              case 'drag_reducing_agent':
                element.serviceType = 'Drag Reducing Agent';
                return true;
            }
          });
          commit('SET_LOCATIONS_TOTAL_CHARGES_CHART', totalChargesChart);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setLocationTotalConsumptionByServiceType({ commit }, locationId) {
    await axios
      .get(`/locations/${locationId}/totalConsumptionByServiceType`)
      .then((res) => {
        if (res.status === 200) {
          let usagesChart = [];
          deepCopy(res.data.data)
            .sort(
              (first, second) =>
                new Date(first.billMonth) - new Date(second.billMonth)
            )
            .forEach((item) => {
              // Changed map to forEach since we're not returning anything from the function
              item.billMonth = formatBillMonth(item.billMonth, true);
              let found = usagesChart.find(
                (el) => el.billMonth === item.billMonth
              );

              // Initialize the missingServiceTypeMonth array if it doesn't exist
              if (found && !found.missingServiceTypeMonth) {
                found.missingServiceTypeMonth = [];
              }

              // Check if the current item is missing an invoice
              if (item.isInvoiceMissing) {
                if (found) {
                  // Add the service type to the missing array if it doesn't already exist
                  if (
                    !found.missingServiceTypeMonth.includes(
                      formatServiceType(item.serviceType)
                    )
                  ) {
                    found.missingServiceTypeMonth.push(
                      formatServiceType(item.serviceType)
                    );
                  }
                } else {
                  // Create a new object with the missing service type
                  usagesChart.push({
                    billMonth: item.billMonth,
                    missingServiceTypeMonth: [
                      formatServiceType(item.serviceType)
                    ]
                  });
                  // Since we just added it, set found to this new object
                  found = usagesChart[usagesChart.length - 1];
                }
              }

              if (found) {
                // Add the totalUsage to the existing month
                found[item.serviceType] = item.totalUsage;

                // Add other properties if the service type is 'electric'
                if (item.serviceType === 'electric') {
                  found['kVA'] = item.maxKvA;
                  found['kW'] = item.maxkW;
                  found['kVAR'] = item.totalKvAR;
                }
              } else {
                // Create a new object for this month and service type
                const newUsage = {
                  billMonth: item.billMonth,
                  [item.serviceType]: item.totalUsage,
                  missingServiceTypeMonth: item.isInvoiceMissing
                    ? [formatServiceType(item.serviceType)]
                    : []
                };

                // Add other properties if the service type is 'electric'
                if (item.serviceType === 'electric') {
                  newUsage['kVA'] = item.maxKvA;
                  newUsage['kW'] = item.maxkW;
                  newUsage['kVAR'] = item.totalKvAR;
                }

                usagesChart.push(newUsage);
              }
            });
          const usagesGrid = res.data.data
            .filter((item) => !item.isInvoiceMissing)
            .map((item) => {
              item.billMonth = formatSortingBillMonth(item.billMonth);
              item.serviceIcons = replaceServiceType(item.serviceType, true);
              item.services = replaceServiceType(item.serviceType);

              if (item.energyUnit !== 'kWh') {
                item.energyUnit = capitalizeFirstLetter(item.energyUnit);
              }
              return item;
            });
          //Calculate service usage for the last 12 months
          let loacation12MonthsTotalUsages = {};
          let serviceTypeCount = {};
          let usagesLast12Months = [];
          usagesGrid.forEach((x) => {
            if (!loacation12MonthsTotalUsages[x.serviceType]) {
              loacation12MonthsTotalUsages[x.serviceType] = {
                totalUsage: 0,
                serviceIcon: `${x.serviceIcons[0]} card-header-icon`,
                service: x.services,
                energyUnit: x.energyUnit
              };
            }
          });

          Object.keys(loacation12MonthsTotalUsages).forEach((item) => {
            serviceTypeCount[item] = {
              count: 0
            };
          });

          usagesGrid.forEach((item) => {
            if (serviceTypeCount[item.serviceType].count < 12) {
              usagesLast12Months.push({
                billMonth: item.billMonth,
                serviceType: item.serviceType,
                totalUsage: item.totalUsage
              });
              serviceTypeCount[item.serviceType].count++;
            }
          });
          usagesLast12Months.forEach((item) => {
            loacation12MonthsTotalUsages[item.serviceType].totalUsage +=
              item.totalUsage;
          });
          commit('SET_LOCATION_TOTAL_USAGES', loacation12MonthsTotalUsages);
          commit('SET_LOCATION_USAGES_CHART', usagesChart);
          commit('SET_LOCATION_USAGES_GRID', usagesGrid);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setLocationInfo({ commit }, locationId) {
    commit('SET_LOCATION_CARDS_LOAD', true);
    await axios
      .get(`/locations/${locationId}/locationinfo`)
      .then((res) => {
        if (res.status === 200) {
          const locationInfo = res.data.data[0];
          locationInfo.addressLineTwo = `${locationInfo.city}, ${locationInfo.state} ${locationInfo.zip}`;
          locationInfo.sqft = formatForGrid(locationInfo.sqft);

          commit('SET_LOCATION_INFO', locationInfo);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setLocationAttentionItems({ commit }, locationId) {
    await axios
      .get(`/locations/${locationId}/attention`)
      .then((res) => {
        if (res.status === 200) {
          const attentionItems = res.data.data[0];
          commit('SET_LOCATION_ATTENTION_ITEMS', attentionItems);
          commit('SET_LOCATION_CARDS_LOAD', false);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setLocationAccounts({ commit }, locationId) {
    await axios
      .get(`/locations/${locationId}/accounts`)
      .then((res) => {
        if (res.status === 200) {
          const accountsGrid = res.data.data.map((item) => {
            if (!Boolean(item.serviceAddress)) {
              item.serviceAddress = item.locationAddress;
            }

            item.serviceIcons = replaceServiceType(item.services, true);
            item.services = replaceServiceType(item.services);
            item.collectionTypeIcons = replaceCollectionType(
              item.collectionType,
              true
            );
            item.collectionType = replaceCollectionType(item.collectionType);

            if (item.esiId && item.locationState !== 'TX') {
              item.meterCustomerNumber = item.esiId;
              item.esiId = '';
            }

            return item;
          });
          commit('SET_LOCATION_ACCOUNTS_GRID', accountsGrid);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setLocationAccountLatestBill({ commit }, billId) {
    await axios
      .get(`/bills/${billId}`)
      .then((res) => {
        if (res.status === 200) {
          let latestBill = res.data.data[0];
          latestBill.vendorName = formatVendorName(latestBill.vendorName);
          commit('SET_LOCATION_ACCOUNT_LATEST_BILL', latestBill);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setLocationContracts({ commit }, locationId) {
    await axios
      .get(`/location/${locationId}/contracts`)
      .then((res) => {
        if (res.status === 200) {
          let locationContracts = res.data.data;
          locationContracts.map((contract) => {
            switch (contract.serviceType) {
              case 'electric':
                contract.serviceType = 'fas fa-bolt';
                contract.serviceUnit = 'kWh';
                break;
              case 'natural_gas':
                contract.serviceType = 'fas fa-fire';
                contract.serviceUnit = 'therms';
                break;
              case 'water':
                contract.serviceType = 'fas fa-tint';
                contract.serviceUnit = 'gallons';
                break;
              case 'trash':
                contract.serviceType = 'fas fa-trash';
                break;
            }
            //sets a null value for all state that are empty string in order for the column to sort
            if (contract.state == '') {
              contract.state = null;
            }
            contract.termMonth = getMonthDifference(
              new Date(contract.startDate),
              new Date(contract.endDate)
            );
            contract.pastDays = Math.round(
              Math.abs(
                (new Date(contract.startDate) - new Date()) /
                  (24 * 60 * 60 * 1000)
              )
            );
            contract.remainingMonths = getMonthDifference(
              new Date(),
              new Date(contract.endDate)
            );
            contract.pastMonths =
              getMonthDifference(new Date(contract.startDate), new Date()) - 1;

            contract.contractRate = Boolean(contract.contractRate)
              ? `$${contract.contractRate}`
              : null;
            contract.contractAnnualUsage = Boolean(contract.contractAnnualUsage)
              ? `${formatForGrid(contract.contractAnnualUsage)} ${
                  contract.serviceUnit
                }`
              : null;

            //Get the status of the contract
            contract.status = getContractStatus(contract).status;
            contract.statusColor = getContractStatus(contract).statusColor;
          });
          commit('SET_LOCATION_CONTRACTS_GRID', locationContracts);
        }
      })
      .catch((err) => {
        //handle error
        console.log(err);
      });
  },
  async setLocationBudgets({ commit }, locationId) {
    await axios
      .get(`/locations/${locationId}/budgets`)
      .then((res) => {
        if (res.status === 200) {
          let locationBudgets = res.data.data.map((budget) => {
            budget.serviceTypeIcon = replaceServiceType(
              budget.serviceType.toLowerCase(),
              true
            );

            budget.createdAt = formatDate(budget.createdAt);
            budget.modifiedAt = formatDate(budget.modifiedAt);
            budget.unit = budgetUnits[budget.serviceType];

            budget.budgetSpendTotal = 0;
            budget.actualSpendTotal = 0;
            budget.budgetUsageTotal = 0;
            budget.actualUsageTotal = 0;
            budget.budgetItems.map((budgetItem) => {
              budgetItem.month = formatBillMonth(budgetItem.month, true);
              budgetItem.unit = budget.unit;

              budgetItem.spendDifference =
                budgetItem.budgetSpendAmount - budgetItem.actualSpendAmount;
              budgetItem.usageDifference =
                budgetItem.budgetUsageAmount - budgetItem.actualUsageAmount;

              budget.budgetSpendTotal += Boolean(budgetItem.budgetSpendAmount)
                ? budgetItem.budgetSpendAmount
                : 0;
              budget.actualSpendTotal += Boolean(budgetItem.actualSpendAmount)
                ? budgetItem.actualSpendAmount
                : 0;
              budget.budgetUsageTotal += Boolean(budgetItem.budgetUsageAmount)
                ? budgetItem.budgetUsageAmount
                : 0;
              budget.actualUsageTotal += Boolean(budgetItem.actualUsageAmount)
                ? budgetItem.actualUsageAmount
                : 0;
              return budgetItem;
            });
            budget.spendDifference =
              budget.budgetSpendTotal - budget.actualSpendTotal;
            budget.usageDifference =
              budget.budgetUsageTotal - budget.actualUsageTotal;
            return budget;
          });

          commit('SET_LOCATION_BUDGETS_GRID', locationBudgets);
          commit('SET_LOCATION_GRIDS_LOAD', false);
        }
      })
      .catch((err) => {
        //handle error
        console.log(err);
      });
  },
  setLocationTotalCharges({ commit }, params) {
    let totalCharges = 0;
    let min = params[0].findIndex((el) => el.billMonth === params[1].firstDate);
    let max = params[0].findIndex(
      (item) => item.billMonth === params[1].secondDate
    );

    for (let i = max; i >= min; i--) {
      totalCharges += params[0][i].totalCharges;
    }
    commit('SET_LOCATION_TOTAL_CHARGES', totalCharges);
  },
  setLocationTotalUsages({ commit }, params) {
    let locationTotalUsages = {};
    let usage = {};
    let usagesMonths = [];
    let data = params[0].toReversed();
    let services = [
      'natural_gas',
      'sewer',
      'electric',
      'water',
      'trash',
      'telecom'
    ];

    state.locationUsagesGrid.forEach((item) => {
      if (!locationTotalUsages[item.serviceType]) {
        locationTotalUsages[item.serviceType] = {
          totalUsage: 0,
          serviceIcon: `${item.serviceIcons[0]} card-header-icon`,
          service: item.services,
          energyUnit: item.energyUnit
        };
      }
    });

    let min = data.findIndex((item) => item.billMonth === params[1].firstDate);
    let max = data.findIndex((item) => item.billMonth === params[1].secondDate);

    for (let i = max; i <= min; i++) {
      Object.keys(data[i]).forEach((item) => {
        if (item !== 'billMonth' && services.includes(item)) {
          usage.billMonth = data[i].billMonth;
          usage.serviceType = item;
          usage['totalUsage'] = data[i][item];
          usagesMonths.push(usage);
          usage = {};
        }
      });
    }

    usagesMonths.forEach((item) => {
      locationTotalUsages[item.serviceType].totalUsage += item.totalUsage;
    });
    commit('SET_LOCATION_TOTAL_USAGES', locationTotalUsages);
  },
  setLocationTotalChargesDate({ commit }, dateRange) {
    commit(
      'SET_LOCATION_TOTAL_CHARGES_DATE',
      dateRange.firstDate + ' - ' + dateRange.secondDate
    );
  },
  setLocationTotalUsagesDate({ commit }, dateRange) {
    commit(
      'SET_LOCATION_TOTAL_USAGES_DATE',
      dateRange.firstDate + ' - ' + dateRange.secondDate
    );
  }
};

const getters = {
  locationInfo: (state) => {
    return state.locationInfo;
  },
  locationTotalChargesChart: (state) => {
    return state.locationTotalChargesChart;
  },
  locationAttentionItems: (state) => {
    return state.locationAttentionItems;
  },
  locationChargesChart: (state) => {
    return state.locationChargesChart;
  },
  locationUsagesChart: (state) => {
    return state.locationUsagesChart;
  },
  locationAccountsGrid: (state) => {
    return state.locationAccountsGrid;
  },
  locationAccountLatestBill: (state) => {
    return state.locationAccountLatestBill;
  },
  locationUsagesGrid: (state) => {
    return state.locationUsagesGrid;
  },
  locationChargesGrid: (state) => {
    return state.locationChargesGrid;
  },
  locationContractsGrid: (state) => {
    return state.locationContractsGrid;
  },
  locationBudgetsGrid: (state) => {
    return state.locationBudgetsGrid;
  },
  isLocationCardsLoad: (state) => {
    return state.isLocationCardsLoad;
  },
  isLocationAttentionItemsLoad: (state) => {
    return state.isLocationAttentionItemsLoad;
  },
  isLocationAccountsGridLoad: (state) => {
    return state.isLocationAccountsGridLoad;
  },
  isLocationUsagesGridLoad: (state) => {
    return state.isLocationUsagesGridLoad;
  },
  isLocationChargesGridLoad: (state) => {
    return state.isLocationChargesGridLoad;
  },
  loacation12MonthsTotalUsages: () => {
    return state.loacation12MonthsTotalUsages;
  },
  locationTotalCharges: () => {
    return state.locationTotalCharges;
  },
  locationTotalUsages: () => {
    return state.locationTotalUsages;
  },
  locationTotalChargesDate: (state) => {
    return state.locationTotalChargesDate;
  },
  locationTotalUsagesDate: (state) => {
    return state.locationTotalUsagesDate;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
