<template>
  <div class="wrapper wrapper-full-page">
    <consultant-navbar
      v-show="this.$route.path === '/consultant'"
    ></consultant-navbar>
    <div class="full-page">
      <div class="content custom-content">
        <zoom-center-transition :duration="200" mode="out-in">
          <router-view></router-view>
        </zoom-center-transition>
      </div>
    </div>
  </div>
</template>
<script>
import ConsultantNavbar from './ConsultantNavbar.vue';
import DashboardContent from './Content.vue';
import { SlideYDownTransition, ZoomCenterTransition } from 'vue2-transitions';

export default {
  components: {
    ConsultantNavbar,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition
  },
  data() {
    return {
      showMenu: true,
      sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
    };
  },
  computed: {
    userLogged() {
      return {
        compId: this.$route.params.compId,
        isConsultant: this.$store.getters.isConsultant
      };
    }
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
.custom-content {
  padding-top: 0 !important;
  padding-bottom: 0px !important;
}
</style>
