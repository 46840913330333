import axios from 'axios';
import {
  checkBlank,
  formatDate,
  formatForGrid,
  replaceCollectionType,
  replaceServiceType,
  setLocalTime
} from '@/util/dataFormatting';

const state = {
  accountsPayableBillStatsChart: [],
  accountsPayableTotalServiceChargeChart: [],
  accountsPayableTotalServiceCharge: 0,
  accountsPayable: [],
  isAccountsPayableChartsLoad: true,
  isAccountsPayableGridLoad: true,
  error: null
};

const mutations = {
  SET_ACCOUNTS_PAYABLE_BILL_STATS_CHART(state, data) {
    state.accountsPayableBillStatsChart = data;
  },
  SET_ACCOUNTS_PAYABLE_TOTAL_SERVICE_CHARGE(state, data) {
    state.accountsPayableTotalServiceCharge = data;
  },
  SET_ACCOUNTS_PAYABLE_TOTAL_SERVICE_CHARGE_CHART(state, data) {
    state.accountsPayableTotalServiceChargeChart = data;
  },
  SET_ACCOUNTS_PAYABLE(state, data) {
    state.accountsPayable = data;
  },
  SET_ACCOUNTS_PAYABLE_CHARTS_LOAD(state, load) {
    state.isAccountsPayableChartsLoad = load;
  },
  SET_ACCOUNTS_PAYABLE_GRID_LOAD(state, load) {
    state.isAccountsPayableGridLoad = load;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

const actions = {
  async setAccountsPayable({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/accounts_payable${
              url.dateData !== undefined
                ? `${
                    url.dateData.dateReceived !== ''
                      ? `?CreateDate=${url.dateData.dateReceived}`
                      : `?DueDateFrom=${url.dateData.dueDateFrom}&DueDateTo=${url.dateData.dueDateTo}`
                  }`
                : ''
            }`
          : '/accounts_payable'
      )
      .then((res) => {
        if (res.status === 200) {
          const stats = res.data.data.accountsPayableChart[0];
          const billStatsChart = [
            { type: 'Bills', total: stats.numberOfInvoices },
            { type: 'Locations', total: stats.numberOfLocations },
            { type: 'Vendors', total: stats.numberOfVendors },
            { type: 'Penalties / Late Fees', total: stats.numberOfPenalties }
          ];

          let totalServiceCharge = 0;
          const totalServiceChargeChart =
            res.data.data.serviceGroupCharge.filter((charge) => {
              charge.totalSpend = charge.totalCharge;
              totalServiceCharge += charge.totalCharge;
              switch (charge.serviceGroupName) {
                case 'electric':
                  charge.serviceType = 'Electricity';
                  break;
                case 'natural_gas':
                  charge.serviceType = 'Gas';
                  break;
                case 'water':
                  charge.serviceType = 'Water';
                  break;
                case 'trash':
                  charge.serviceType = 'Trash';
                  break;
                case 'account':
                  charge.serviceType = 'Account';
                  break;
                case 'telecom':
                  charge.serviceType = 'Telecom';
                  break;
                case 'sewer':
                  charge.serviceType = 'Sewer';
                  break;
                case 'drag_reducing_agent':
                  charge.serviceType = 'Drag Reducing Agent';
                  break;
              }
              return charge.serviceGroupName !== 'steam';
            });

          totalServiceCharge = formatForGrid(totalServiceCharge);

          const accountsPayable = res.data.data.accountsPayableList.map(
            (item) => {
              item.serviceIcons = replaceServiceType(item.services, true);
              item.services = replaceServiceType(item.services);
              item.collectionTypeIcons = replaceCollectionType(
                item.collectionType,
                true
              );
              item.collectionType = replaceCollectionType(item.collectionType);

              //Added this property to sort the AP column
              if (item.apFileInfo) {
                item.apFileInfoDesc = 'Ap File Info';
              } else {
                item.apFileInfoDesc = 'No Ap File Info';
              }

              item.vendorName = `${item.vendorName} - ${item.accountNumber}`;

              item.apFileDate = !!item.apFileDate
                ? setLocalTime(item.apFileDate, true)
                : item.apFileDate;

              item.apFileInfo = !!item.apFileInfo
                ? item.apFileInfo.map((infoItem) => {
                    infoItem.CoCd = !!infoItem.Company_Reference
                      ? infoItem.Company_Reference
                      : infoItem.CoCd;
                    infoItem['Cost Ctr'] =
                      !!infoItem.Worktag_Cost_Center_Reference_ID
                        ? infoItem.Worktag_Cost_Center_Reference_ID
                        : infoItem['Cost Ctr'];
                    infoItem['Line Text'] = !!infoItem['Line Order']
                      ? infoItem['Line Order']
                      : infoItem['Line Text'];
                    infoItem.invoice_date = !!infoItem.Invoice_Date
                      ? infoItem.Invoice_Date
                      : infoItem.invoice_date;
                    infoItem.invoice_date = !!infoItem.invoice_date
                      ? isNaN(infoItem.invoice_date)
                        ? formatDate(infoItem.invoice_date)
                        : formatDate(
                            `${infoItem.invoice_date.slice(
                              0,
                              2
                            )}/${infoItem.invoice_date.slice(
                              2,
                              4
                            )}/20${infoItem.invoice_date.slice(4, 6)}`
                          )
                      : infoItem.invoice_date;
                    infoItem.amount = !!infoItem.amount
                      ? infoItem.amount
                      : formatForGrid(infoItem.amount, '$');
                    return infoItem;
                  })
                : item.apFileInfo;

              return item;
            }
          );

          commit('SET_ACCOUNTS_PAYABLE_BILL_STATS_CHART', billStatsChart);
          commit(
            'SET_ACCOUNTS_PAYABLE_TOTAL_SERVICE_CHARGE',
            totalServiceCharge
          );
          commit(
            'SET_ACCOUNTS_PAYABLE_TOTAL_SERVICE_CHARGE_CHART',
            totalServiceChargeChart
          );
          commit('SET_ACCOUNTS_PAYABLE', accountsPayable);
          commit('SET_ACCOUNTS_PAYABLE_CHARTS_LOAD', false);
          commit('SET_ACCOUNTS_PAYABLE_GRID_LOAD', false);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setAccountsPayableLoads({ commit }) {
    commit('SET_ACCOUNTS_PAYABLE_CHARTS_LOAD', true);
    commit('SET_ACCOUNTS_PAYABLE_GRID_LOAD', true);
  }
};

const getters = {
  accountsPayableBillStatsChart: (state) => {
    return state.accountsPayableBillStatsChart;
  },
  accountsPayableTotalServiceCharge: (state) => {
    return state.accountsPayableTotalServiceCharge;
  },
  accountsPayableTotalServiceChargeChart: (state) => {
    return state.accountsPayableTotalServiceChargeChart;
  },
  accountsPayable: (state) => {
    return state.accountsPayable;
  },
  isAccountsPayableChartsLoad: (state) => {
    return state.isAccountsPayableChartsLoad;
  },
  isAccountsPayableGridLoad: (state) => {
    return state.isAccountsPayableGridLoad;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
