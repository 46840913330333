import { Message } from 'element-ui';
/*  CONSTANT  VARIABLES */
export const usStatesConstant = [
  { label: 'Alabama', key: 'AL' },
  { label: 'Alaska', key: 'AK' },
  { label: 'Arizona', key: 'AZ' },
  { label: 'Arkansas', key: 'AR' },
  { label: 'California', key: 'CA' },
  { label: 'Colorado', key: 'CO' },
  { label: 'Connecticut', key: 'CT' },
  { label: 'Delaware', key: 'DE' },
  { label: 'Florida', key: 'FL' },
  { label: 'Georgia', key: 'GA' },
  { label: 'Hawaii', key: 'HI' },
  { label: 'Idaho', key: 'ID' },
  { label: 'Illinois', key: 'IL' },
  { label: 'Indiana', key: 'IN' },
  { label: 'Iowa', key: 'IA' },
  { label: 'Kansas', key: 'KS' },
  { label: 'Kentucky', key: 'KY' },
  { label: 'Louisiana', key: 'LA' },
  { label: 'Maine', key: 'ME' },
  { label: 'Maryland', key: 'MD' },
  { label: 'Massachusetts', key: 'MA' },
  { label: 'Michigan', key: 'MI' },
  { label: 'Minnesota', key: 'MN' },
  { label: 'Mississippi', key: 'MS' },
  { label: 'Missouri', key: 'MO' },
  { label: 'Montana', key: 'MT' },
  { label: 'Nebraska', key: 'NE' },
  { label: 'Nevada', key: 'NV' },
  { label: 'New Hampshire', key: 'NH' },
  { label: 'New Jersey', key: 'NJ' },
  { label: 'New Mexico', key: 'NM' },
  { label: 'New York', key: 'NY' },
  { label: 'North Carolina', key: 'NC' },
  { label: 'North Dakota', key: 'ND' },
  { label: 'Ohio', key: 'OH' },
  { label: 'Oklahoma', key: 'OK' },
  { label: 'Oregon', key: 'OR' },
  { label: 'Pennsylvania', key: 'PA' },
  { label: 'Rhode Island', key: 'RI' },
  { label: 'South Carolina', key: 'SC' },
  { label: 'South Dakota', key: 'SD' },
  { label: 'Tennessee', key: 'TN' },
  { label: 'Texas', key: 'TX' },
  { label: 'Utah', key: 'UT' },
  { label: 'Vermont', key: 'VT' },
  { label: 'Virginia', key: 'VA' },
  { label: 'Washington', key: 'WA' },
  { label: 'West Virginia', key: 'WV' },
  { label: 'Wisconsin', key: 'WI' },
  { label: 'Wyoming', key: 'WY' },
  { label: 'American Samoa', key: 'AS' },
  { label: 'Guam', key: 'GU' },
  { label: 'Northern Mariana Islands', key: 'MP' },
  { label: 'Puerto Rico', key: 'PR' },
  { label: 'U.S. Virgin Islands', key: 'VI' },
  { label: 'District of Columbia', key: 'DC' },
  { label: 'Aguascalientes', key: 'AGS' },
  { label: 'Baja California', key: 'BC' },
  { label: 'Baja California Sur', key: 'BCS' },
  { label: 'Campeche', key: 'CAMP' },
  { label: 'Chiapas', key: 'CHIS' },
  { label: 'Chihuahua', key: 'CHIH' },
  { label: 'Ciudad de México', key: 'CDMX' },
  { label: 'Coahuila', key: 'COAH' },
  { label: 'Colima', key: 'COL' },
  { label: 'Durango', key: 'DGO' },
  { label: 'Guanajuato', key: 'GTO' },
  { label: 'Guerrero', key: 'GRO' },
  { label: 'Hidalgo', key: 'HGO' },
  { label: 'Jalisco', key: 'JAL' },
  { label: 'México', key: 'MEX' },
  { label: 'Michoacán', key: 'MICH' },
  { label: 'Morelos', key: 'MOR' },
  { label: 'Nayarit', key: 'NAY' },
  { label: 'Nuevo León', key: 'NL' },
  { label: 'Oaxaca', key: 'OAX' },
  { label: 'Puebla', key: 'PUE' },
  { label: 'Querétaro', key: 'QRO' },
  { label: 'Quintana Roo', key: 'QROO' },
  { label: 'San Luis Potosí', key: 'SLP' },
  { label: 'Sinaloa', key: 'SIN' },
  { label: 'Sonora', key: 'SON' },
  { label: 'Tabasco', key: 'TAB' },
  { label: 'Tamaulipas', key: 'TAMPS' },
  { label: 'Tlaxcala', key: 'TLAX' },
  { label: 'Veracruz', key: 'VER' },
  { label: 'Yucatán', key: 'YUC' },
  { label: 'Zacatecas', key: 'ZAC' },
  { label: 'Alberta', key: 'AB' },
  { label: 'British Columbia', key: 'BC' },
  { label: 'Manitoba', key: 'MB' },
  { label: 'New Brunswick', key: 'NB' },
  { label: 'Newfoundland and Labrador', key: 'NL' },
  { label: 'Nova Scotia', key: 'NS' },
  { label: 'Ontario', key: 'ON' },
  { label: 'Prince Edward Island', key: 'PE' },
  { label: 'Quebec', key: 'QC' },
  { label: 'Saskatchewan', key: 'SK' }
];
export const serviceTypesConstant = [
  { key: 'electric', label: 'Electricity' },
  { key: 'natural_gas', label: 'Gas' },
  { key: 'water', label: 'Water' },
  { key: 'telecom', label: 'Telecom' },
  { key: 'trash', label: 'Trash' },
  { key: 'sewer', label: 'Sewer' },
  { key: 'sanitation', label: 'Sanitation' },
  { key: 'lighting', label: 'Lighting' },
  { key: 'irrigation', label: 'Irrigation' },
  { key: 'steam', label: 'Steam' },
  { key: 'district_heat', label: 'District Heat' },
  { key: 'propane', label: 'Propane' },
  { key: 'fire_protection', label: 'Fire Protection' },
  { key: 'internet', label: 'Internet' },
  { key: 'landline_phone', label: 'Landline Phone' },
  { key: 'television', label: 'Television' },
  { KEY: 'drag_reducing_agent', label: 'Drag Reducing Agent' }
];

export const openCloseStatusConstant = [
  'Received',
  'In Progress',
  'Pending',
  'Done'
];

// Define a mapping of service types to icons
export const serviceTypeIcons = {
  account: 'fas fa-trash',
  electric: 'fas fa-bolt',
  natural_gas: 'fad fa-fire',
  'natural gas': 'fad fa-fire',
  water: 'fad fa-tint',
  telecom: 'fas fa-phone',
  trash: 'fad fa-trash-can',
  sewer: 'fad fa-manhole',
  sanitation: 'fas fa-soap',
  lighting: 'fas fa-lightbulb',
  irrigation: 'fas fa-shower',
  steam: 'fas fa-cloud',
  district_heat: 'fas fa-thermometer-full',
  propane: 'fas fa-rocket',
  fire_protection: 'fas fa-fire-extinguisher',
  internet: 'fas fa-wifi',
  landline_phone: 'fas fa-phone',
  television: 'fas fa-tv',
  drag_reducing_agent: 'fas-solid fa-pipe-section'
};

//Service Types for select input fields
export const serviceTypesSelectConstant = [
  {
    id: 3,
    label: 'Natural Gas',
    name: 'natural_gas',
    icon: 'fas fa-fire'
  },
  {
    id: 4,
    label: 'Electric',
    name: 'electric',
    icon: 'fas fa-bolt'
  },
  {
    id: 5,
    label: 'Lighting',
    name: 'lighting',
    icon: 'fas fa-bolt'
  },
  {
    id: 6,
    label: 'Sanitation',
    name: 'sanitation',
    icon: 'fas fa-faucet'
  },
  {
    id: 7,
    label: 'Sewer',
    name: 'sewer',
    icon: 'fas fa-manhole'
  },
  {
    id: 8,
    label: 'Water',
    name: 'water',
    icon: 'fas fa-droplet'
  },
  {
    id: 9,
    label: 'Irrigation',
    name: 'irrigation',
    icon: 'fas fa-hose'
  },
  {
    id: 11,
    label: 'Invoice',
    name: 'account',
    icon: 'fas fa-laptop'
  },
  {
    id: 12,
    label: 'Telecom',
    name: 'telecom',
    icon: 'fas fa-phone'
  },
  {
    id: 13,
    label: 'Trash',
    name: 'trash',
    icon: 'fas fa-trash'
  },
  {
    id: 14,
    label: 'Steam',
    name: 'steam',
    icon: 'fas fa-cloud'
  },
  {
    id: 15,
    label: 'District Heat',
    name: 'district_heat',
    icon: 'fas fa-fireplace'
  },
  {
    id: 16,
    label: 'Propane',
    name: 'propane',
    icon: 'fas fa-fire'
  },
  {
    id: 17,
    label: 'Fireline',
    name: 'fireline',
    icon: 'fas fa-fire-hydrant'
  },
  {
    id: 18,
    label: 'Storm Water',
    name: 'storm_water',
    icon: 'fas fa-cloud-showers-water'
  },
  {
    id: 21,
    label: 'Recycling',
    name: 'recycling',
    icon: 'fas fa-recycle'
  },
  {
    id: 22,
    label: 'Internet',
    name: 'internet',
    icon: 'fas fa-wifi'
  },
  {
    id: 23,
    label: 'Landline Phone',
    name: 'landline_phone',
    icon: 'fas fa-phone-flip'
  },
  {
    id: 24,
    label: 'Television',
    name: 'television',
    icon: 'fas fa-tv'
  },
  {
    id: 25,
    label: 'Mobile Phone',
    name: 'mobile_phone',
    icon: 'fas fa-mobile'
  },
  {
    id: 26,
    label: 'Cable',
    name: 'cable',
    icon: 'fas fa-network-wired'
  },
  {
    id: 27,
    label: 'Satellite TV',
    name: 'satellite_tv',
    icon: 'fas fa-tv'
  },
  {
    id: 28,
    label: 'Drag Reducing Agent',
    name: 'drag_reducing_agent',
    icon: 'fa-solid fa-flask'
  }
];

export const passwordValidationRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/;

export function includesIgnoreCase(property, searchValue) {
  return (
    Boolean(property) &&
    property.toString().toLowerCase().includes(searchValue.toLowerCase())
  );
}

export function displayMessage(message, type) {
  Message({ message, type });
}

export async function assignSourceObjectToTargetObject(source, target) {
  if (!source || !target) return;
  await Object.keys(target).forEach((prop) => {
    if (source.hasOwnProperty(prop)) {
      target[prop] = source[prop];
    }
  });
  return target;
}

/**
 * Vue Virtual Table grid only takes pixels (pass number and the 'px' is added in the background)
 * Grid needs to display properly for various monitor sizes/pixels and browser zoom percentage magnification
 * @param {Number} argA height needed to be returned for monitors with height 1080px and under
 * @param {Number} argB height needed to be returned for monitors with height greater than 1080px and less than or equal to 1440px
 * @param {Number} argC height needed to be returned for monitors with height greater than 1440px and less than or equal to 1680px
 * @param {Number} argD height needed to be returned for everything else (for now; more args can be added later)
 * @returns {Number} Returns the required height times the zoom ratio
 */
export function formatGridHeight(argA, argB, argC, argD) {
  const zoom = Math.round((window.outerWidth / window.innerWidth) * 100);
  const height = screen.height;

  switch (true) {
    case height <= 1080:
      return argA * (100 / zoom);
    case height > 1080 && height <= 1440:
      return argB * (100 / zoom);
    case height > 1440 && height <= 1680:
      return argC * (100 / zoom);
    default:
      return argD * (100 / zoom);
  }
}

/**
 * Helper function for asynchronous functions with exception handling
 * @param {Function} fn Async function
 * @param {...any} args Optional arguments to be passed to the function.
 * @returns {Promise} A Promise that resolves to an object with 'response' and 'error' properties.
 */
export const withAsync = async (fn, ...args) => {
  return await fn(...args)
    .then((response) => {
      return {
        response,
        error: null
      };
    })
    .catch((error) => {
      console.error('Something went wrong:', error);
      return {
        response: null,
        error
      };
    });
};

/**
 * Default List of Scopes to be used as a filter under Sustainability Reports
 * @returns {Array} List of Sustainability Scopes.
 */
export const sustainabilityScopeList = [
  {
    id: 1,
    label: 'Scope 1',
    icon: 'fas fa-fire',
    description: 'Scope 1 includes natural gas and propane'
  },
  {
    id: 2,
    label: 'Scope 2',
    icon: 'fas fa-bolt',
    description: 'Scope 2 includes electric and lighting'
  },
  {
    id: 3,
    label: 'Scope 3',
    icon: 'fas fa-lightbulb',
    description: 'Scope 3 includes electric and lighting'
  }
];
