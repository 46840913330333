import Vue from 'vue';
import Vuex from 'vuex';
import authState from './modules/authState';
import locationsStore from './modules/locationsState';
import locationStore from './modules/locationState';
import energyStarStore from './modules/energyStarState';
import accountsStore from './modules/accountsState';
import accountStore from './modules/accountState';
import alertsStore from './modules/alertsState';
import billsStore from './modules/billsState';
import billStore from './modules/billState';
import contractsStore from './modules/contractsState';
import budgetsStore from './modules/budgetsState';
import APStore from './modules/APState';
import openCloseState from './modules/openCloseState';
import consultantStore from './modules/consultantState';
import reportsStore from './modules/reportsState';
import supportStore from './modules/supportState';
import BPStore from './modules/BPState';
import usersStore from './modules/usersState';
import filterState from './modules/FiltersState/filterState';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authState,
    locationsStore,
    locationStore,
    energyStarStore,
    accountsStore,
    accountStore,
    alertsStore,
    billsStore,
    billStore,
    contractsStore,
    budgetsStore,
    APStore,
    consultantStore,
    reportsStore,
    BPStore,
    usersStore,
    filterState,
    openCloseState,
    supportStore
  }
});
