import axios from 'axios';
import {
  formatBillMonth,
  formatDate,
  replaceServiceType
} from '@/util/dataFormatting';

const state = {
  budgets: [],
  pastBudgetData: [],
  error: null,
  pastBudgetDataLoading: false,
  addUpdateBudgetLoading: false
};

const mutations = {
  SET_BUDGETS(state, data) {
    state.budgets = data;
  },
  SET_PAST_BUDGET_DATA(state, data) {
    state.pastBudgetData = data;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_PAST_BUDGET_DATA_LOADING(state, data) {
    state.pastBudgetDataLoading = data;
  },
  SET_ADD_UPDATE_BUDGET_LOADING(state, data) {
    state.addUpdateBudgetLoading = data;
  }
};

const budgetUnits = {
  Electric: 'kWh',
  electric: 'kWh',
  'Natural Gas': 'Therms',
  'natural gas': 'Therms',
  natural_gas: 'Therms',
  Water: 'Gallons',
  water: 'Gallons'
};

const actions = {
  async setBudgets({ commit }, url) {
    await axios
      .get(
        url.isConsultant ? `/companies/${url.companyId}/budgets` : `/budgets`
      )
      .then((res) => {
        if (res.status === 200) {
          const budgets = res.data.data
            .sort((first, second) => {
              return new Date(second.createdAt) - new Date(first.createdAt);
            })
            .map((budget) => {
              budget.serviceTypeIcon = replaceServiceType(
                budget.serviceType.toLowerCase(),
                true
              );
              budget.createdAt = formatDate(budget.createdAt);
              budget.modifiedAt = formatDate(budget.modifiedAt);

              if (!Boolean(budget.unit)) {
                budget.unit = budgetUnits[budget.serviceType];
              }

              budget.budgetSpendTotal = 0;
              budget.actualSpendTotal = 0;
              budget.budgetUsageTotal = 0;
              budget.actualUsageTotal = 0;
              budget.budgetItems.map((budgetItem) => {
                budgetItem.month = formatBillMonth(budgetItem.month, true);
                if (!Boolean(budgetItem.unit)) {
                  budgetItem.unit = budget.unit;
                }

                budgetItem.spendDifference =
                  budgetItem.budgetSpendAmount - budgetItem.actualSpendAmount;
                budgetItem.usageDifference =
                  budgetItem.budgetUsageAmount - budgetItem.actualUsageAmount;

                budget.budgetSpendTotal += Boolean(budgetItem.budgetSpendAmount)
                  ? budgetItem.budgetSpendAmount
                  : 0;
                budget.actualSpendTotal += Boolean(budgetItem.actualSpendAmount)
                  ? budgetItem.actualSpendAmount
                  : 0;
                budget.budgetUsageTotal += Boolean(budgetItem.budgetUsageAmount)
                  ? budgetItem.budgetUsageAmount
                  : 0;
                budget.actualUsageTotal += Boolean(budgetItem.actualUsageAmount)
                  ? budgetItem.actualUsageAmount
                  : 0;
                return budgetItem;
              });
              budget.spendDifference =
                budget.budgetSpendTotal - budget.actualSpendTotal;
              budget.usageDifference =
                budget.budgetUsageTotal - budget.actualUsageTotal;
              return budget;
            });
          commit('SET_BUDGETS', budgets);
        }
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  async getPastBudgetData({ commit }, pastBudgetInfo) {
    commit('SET_PAST_BUDGET_DATA_LOADING', true);
    return await axios
      .get(
        `/budgets/${pastBudgetInfo.locationId}/previousData?ServiceGroupId=${pastBudgetInfo.serviceGroupId}&StartYear=${pastBudgetInfo.startYear}&StartMonth=${pastBudgetInfo.startMonth}&NumOfMonths=${pastBudgetInfo.numOfMonths}`
      )
      .then((res) => {
        const pastBudgetData = res.data.data;
        commit('SET_PAST_BUDGET_DATA', pastBudgetData);
        commit('SET_PAST_BUDGET_DATA_LOADING', false);
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        commit('SET_PAST_BUDGET_DATA_LOADING', false);
        console.log(err);
        return err.response.status;
      });
  },
  async addBudget({ commit }, budgetInfo) {
    commit('SET_ADD_UPDATE_BUDGET_LOADING', true);
    return await axios
      .post('/budgets', budgetInfo)
      .then((res) => {
        commit('SET_ADD_UPDATE_BUDGET_LOADING', false);
        return res.status;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        commit('SET_ADD_UPDATE_BUDGET_LOADING', false);
        console.log(err);
        return err.response.status;
      });
  },
  async updateBudget({ commit }, params) {
    commit('SET_ADD_UPDATE_BUDGET_LOADING', true);
    return await axios
      .put(`/budgets/${params.budgetId}`, params.budgetInfo)
      .then((res) => {
        commit('SET_ADD_UPDATE_BUDGET_LOADING', false);
        return res.status;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        commit('SET_ADD_UPDATE_BUDGET_LOADING', true);
        console.log(err);
        return err.response.status;
      });
  },
  async deleteBudget({ commit }, budgetId) {
    return await axios
      .delete(`/budgets/${budgetId}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        console.log(err);
        return err.response.status;
      });
  }
};

const getters = {
  budgets: (state) => {
    return state.budgets;
  },
  pastBudgetData: (state) => {
    return state.pastBudgetData;
  },
  pastBudgetDataLoadingState: (state) => {
    return state.pastBudgetDataLoading;
  },
  addUpdateBudgetLoadingState: (state) => {
    return state.addUpdateBudgetLoading;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
