import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store/store';

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    const container = document.querySelector('.main-panel');
    if (container !== null) {
      container.scrollTop = 0;
    }
  }
});

router.beforeEach(async (to, from, next) => {
  const isAuthorized = localStorage.getItem('isAuthorized') === 'true';
  const publicPages = [
    'Login',
    'Forgot',
    'Reset',
    'Register',
    'SSO Login',
    'SSO ACS'
  ];
  const now = new Date();
  const expireIn = new Date(store.getters.getExpirationDate);

  //REDIRECT THE USER TO LOGIN IF NOT AUTHORIZED.
  if (!isAuthorized && !publicPages.includes(to.name)) {
    const ssoLoginPath = localStorage.getItem('ssoLoginPath');
    // Determine the redirection path
    const redirectPath =
      ssoLoginPath && ssoLoginPath.trim() !== '' ? ssoLoginPath : '/login';
    if (
      from.fullPath === '/login' ||
      (ssoLoginPath && from.fullPath === ssoLoginPath)
    ) {
      localStorage.setItem('prevRoute', to.fullPath);
    }
    localStorage.removeItem('isAuthorized');
    localStorage.removeItem('ssoLoginPath');
    next({ path: redirectPath });
  }

  //HANDLE REFRESH LOGIC
  else if (isAuthorized && expireIn.valueOf() < now.valueOf()) {
    await store
      .dispatch('setRefreshAuth')
      .then(async (res) => {
        if (res.status === 200) {
          let isConsultant = store.getters.isConsultant;
          let isLimitedClient = store.getters.isLimitedClient;
          let isSuperConsultant = store.getters.isSuperConsultant;
          let companyId = Boolean(isConsultant)
            ? to.params.compId
            : res.data.data.companyId;
          let hasApAccess;
          let hasBpAccess;
          let hasBudgetsAccess;
          let hasAlertsAccess;
          let hasEnergyStarAccess;
          let hasOpenCloseAccess;
          let companyHasSupportAccess;
          let enableAlertsReadonly = store.getters.enableAlertsReadonly;
          await store
            .dispatch('setCompanySupportAccess', companyId)
            .then(() => {
              companyHasSupportAccess =
                store.getters.getCompanyHasSupportAccess;
            });

          //SET COMPANY UPON REFRESHING THE PAGE
          await store.dispatch('setCompany', companyId).then(() => {
            hasApAccess = store.getters.getCompany.apAccess;
            hasBpAccess = store.getters.getCompany.billPayAccess;
            hasBudgetsAccess = store.getters.getCompany.budgetsAccess;
            hasAlertsAccess = store.getters.getCompany.alertsAccess;
            hasEnergyStarAccess = store.getters.getCompany.energyStarAccess;
            hasOpenCloseAccess = store.getters.getCompany.openCloseAccess;
          });

          /* ROUTE GUARDS */
          //Guard for Reports to prevent url injection
          if (to.name === 'Reports' && from.name === null) {
            if (isLimitedClient) {
              //Redirect the user to locations tab if no access
              next({
                path: Boolean(isConsultant)
                  ? `/companies/${companyId}/locations`
                  : `/locations`
              });
            }
          }
          //Guard for Contracts to prevent url injection
          if (to.name === 'Contracts' && from.name === null) {
            if (isLimitedClient) {
              //Redirect the user to locations tab if no access
              next({
                path: Boolean(isConsultant)
                  ? `/companies/${companyId}/locations`
                  : `/locations`
              });
            }
          }
          //Guard for Ap page to prevent url injection
          if (to.name === 'Bill Deliveries' && from.name === null) {
            if (!Boolean(hasApAccess)) {
              //Redirect the user to locations tab if no ap access
              next({
                path: Boolean(isConsultant)
                  ? `/companies/${companyId}/locations`
                  : `/locations`
              });
            }
          }
          //Route Guard for Energy Star page to prevent url injection
          if (
            (to.name === 'Energy Star-Consultant' ||
              to.name === 'Energy Star') &&
            from.name === null
          ) {
            //Redirect the user to locations if company is Bon Secours Mercy Health
            if (!Boolean(hasEnergyStarAccess)) {
              next({
                path: Boolean(isConsultant)
                  ? `/companies/${companyId}/locations`
                  : `/locations`
              });
            }
          }
          //Guard for Bill Pay page to prevent url injection
          if (
            (to.name === 'Bill Pay-Consultant' || to.name === 'Bill Pay') &&
            from.name === null
          ) {
            if (!Boolean(hasBpAccess) || isLimitedClient) {
              //Redirect the user to locations tab if no bp access
              next({
                path: Boolean(isConsultant)
                  ? `/companies/${companyId}/locations`
                  : `/locations`
              });
            }
          }
          //Guard for Budgets page to prevent url injection
          if (to.name === 'Budgets' && from.name === null) {
            if (!Boolean(hasBudgetsAccess) || isLimitedClient) {
              //Redirect the user to locations tab if no budgets access
              next({
                path: Boolean(isConsultant)
                  ? `/companies/${companyId}/locations`
                  : `/locations`
              });
            }
          }
          //Guard for Alerts Page to prevent url inject
          if (
            (to.name === 'Alerts' || to.name === 'Alerts-Consultant') &&
            from.name === null
          ) {
            if (!Boolean(hasAlertsAccess) || isLimitedClient) {
              //Redirect the user to locations tab if has alerts access
              next({
                path: Boolean(isConsultant)
                  ? `/companies/${companyId}/locations`
                  : `/locations`
              });
            } else if (hasBpAccess && enableAlertsReadonly) {
              //Redirect the user to locations tab if has alerts access
              next({
                path: Boolean(isConsultant)
                  ? `/companies/${companyId}/alertsro`
                  : `/alertsro`
              });
            }
          }
          if (
            (to.name === 'Alerts ' ||
              to.name === 'Alerts-Consultant-Readonly') &&
            from.name === null
          ) {
            if (
              !Boolean(hasAlertsAccess) ||
              isLimitedClient ||
              !hasBpAccess ||
              !enableAlertsReadonly
            ) {
              next({
                path: Boolean(isConsultant)
                  ? `/companies/${companyId}/alerts`
                  : `/alerts`
              });
            }
          }
          //Guard for Open Close page to prevent url injection
          if (to.name === 'Open/Close Accounts' && from.name === null) {
            if (!Boolean(hasOpenCloseAccess) || isLimitedClient) {
              //Redirect the user to locations tab if no open close access
              next({
                path: Boolean(isConsultant)
                  ? `/companies/${companyId}/locations`
                  : `/locations`
              });
            }
          }
          //Guard for Energy Star page to prevent url injection
          if (
            (to.name === 'Company Energy Star' ||
              to.name === 'Company Energy Star-Consultant') &&
            from.name === null
          ) {
            if (!Boolean(hasEnergyStarAccess)) {
              //Redirect the user to locations tab if no energy star access
              next({
                path: Boolean(isConsultant)
                  ? `/companies/${companyId}/locations`
                  : `/locations`
              });
            }
          }
          //Guard for Company Support Tickets Page to prevent url injection
          if (to.name === 'Ticket List' && from.name === null) {
            if (!companyHasSupportAccess) {
              //Redirect the userto locations tab if not a consultant
              next({
                path: Boolean(isConsultant)
                  ? `/companies/${companyId}/locations`
                  : `/locations`
              });
            }
          }
          //Guard for Users Page to prevent url injection
          if (to.name === 'Users' && from.name === null) {
            if (!isSuperConsultant) {
              //Redirect the userto locations tab if not a super consultant
              next({
                path: Boolean(isConsultant)
                  ? `/companies/${companyId}/locations`
                  : `/locations`
              });
            }
          }
          next();
        }
        //If Refresh Token API fails. Redirect to Login
        if (res.status === 401) {
          localStorage.removeItem('isAuthorized');
          next();
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          next({ name: 'Login' });
        }
      });
  } else if (
    isAuthorized &&
    to.name === 'Login' &&
    from.name !== 'Register' &&
    from.name !== 'Login'
  ) {
    next({ name: from.name });
  } else {
    //Navigate to the component that the user clicked on
    next();
  }
});

export default router;
