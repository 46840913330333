<template>
  <base-nav
    v-model="showMenu"
    class="top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <i :href="homeUrl" class="fa fa-home fa-lg home-custom"></i>
      <a class="navbar-brand" :href="homeUrl">{{ companyName }}Home</a>
    </div>
    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <sidebar-share></sidebar-share>
      <a @click="logout" style="cursor: pointer">
        <div class="photo">
          <img src="/img/Logout.jpg" />
        </div>
      </a>
    </ul>
  </base-nav>
</template>
<script>
import { BaseNav } from '@/components';
import SidebarShare from './SidebarSharePlugin';

export default {
  components: {
    BaseNav,
    SidebarShare
  },
  computed: {
    companyName() {
      return Boolean(this.$store.getters.getCompany.name)
        ? `${this.$store.getters.getCompany.name} - `
        : '';
    },
    routeName() {
      const { name } = this.$route;
      const companyName = this.$store.getters.getCompany;
      return name === 'Home'
        ? this.capitalizeFirstLetter(name)
        : `${companyName.name} - ${this.capitalizeFirstLetter(name)}`;
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      homeUrl: ''
    };
  },
  async created() {
    await this.$store.dispatch('setCompany', this.$route.params.compId);
    await this.$store.dispatch(
      'setCompanySupportAccess',
      this.$route.params.compId
    );
    this.homeUrl = this.$store.getters.isConsultant
      ? '/#/consultant'
      : '/#/home';
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    async logout() {
      await this.$store.dispatch('setLogout');
      location.reload();
    }
  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
  background-color: #103f5f !important;
  margin-bottom: 12px;
}
.home-custom {
  color: #4bd0bc;
}
</style>
