import axios from 'axios';
import {
  checkBlank,
  formatGridText,
  formatBillMonth,
  formatDate,
  formatForGrid,
  assignCurrencySign,
  sortReportsByDate,
  sortStrings
} from '@/util/dataFormatting';

const state = {
  filters: [],
  accounts: [],
  meters: [],
  customAttributes: [],
  customAttributesValues: {},
  states: [],
  serviceTypes: [],
  locationTypes: [],
  locationNamesList: [],
  vendors: [],
  childCompanies: [],
  reportList: [],
  reportData: [],
  headers: [],
  isReportGridLoading: true,
  chargetypes: [],
  error: null,
  companyFilters: [],
  locationMissingInvoices: [],
  reportsCompanyId: null,
  loadingCompanyMissingInvoice: false,
  companyMissingInvoices: [],
  bpBusinessUnits: [],
  selectedScopes: []
};

const mutations = {
  SET_ACCOUNTS(state, data) {
    state.accounts = data;
  },
  SET_METERS(state, data) {
    state.meters = data;
  },
  SET_CUSTOM_ATTRIBUTES(state, data) {
    state.customAttributes = data;
  },
  SET_STATES(state, data) {
    state.states = data;
  },
  SET_SERVICE_TYPES(state, data) {
    state.serviceTypes = data;
  },
  SET_LOCATION_TYPES(state, data) {
    state.locationTypes = data;
  },
  SET_LOCATION_NAMES_LIST(state, data) {
    state.locationNamesList = data;
  },
  SET_VENDORS(state, data) {
    state.vendors = data;
  },
  SET_CHILD_COMPANIES(state, data) {
    state.childCompanies = data;
  },
  SET_CUSTOM_ATTR_VALUES(state, data) {
    state.customAttributesValues = data;
  },
  SET_REPORTS(state, data) {
    state.reportList = data;
  },
  SET_REPORT_DATA(state, data) {
    state.reportData = data;
  },
  SET_GRID_LOADING(state, data) {
    state.isReportGridLoading = data;
  },
  SET_HEADERS(state, data) {
    state.headers = data;
  },
  SET_CHARGES_TYPE(state, data) {
    state.chargetypes = data;
  },
  SET_FILTERS(state, data) {
    state.filters = data;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_COMBINED_COMPANY_FILTERS(state, data) {
    state.companyFilters = data;
  },
  SET_LOCATIONS_MISSING_INVOICES(state, data) {
    state.locationMissingInvoices = data;
  },
  SET_REPORTS_COMPANY_ID(state, data) {
    state.reportsCompanyId = data;
  },
  SET_COMPANY_MISSING_INVOICE(state, data) {
    state.companyMissingInvoices = data;
  },
  SET_COMPANY_MISSING_INVOICE_LOADING(state, data) {
    state.loadingCompanyMissingInvoice = data;
  },
  SET_BILLPAY_BUSINESS_UNITS(state, data) {
    state.bpBusinessUnits = data;
  }
};

const actions = {
  async setFilters({ rootGetters, commit }, companyId) {
    console.time('timer');
    const parentId = rootGetters['getParentId'];
    const isParent = parentId.toString() === companyId.toString();
    const api = `/allFilters?id=${
      isParent ? parentId : companyId
    }&isParent=${isParent}`;

    const res = await axios.get(api);

    if (res.status === 200) {
      let customAttributes = res.data.data.companyCustomAttributes;
      let bpBusinessUnits = res.data.data.billPayBusinessUnits;
      let childCompanies = res.data.data.childCompanies;
      const filters = res.data.data.filters;
      let isIncluded = [];
      let accounts = [];
      let states = [];
      let serviceTypes = [];
      let locationNamesList = [];
      let locationTypes = [];
      let vendors = [];
      let meters = [];
      const serviceTypeId = {};
      const vendorId = {};
      const siteId = {};
      const locationTypeId = {};
      const accountId = {};
      //Set filter values
      filters.forEach((item) => {
        /** Check if the states of each data returned by the API does not exist in the isIncluded array */
        if (!isIncluded.includes(item.state)) {
          isIncluded = [...isIncluded, item.state];
          switch (item.state) {
            case 'Ontario':
              item.fullStateName = item.state;
              break;
            case 'PR':
              item.fullStateName = 'Puerto Rico';
              break;
          }
          states = [
            ...states,
            {
              companyId: item.companyId,
              state: item.state,
              fullStateName: item.fullStateName
            }
          ];
        }
        //SERVICE TYPE ID
        if (!serviceTypeId.hasOwnProperty(item.serviceTypeId)) {
          serviceTypeId[item.serviceTypeId] = {
            serviceStates: [item.state],
            servicesVendors: [item.vendorId],
            servicesAccounts: [item.accountId],
            servicesSites: [item.siteId],
            servicesSiteTypes: [item.locationTypeId]
          };
        } else {
          serviceTypeId[item.serviceTypeId].serviceStates.push(item.state);
          serviceTypeId[item.serviceTypeId].servicesVendors.push(item.vendorId);
          serviceTypeId[item.serviceTypeId].servicesAccounts.push(
            item.accountId
          );
          serviceTypeId[item.serviceTypeId].servicesSites.push(item.siteId);
          serviceTypeId[item.serviceTypeId].servicesSiteTypes.push(
            item.locationTypeId
          );
        }
        //VENDOR ID
        if (!vendorId.hasOwnProperty(item.vendorId)) {
          vendorId[item.vendorId] = {
            vendorsStates: [item.state],
            vendorsServices: [item.serviceTypeId],
            vendorsAccounts: [item.accountId],
            vendorsSites: [item.siteId],
            vendorsSiteTypes: [item.locationTypeId]
          };
        } else {
          vendorId[item.vendorId].vendorsStates.push(item.state);
          vendorId[item.vendorId].vendorsServices.push(item.serviceTypeId);
          vendorId[item.vendorId].vendorsAccounts.push(item.accountId);
          vendorId[item.vendorId].vendorsSites.push(item.siteId);
          vendorId[item.vendorId].vendorsSiteTypes.push(item.locationTypeId);
        }
        //LOCATIONS ID
        if (!siteId.hasOwnProperty(item.siteId)) {
          siteId[item.siteId] = {
            locationsServices: [item.serviceTypeId],
            locationsVendors: [item.vendorId],
            locationsAccounts: [item.accountId]
          };
        } else {
          siteId[item.siteId].locationsServices.push(item.serviceTypeId);
          siteId[item.siteId].locationsVendors.push(item.vendorId);
          siteId[item.siteId].locationsAccounts.push(item.accountId);
        }
        //LOCATION TYPE ID
        if (!locationTypeId.hasOwnProperty(item.locationTypeId)) {
          locationTypeId[item.locationTypeId] = {
            locationTypesStates: [item.state],
            locationTypesSites: [item.siteId],
            locationTypesServices: [item.serviceTypeId],
            locationTypesVendors: [item.vendorId],
            locationTypesAccounts: [item.accountId]
          };
        } else {
          locationTypeId[item.locationTypeId].locationTypesStates.push(
            item.state
          );
          locationTypeId[item.locationTypeId].locationTypesSites.push(
            item.siteId
          );
          locationTypeId[item.locationTypeId].locationTypesServices.push(
            item.serviceTypeId
          );
          locationTypeId[item.locationTypeId].locationTypesVendors.push(
            item.vendorId
          );
          locationTypeId[item.locationTypeId].locationTypesAccounts.push(
            item.accountId
          );
        }
        //ACCOUNT ID
        if (!accountId.hasOwnProperty(item.accountId)) {
          accountId[item.accountId] = {
            accountsStates: [item.state],
            accountsServices: [item.serviceTypeId],
            accountsVendors: [item.vendorId],
            accountsSites: [item.siteId],
            accountsMeters: [item.meterNumber],
            accountsSiteTypes: [item.locationTypeId]
          };
        } else {
          accountId[item.accountId].accountsStates.push(item.state);
          accountId[item.accountId].accountsServices.push(item.serviceTypeId);
          accountId[item.accountId].accountsVendors.push(item.vendorId);
          accountId[item.accountId].accountsSites.push(item.siteId);
          accountId[item.accountId].accountsMeters.push(item.meterNumber);
          accountId[item.accountId].accountsSiteTypes.push(item.locationTypeId);
        }
        if (!isIncluded.includes(item.siteId)) {
          isIncluded = [...isIncluded, item.siteId];
          locationNamesList = [
            ...locationNamesList,
            {
              rowId: item.rowId,
              accounts: siteId[item.siteId].locationsAccounts,
              companyId: item.companyId,
              vendors: siteId[item.siteId].locationsVendors,
              services: siteId[item.siteId].locationsServices,
              state: item.state,
              id: item.siteId,
              name: item.locationName,
              type: item.locationType
            }
          ];
        }
        if (!isIncluded.includes(item.locationTypeId)) {
          isIncluded = [...isIncluded, item.locationTypeId];
          locationTypes = [
            ...locationTypes,
            {
              rowId: item.rowId,
              accounts:
                locationTypeId[item.locationTypeId].locationTypesAccounts,
              companyId: item.companyId,
              vendors: locationTypeId[item.locationTypeId].locationTypesVendors,
              services:
                locationTypeId[item.locationTypeId].locationTypesServices,
              states: locationTypeId[item.locationTypeId].locationTypesStates,
              name: item.locationName,
              id: item.locationTypeId,
              type: item.locationType
            }
          ];
        }
        if (!isIncluded.includes(item.accountNumber)) {
          isIncluded = [...isIncluded, item.accountNumber];
          accounts = [
            ...accounts,
            {
              companyId: item.companyId,
              states: accountId[item.accountId].accountsStates,
              sites: accountId[item.accountId].accountsSites,
              siteTypes: accountId[item.accountId].accountsSiteTypes,
              services: accountId[item.accountId].accountsServices,
              vendors: accountId[item.accountId].accountsVendors,
              meters: accountId[item.accountId].accountsMeters,
              id: item.accountId,
              accountNumber: item.accountNumber
            }
          ];
        }
        serviceTypes = [
          ...serviceTypes,
          {
            companyId: item.companyId,
            states: serviceTypeId[item.serviceTypeId].serviceStates,
            sites: serviceTypeId[item.serviceTypeId].servicesSites,
            siteTypes: serviceTypeId[item.serviceTypeId].servicesSiteTypes,
            vendors: serviceTypeId[item.serviceTypeId].servicesVendors,
            accounts: serviceTypeId[item.serviceTypeId].servicesAccounts,
            id: item.serviceTypeId,
            serviceTypeName: item.serviceTypeName
              .replace('Electric', 'Electricity')
              .replace('Sewer', 'Sewage')
          }
        ];
        vendors = [
          ...vendors,
          {
            companyId: item.companyId,
            states: vendorId[item.vendorId].vendorsStates,
            services: vendorId[item.vendorId].vendorsServices,
            sites: vendorId[item.vendorId].vendorsSites,
            siteTypes: vendorId[item.vendorId].vendorsSiteTypes,
            accounts: vendorId[item.vendorId].vendorsAccounts,
            id: item.vendorId,
            vendorName: item.vendorName
          }
        ];
      });
      // Sort filter Data
      states.sort((first, second) => {
        let stateName = first.fullStateName.toLowerCase(),
          nextStateName = second.fullStateName.toLowerCase();

        return sortStrings(stateName, nextStateName);
      });
      locationTypes.sort((first, second) => {
        let locationType = first.type.toLowerCase(),
          nextLocationType = second.type.toLowerCase();

        return sortStrings(locationType, nextLocationType);
      });
      accounts.sort((first, second) => {
        return first.accountNumber - second.accountNumber;
      });
      locationNamesList.sort((first, second) => {
        let name = first.name.toLowerCase(),
          nextName = second.name.toLowerCase();

        return sortStrings(name, nextName);
      });
      serviceTypes.sort((first, second) => {
        let serviceType = first.serviceTypeName.toLowerCase();
        let nextServiceType = second.serviceTypeName.toLowerCase();

        return sortStrings(serviceType, nextServiceType);
      });
      vendors.sort((first, second) => {
        let vendorName = first.vendorName.toLowerCase(),
          nextVendorName = second.vendorName.toLowerCase();

        return sortStrings(vendorName, nextVendorName);
      });

      //Create an object that stores all filters based on a company ID
      let companyFilters = {};
      filters.forEach((filterObject) => {
        //Check if companyId exists in the companyFilters object
        filterObject.companyId in companyFilters
          ? companyFilters[filterObject.companyId].push(filterObject)
          : (companyFilters[filterObject.companyId] = [filterObject]);
      });
      commit('SET_COMBINED_COMPANY_FILTERS', companyFilters);
      commit('SET_CHILD_COMPANIES', childCompanies);
      commit('SET_CUSTOM_ATTRIBUTES', customAttributes);
      commit('SET_STATES', states);
      commit('SET_SERVICE_TYPES', serviceTypes);
      commit('SET_LOCATION_TYPES', locationTypes);
      commit('SET_LOCATION_NAMES_LIST', locationNamesList);
      commit('SET_VENDORS', vendors);
      commit('SET_ACCOUNTS', accounts);
      commit('SET_METERS', meters);
      commit('SET_REPORTS_COMPANY_ID', companyId);
      commit('SET_BILLPAY_BUSINESS_UNITS', bpBusinessUnits);
    } else {
      console.log(res.data);
      commit('SET_ERROR', res.status);
    }
    console.timeEnd('timer');
  },
  async setChargeTypes({ commit }) {
    axios
      .get('/filters/chargeTypes')
      .then((res) => {
        let chargeTypes = res.data.data.chargeTypes;
        commit('SET_CHARGES_TYPE', chargeTypes);
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setCustomAttributesValues({ commit, state }, data) {
    const { companyId, attributeName } = data;
    await axios
      .get(
        companyId !== undefined
          ? `/filters/locationAttributeValues?id=${companyId}&attributeName=${
              attributeName === 'Division #' ? 'Division%20%23' : attributeName
            }`
          : `/filters/locationAttributeValues?attributeName=${
              data === 'Division #' ? 'Division%20%23' : data
            }`
      )
      .then((res) => {
        if (res.status === 200) {
          let customAttributesValues = state.customAttributesValues;
          customAttributesValues[
            companyId !== undefined ? attributeName : data
          ] = res.data.data.locationAttributeValues.map(
            (value) => value.attributeValue
          );
          commit('SET_CUSTOM_ATTR_VALUES', customAttributesValues);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async saveReport({ commit }, data) {
    const { companyId, reportData } = data;
    return await axios
      .post(
        companyId === undefined
          ? `/reporting/saveReportFilter`
          : `/reporting/saveReportFilter?id=${companyId}`,
        reportData
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  async editReport({ commit }, data) {
    const { id, reportData } = data;
    return await axios
      .put(`/reporting/${id}`, reportData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  async deleteReport({ commit }, reportId) {
    return await axios
      .delete(`/reporting/${reportId}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  async getReports({ commit }, companyId) {
    await axios
      .get(
        companyId !== undefined
          ? `/reporting/reportFilters?id=${companyId}`
          : '/reporting/reportFilters'
      )
      .then((res) => {
        if (res.status === 200) {
          const reports = res.data.data.reportFilters;
          commit('SET_REPORTS', reports);
          commit('SET_GRID_LOADING', false);
        }
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  async setReportData({ commit }, report) {
    if (report.reportType.includes('Emissions')) {
      report.reportType = 'Sustainability';
    }
    const { id, reportType, jsonFilter } = report;
    return await axios
      .get('/reporting/report', {
        params: jsonFilter ? { reportType, jsonFilter } : { id, reportType }
      })
      .then((res) => {
        if (res.status === 200) {
          const reportData = res.data.data.report.map((item, idx) => {
            if (!checkBlank(item.account_is_active)) {
              item.account_is_active = item.account_is_active ? 'Yes' : 'No';
            }
            if (!checkBlank(item.rollup_charges)) {
              item.rollup_charges = item.rollup_charges ? 'Yes' : 'No';
            }
            if (report.reportType === 'Locations') {
              item.site_is_active = item.site_is_active ? 'Yes' : 'No';
              if (Boolean(item.services)) {
                item.services = formatGridText(item.services)
                  .replace('Electric', 'Electricity')
                  .replace('Sewer', 'Sewage');
              }
            } else if (
              report.reportType === 'Usages' ||
              report.reportType === 'Pricing'
            ) {
              item.bill_month = formatBillMonth(item.bill_month, true);
              item.cost =
                report.reportType === 'Usages'
                  ? formatForGrid(item.cost, '$')
                  : item.cost;
              item.cost_per_sqft =
                report.reportType === 'Usages'
                  ? assignCurrencySign(item.cost_per_sqft, '$')
                  : item.cost_per_sqft;
              item.invoice_date = formatDate(item.invoice_date);
              item.kva_max = formatForGrid(item.kva_max);
              item.kvar_sum = formatForGrid(item.kvar_sum);
              item.kw_max = formatForGrid(item.kw_max);
              item.read_date_from = formatDate(item.read_date_from);
              item.read_date_to = formatDate(item.read_date_to);
              item.service_name = formatGridText(item.service_name)
                .replace('Electric', 'Electricity')
                .replace('Sewer', 'Sewage');
              item.site_is_active = item.site_is_active ? 'Yes' : 'No';
              item.usage_total =
                report.reportType === 'Usages'
                  ? formatForGrid(item.usage_total)
                  : item.usage_total;
              item.usage_uom =
                item.usage_uom !== 'kWh'
                  ? formatGridText(item.usage_uom)
                  : item.usage_uom;
            } else if (report.reportType === 'Charges') {
              item.bill_month = formatBillMonth(item.bill_month, true);
              item.site_is_active = item.site_is_active ? 'Yes' : 'No';
              item.amount = formatForGrid(item.amount, '$');
              item.invoice_date = formatDate(item.invoice_date);
              item.read_date_from = formatDate(item.read_date_from);
              item.read_date_to = formatDate(item.read_date_to);
              item.services = formatGridText(item.services)
                .replace('Electric', 'Electricity')
                .replace('Sewer', 'Sewage');
            } else if (report.reportType === 'Energy Star Meter Usages') {
              item.cost = formatForGrid(item.cost, '$');
              item.end_date = formatDate(item.end_date);
              item.start_date = formatDate(item.start_date);
              item.meter_type = Boolean(item.meter_type)
                ? item.meter_type.replace('NaturalGas', 'Natural Gas')
                : item.meter_type;
              item.meter_unit = formatGridText(item.meter_unit).replace(
                'KWh(thousand Watt-hours)',
                'kWh'
              );
              item.usage = formatForGrid(item.usage);
            } else if (report.reportType === 'Energy Star Locations') {
              item.gross_floor_area = formatForGrid(item.gross_floor_area);
              if (Boolean(item.occupancy)) {
                item.occupancy = `${item.occupancy}%`;
              }
            } else if (report.reportType === 'Energy Star Location Meters') {
              item.date_meter_became_active = formatDate(
                item.date_meter_became_active
              );
              item.date_meter_became_inactive = formatDate(
                item.date_meter_became_inactive
              );
              item.start_date = formatDate(item.start_date);
              item.units = formatGridText(item.units).replace(
                'KWh(thousand Watt-hours)',
                'kWh'
              );
            } else if (report.reportType === 'Payment Confirmation') {
              item.amount = formatForGrid(item.amount, '$');
            }
            return item;
          });
          sortReportsByDate(reportData, report.reportType);
          const properties = reportData[0];
          let propertiesData = [];
          let questionMarkProps = [
            'is_active',
            'site_is_active',
            'account_is_active',
            'rollup_charges',
            'is_a_federal_property',
            'bulk_or_metered',
            'include_in_property_metrics'
          ];
          for (var item in properties) {
            if (item === 'energy_star_score') {
              propertiesData.unshift({
                prop: item,
                name: item.split('_').join(' ').toUpperCase()
              });
            } else {
              propertiesData.push({
                prop: item,
                name: questionMarkProps.includes(item)
                  ? `${item.split('_').join(' ').toUpperCase()}?`
                  : item.split('_').join(' ').toUpperCase()
              });
            }
          }
          commit('SET_REPORT_DATA', reportData);
          commit('SET_HEADERS', propertiesData);
        }
        return res;
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  async setMissingInvoicesData({ commit }, params) {
    await axios
      .get(
        `bills/${params.companyId}/locationBills?siteIds=${params.siteIds}&fromYear=${params.fromYear}&toYear=${params.toYear}&vendorIds=${params.vendorIds}`
      )
      .then((res) => {
        if (res.status === 200) {
          commit('SET_LOCATIONS_MISSING_INVOICES', res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  async setMissingInvoicesByCompanyId({ commit }, params) {
    commit('SET_COMPANY_MISSING_INVOICE_LOADING', true);
    await axios
      .get(
        `bills/${params.companyId}/getAllSitesMissingBills?fromYear=${params.fromYear}&toYear=${params.toYear}`
      )
      .then((res) => {
        if (res.status === 200) {
          commit('SET_COMPANY_MISSING_INVOICE', res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      })
      .finally(() => {
        commit('SET_COMPANY_MISSING_INVOICE_LOADING', false);
      });
  }
};

const getters = {
  getAccounts: (state) => {
    return state.accounts;
  },
  getMeters: (state) => {
    return state.meters;
  },
  getChildCompanies: (state) => {
    return state.childCompanies;
  },
  getCustomAttributes: (state) => {
    return state.customAttributes;
  },
  getStates: (state) => {
    return state.states;
  },
  getServiceTypes: (state) => {
    return state.serviceTypes;
  },
  getLocationNamesList: (state) => {
    return state.locationNamesList;
  },
  getVendors: (state) => {
    return state.vendors;
  },
  getCustomAttributesValues: (state) => {
    return state.customAttributesValues;
  },
  getReportData: (state) => {
    return state.reportData;
  },
  getHeaders: (state) => {
    return state.headers;
  },
  getChargeTypes: (state) => {
    return state.chargetypes;
  },
  isReportGridLoading: (state) => {
    return state.isReportGridLoading;
  },
  getReportList: (state) => {
    return state.reportList;
  },
  getFilters: (state) => {
    return state.filters;
  },
  getCompanyFilters: (state) => {
    return state.companyFilters;
  },
  getLocationMissingInvoices: (state) => {
    return state.locationMissingInvoices;
  },
  getReportsCompanyId: (state) => {
    return state.reportsCompanyId;
  },
  getCompanyMissingInvoices: (state) => {
    return state.companyMissingInvoices;
  },
  getBpBusinessUnits: (state) => {
    return state.bpBusinessUnits;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
