/* eslint-disable no-console */

import { register } from 'register-service-worker';
import swal from 'sweetalert2';

const notifyUpdate = (worker) => {
  swal
    .fire({
      position: 'top-end',
      icon: 'success',
      title: 'A New Update is Available!',
      confirmButtonText: 'Great!'
    })
    .then((result) => {
      if (result.isConfirmed) {
        worker.postMessage({ action: 'skipWaiting' });
      }
    });
};

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated(registration) {
      notifyUpdate(registration.waiting);
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  });

  var refreshing;
  navigator.serviceWorker.addEventListener('controllerchange', function () {
    if (refreshing) return;
    window.location.reload();
    refreshing = true;
  });
}
