const state = {
  //LOCATIONS
  locationsSelectedFilters: {
    showInactiveAccounts: false,
    showWithoutAccounts: false,
    filterValue: '',
    selectedCondition: '',
    selectedFilterOption: '',
    statesValue: []
  },
  //LOCATION
  locationAccountsFilters: {
    showInactiveAccounts: false,
    serviceTypeValue: '',
    billTypeValue: '',
    accountTypeValue: ''
  },
  locationUsagesFilters: {
    UsagesServiceValue: ''
  },
  //ACCOUNTS
  AccountsSelectedFilters: {
    showInactiveAccounts: false,
    serviceTypeValue: '',
    billTypeValue: '',
    statesValue: [],
    accountTypeValue: ''
  },
  //ACCOUNT
  accountChargesFilters: {
    fromDate: '',
    toDate: ''
  },
  accountUsagesFilters: {
    fromDate: '',
    toDate: ''
  },
  //BILLS
  billsSelectedFilters: {
    fromDate: '',
    toDate: '',
    hasLateFee: '',
    outstanding: ''
  },
  //ALLERTS
  alertsSelectedFilters: {
    fromDate: '',
    toDate: '',
    selectAllChecked: '',
    serviceTypeValue: '',
    alertTypeValue: ''
  },
  //ACCOUNTS PAYABLE
  accountsPayableFilters: {
    servicesValue: '',
    billTypeValue: ''
  },
  //CONTRACTS
  contractsFilters: {
    statusFilter: '',
    serviceTypeFilter: '',
    stateFilterArray: []
  }
};

const mutations = {
  //LOCATIONS
  SET_LOCATIONS_SELECTED_FILTERS(state, data) {
    state.locationsSelectedFilters = data;
  },
  //LOCATION
  SET_LOCATION_ACCOUNTS_FILTERS(state, data) {
    state.locationAccountsFilters = data;
  },
  SET_LOCATION_USAGES_FILTERS(state, data) {
    state.locationUsagesFilters = data;
  },
  //ACCOUNTS
  SET_ACCOUNTS_SELECTED_FILTERS(state, data) {
    state.AccountsSelectedFilters = data;
  },
  //ACCOUNT
  SET_ACCOUNT_CHARGES_FILTERS(state, data) {
    state.accountChargesFilters = data;
  },
  SET_ACCOUNT_USAGES_FILTERS(state, data) {
    state.accountUsagesFilters = data;
  },
  SET_BILLS_SELECTED_FILTERS(state, data) {
    state.billsSelectedFilters = data;
  },
  //ALERTS
  SET_ALERTS_SELECTED_FILTERS(state, data) {
    state.alertsSelectedFilters = data;
  },
  //ACCOUNTS PAYABLE
  SET_ACCOUNTS_PAYABLE_FILTERS(state, data) {
    state.accountsPayableFilters = data;
  },
  //CONTRACTS
  SET_CONTRACTS_FILTERS(state, data) {
    state.contractsFilters = data;
  }
};

const actions = {
  //LOCATIONS
  setLocationsSelectedFilters({ commit }, filters) {
    commit('SET_LOCATIONS_SELECTED_FILTERS', filters);
  },
  //LOCATION
  setLocationAccountsFilters({ commit }, filters) {
    commit('SET_LOCATION_ACCOUNTS_FILTERS', filters);
  },
  setLocationUsagesFilters({ commit }, filters) {
    commit('SET_LOCATION_USAGES_FILTERS', filters);
  },
  //ACCOUNTS
  setAccountsSelectedFilters({ commit }, filters) {
    commit('SET_ACCOUNTS_SELECTED_FILTERS', filters);
  },
  //ACCOUNT
  setAccountChargesFilters({ commit }, filters) {
    commit('SET_ACCOUNT_CHARGES_FILTERS', filters);
  },
  setAccountUsagesFilters({ commit }, filters) {
    commit('SET_ACCOUNT_USAGES_FILTERS', filters);
  },
  //BILLS
  setBillsFilters({ commit }, filters) {
    commit('SET_BILLS_SELECTED_FILTERS', filters);
  },
  //ALERTS
  setAlertsFilters({ commit }, filters) {
    commit('SET_ALERTS_SELECTED_FILTERS', filters);
  },
  //ACCOUNTS PAYABLE
  setAccountsPayableFilters({ commit }, filters) {
    commit('SET_ACCOUNTS_PAYABLE_FILTERS', filters);
  },
  //CONTRACTS
  setContractsFilters({ commit }, filters) {
    commit('SET_CONTRACTS_FILTERS', filters);
  }
};

const getters = {
  //LOCATIONS
  locationsSelectedFilter: (state) => {
    return state.locationsSelectedFilters;
  },
  //LOCATION
  locationAccountsFilters: (state) => {
    return state.locationAccountsFilters;
  },
  locationUsagesFilters: (state) => {
    return state.locationUsagesFilters;
  },
  //ACCOUNTS
  accountsSelectedFilters: (state) => {
    return state.AccountsSelectedFilters;
  },
  //ACCOUNT
  accountChargesFilters: (state) => {
    return state.accountChargesFilters;
  },
  accountUsagesFilters: (state) => {
    return state.accountUsagesFilters;
  },
  //BILLS
  billsSelectedFilters: (state) => {
    return state.billsSelectedFilters;
  },
  //ALERTS
  alertsSelectedFilters: (state) => {
    return state.alertsSelectedFilters;
  },
  //ACCOUNTS PAYABLE
  accountsPayableFilters: (state) => {
    return state.accountsPayableFilters;
  },
  //CONTRACTS
  contractsFilters: (state) => {
    return state.contractsFilters;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
