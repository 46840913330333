import axios from 'axios';
import {
  replaceServiceType,
  formatForGrid,
  formatCurrency,
  formatDate,
  setLocalTime
} from '@/util/dataFormatting';

const state = {
  billPayConfirmation: [],
  billPayConfirmationGraphData: {},
  billPayConfirmationGraphLoading: false,
  billPayClientDeposit: [],
  billPayGl: [],
  billPayAccruals: [],
  billPayAccrualsTool: [],
  billPayGlList: [],
  billPayGlFileIdList: [],
  billPayConfirmationCompanyId: null,
  error: null,
  totalMatchedAmount: '',
  totalDepositAmount: '',
  totalPaymentConfirmationAmount: '',
  digitalPaymentsCount: '',
  checkPaymentsCount: '',
  companyBillPayNotes: [],
  companyControlWorkbookPdfs: [],
  pdfPathUrl: ''
};

const mutations = {
  SET_BILL_PAY_CONFIRMATION(state, data) {
    state.billPayConfirmation = data;
  },
  SET_DIGITAL_PAYMENTS_COUNT(state, data) {
    state.digitalPaymentsCount = data;
  },
  SET_CHECK_PAYMENTS_COUNT(state, data) {
    state.checkPaymentsCount = data;
  },
  SET_TOTAL_MATCHED_AMOUNT(state, totalAmount) {
    state.totalMatchedAmount = totalAmount;
  },
  SET_TOTAL_PAYMENT_CONFIRMATION_AMOUNT(state, totalAmount) {
    state.totalPaymentConfirmationAmount = totalAmount;
  },
  SET_PAYMENT_CONFIRMATION_GRAPH(state, data) {
    state.billPayConfirmationGraphData = data;
  },
  SET_BILL_PAY_GRAPH_LOADING_STATE(state, isLoading) {
    state.billPayConfirmationGraphLoading = isLoading;
  },
  SET_BILL_PAY_CLIENT_DEPOSIT(state, data) {
    state.billPayClientDeposit = data;
  },
  SET_BILL_PAY_GL(state, data) {
    state.billPayGl = data;
  },
  SET_BILL_PAY_ACCRUALS(state, data) {
    state.billPayAccruals = data;
  },
  SET_BILL_PAY_ACCRUALS_TOOL(state, data) {
    state.billPayAccrualsTool = data;
  },
  SET_BILL_PAY_GL_LIST(state, data) {
    state.billPayGlList = data;
  },
  SET_BILL_PAY_GL_FILE_ID_LIST(state, data) {
    state.billPayGlFileIdList = data;
  },
  SET_COMPANY_ID(state, id) {
    state.billPayCompanyId = id;
  },
  SET_TOTAL_DEPOSIT_AMOUNT(state, totalAmount) {
    state.totalDepositAmount = totalAmount;
  },
  SET_COMPANY_BILL_PAY_NOTES(state, data) {
    state.companyBillPayNotes = data;
  },
  SET_COMPANY_CONTROL_WORKBOOK_PDFS(state, data) {
    state.companyControlWorkbookPdfs = data;
  },
  SET_PDF_PATH_URL(state, data) {
    state.pdfPathUrl = data;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

const actions = {
  async setBillPayConfirmation({ commit, dispatch }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/billPayPaymentConfirmation?fromDate=${url.fromDate}&toDate=${url.toDate}`
          : `/billPayPaymentConfirmation?fromDate=${url.fromDate}&toDate=${url.toDate}`
      )
      .then(async (res) => {
        if (res.status === 200) {
          commit('SET_BILL_PAY_GRAPH_LOADING_STATE', true);
          let billPayPaymentConfirmation = res.data.data;
          let totalAmount = 0;
          let digitalPaymentsCount = 0;
          let checkPaymentsCount = 0;
          billPayPaymentConfirmation.map((billPayment) => {
            if (!Boolean(billPayment.services)) {
              billPayment.serviceIcons = billPayment.services;
            }
            billPayment.serviceIcons = replaceServiceType(
              billPayment.services,
              true
            );
            billPayment.services = replaceServiceType(billPayment.services);

            //Get total amount paid
            totalAmount = totalAmount + billPayment.amount;
            //Count Digital/Check payments and set non check payments as digital
            if (!Boolean(billPayment.paymentMethod.includes('CHECK'))) {
              billPayment.paymentMethod = 'DIGITAL';
              digitalPaymentsCount += 1;
            } else {
              checkPaymentsCount += 1;
            }
            return billPayment;
          });
          //sort Invoice date oldest to newest
          billPayPaymentConfirmation.sort((first, second) => {
            return new Date(first.invoiceDate) - new Date(second.invoiceDate);
          });
          await dispatch('setBpConrfirmationGraph', billPayPaymentConfirmation);
          await commit('SET_TOTAL_PAYMENT_CONFIRMATION_AMOUNT', totalAmount);
          await commit('SET_BILL_PAY_CONFIRMATION', billPayPaymentConfirmation);
          await commit('SET_DIGITAL_PAYMENTS_COUNT', digitalPaymentsCount);
          await commit('SET_CHECK_PAYMENTS_COUNT', checkPaymentsCount);
          commit('SET_COMPANY_ID', url.companyId);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  /***
   * Takes Bill Payments Confirmation Data to generate data used for pie charts
   */
  async setBpConrfirmationGraph({ commit }, bpConfirmationData) {
    const bpConfirmationGraphData = {};
    bpConfirmationData.forEach((data) => {
      if (!(data.paymentMethod in bpConfirmationGraphData)) {
        bpConfirmationGraphData[data.paymentMethod] = {
          paymentMethod: data.paymentMethod,
          totalAmount: data.amount
        };
      } else {
        bpConfirmationGraphData[data.paymentMethod].totalAmount =
          bpConfirmationGraphData[data.paymentMethod].totalAmount + data.amount;
      }
    });
    commit('SET_PAYMENT_CONFIRMATION_GRAPH', bpConfirmationGraphData);

    commit('SET_BILL_PAY_GRAPH_LOADING_STATE', false);
  },
  //BILL PAY DEPOSIT
  async setBillPayClientDeposit({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/clientDeposit?fromDate=${url.fromDate}&toDate=${url.toDate}`
          : `/clientDeposit?fromDate=${url.fromDate}&toDate=${url.toDate}`
      )
      .then((res) => {
        if (res.status === 200) {
          let deposits = {};
          let totalBatchAmount = 0;
          let totalMatchAmount = 0;
          let clientDeposit = res.data.data;
          clientDeposit.map((deposit) => {
            //Calculate totals
            totalBatchAmount = totalBatchAmount + deposit.batchAmount;
            totalMatchAmount = totalMatchAmount + deposit.matchedAmount;
            //Process data
            if (!(deposit.depositDate in deposits)) {
              deposits[deposit.depositDate] = {
                transactionAmount: deposit.transactionAmount,
                depositDate: deposit.depositDate,
                batchAmount: deposit.matchedAmount
              };
            } else {
              deposits[deposit.depositDate].batchAmount =
                deposits[deposit.depositDate].batchAmount +
                deposit.matchedAmount;
            }
            deposit.batchAmount = formatForGrid(deposit.batchAmount, '$');
            deposit.transactionAmount = formatForGrid(
              deposit.transactionAmount,
              '$'
            );

            return deposit;
          });

          let totalDepositAmount = 0;
          let actualDeposits = [];
          //stores the values within the deposit object to an array
          for (const key in deposits) {
            totalDepositAmount =
              totalDepositAmount + deposits[key].transactionAmount;
            actualDeposits.push(deposits[key]);
            deposits[key].batchAmount = formatForGrid(
              deposits[key].batchAmount,
              '$'
            );
          }
          //Sort data by deposit date oldest -> newest
          actualDeposits.sort((first, second) => {
            return new Date(first.depositDate) - new Date(second.depositDate);
          });
          commit('SET_TOTAL_MATCHED_AMOUNT', totalMatchAmount);
          commit('SET_TOTAL_DEPOSIT_AMOUNT', totalDepositAmount);
          commit('SET_BILL_PAY_CLIENT_DEPOSIT', actualDeposits);
          commit('SET_COMPANY_ID', url.companyId);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  //General Ledger Data with Payment Details
  async setBillPayGl({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/onDemandGl?fromDate=${url.fromDate}&toDate=${url.toDate}`
          : `/onDemandGl?fromDate=${url.fromDate}&toDate=${url.toDate}`
      )
      .then((res) => {
        if (res.status === 200) {
          const generalLedger = res.data.data.map((gl) => {
            gl['batch Date'] = formatDate(gl['batch Date']);
            gl['payment Status Date'] =
              gl['payment Status']?.toLowerCase() === 'cleared'
                ? formatDate(gl['payment Status Date'])
                : '';
            return gl;
          });
          commit('SET_BILL_PAY_GL', generalLedger);
          commit('SET_COMPANY_ID', url.companyId);
        }
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  //Get Accruals Data
  async setBillPayAccruals({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/billPayAccrual?companyId=${url.companyId}`
          : '/billPayAccrual'
      )
      .then((res) => {
        if (res.status === 200) {
          const accruals = res.data.data.map((accr) => {
            accr['serviceStartPeriod'] = formatDate(accr['serviceStartPeriod']);
            accr['invoiceDate'] = formatDate(accr['invoiceDate']);
            accr['serviceEndPeriod'] = formatDate(accr['serviceEndPeriod']);
            accr['lastBillReceived'] = formatDate(accr['lastBillReceived']);
            accr['lastBillPaid'] = formatDate(accr['lastBillPaid']);
            accr['batchDate'] = formatDate(accr['batchDate']);
            return accr;
          });
          commit('SET_BILL_PAY_ACCRUALS', accruals);
        }
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  //Get Accruals Tool Data
  async setBillPayAccrualsTool({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/billPayAccrual?companyId=${url.companyId}&fromReceiveDate=${url.fromDate}&toReceiveDate=${url.toDate}&fromLastBatchDate=${url.fromBatch}&toLastBatchDate=${url.toBatch}&fromLastBillDate=${url.fromBill}&toLastBillDate=${url.toBill}`
          : `/billPayAccrual?fromReceiveDate=${url.fromDate}&toReceiveDate=${url.toDate}&fromLastBatchDate=${url.fromBatch}&toLastBatchDate=${url.toBatch}&fromLastBillDate=${url.fromBill}&toLastBillDate=${url.toBill}`
      )
      .then((res) => {
        if (res.status === 200) {
          const accrualsTool = res.data.data.map((accrls) => {
            accrls['serviceStartPeriod'] = formatDate(
              accrls['serviceStartPeriod']
            );
            accrls['invoiceDate'] = formatDate(accrls['invoiceDate']);
            accrls['serviceEndPeriod'] = formatDate(accrls['serviceEndPeriod']);
            accrls['lastBillReceived'] = formatDate(accrls['lastBillReceived']);
            accrls['lastBillPaid'] = formatDate(accrls['lastBillPaid']);
            accrls['batchDate'] = formatDate(accrls['batchDate']);
            accrls['totalMinusAllCategoriesPerDay'] = formatCurrency(
              accrls.totalMinusAllCategories / (accrls.daysOfService || 1),
              'USD'
            );
            return accrls;
          });
          commit('SET_BILL_PAY_ACCRUALS_TOOL', accrualsTool);
        }
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },

  async setBillPayGlList({ commit }, url) {
    await axios
      .get(`/companies/${url.companyId}/onDemandGl/list`)
      .then((res) => {
        res.data.data.map((item) => {
          item.file_generated = formatDate(item.file_generated);
        });
        if (res.status === 200) {
          commit('SET_BILL_PAY_GL_LIST', res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  async setBillPayGlFileIdList({ commit }, url) {
    await axios
      .get(`/companies/${url.companyId}/onDemandGl/${url.glFileId}`)
      .then((res) => {
        if (res.status === 200) {
          commit('SET_BILL_PAY_GL_FILE_ID_LIST', res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  async downloadAccrualsData({ commit }, params) {
    await axios
      .get(
        params.isConsultant
          ? `/downloadBillPayAccrual?companyId=${params.companyId}&year=${params.year}&month=${params.month}`
          : `/downloadBillPayAccrual?year=${params.year}&month=${params.month}`,
        { responseType: 'blob' }
      )
      .then((res) => {
        let fileURL = window.URL.createObjectURL(new Blob([res.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', params.fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
        return res;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
  // Company Bill Pay Notes
  async setCompanyBillPayNotes({ commit }, companyId) {
    await axios
      .get(`/BillPayBillNotes/${companyId}`)
      .then((res) => {
        const companyBillPayNotes = res.data.data
          .sort((first, second) => {
            // Sort latest to oldest
            return new Date(second.invoiceDate) - new Date(first.invoiceDate);
          })
          .map((item) => {
            item.invoiceDueDate = formatDate(item.invoiceDueDate);
            item.invoiceDate = formatDate(item.invoiceDate);
            item.dateCreated = formatDate(item.dateCreated);
            item.resolvedDate = formatDate(item.resolvedDate);
            item.amountDue = formatForGrid(item.amountDue, '$');
            item.accountNumber = item.accountNumber ? item.accountNumber : '';
            return item;
          });
        commit('SET_COMPANY_BILL_PAY_NOTES', companyBillPayNotes);
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  },
  async setCompanyControlWorkbookPdfs({ commit }, companyId) {
    return await axios
      .get(`getControlWorkbookPdfs/${companyId}`)
      .then((res) => {
        const companyControlWorkbookPdfs = res.data.data
          .sort((first, second) => {
            return new Date(second.dateCreated) - new Date(first.dateCreated);
          })
          .map((item) => {
            item.dateCreated = setLocalTime(item.dateCreated);
            return item;
          });
        commit('SET_COMPANY_CONTROL_WORKBOOK_PDFS', companyControlWorkbookPdfs);
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  },
  async setPdfPathUrl({ commit }, id) {
    return await axios
      .get(`/downloadPdfAttachment?id=${id}`, {
        responseType: 'blob'
      })
      .then((res) => {
        commit('SET_PDF_PATH_URL', URL.createObjectURL(new Blob([res.data])));
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  },
  /**
   * Stores the file stream in Azure container and adds a new record in DB
   * @param {object} param - Parameters to be passed to the API
   */
  async saveAccrualsReport({ commit }, param) {
    const { companyId, reportData } = param;
    let api = `/accrualsReport`;
    if (companyId) {
      api += `?id=${companyId}`;
    }
    try {
      return await axios.post(api, reportData);
    } catch (error) {
      console.error(error);
      commit('SET_ERROR', error.status);
    }
  },
  /**
   * Delete the accrual excel file in Azure
   * @param {string} blobId - Blob Id of the excel file
   */
  async deleteAccrualsReportExcel({ commit }, blobId) {
    try {
      return await axios.delete(`/accrualsReport?blobId=${blobId}`);
    } catch (error) {
      console.error(error);
      commit('SET_ERROR', error.status);
    }
  }
};

const getters = {
  billPayConfirmation: (state) => {
    return state.billPayConfirmation;
  },
  billPayConfirmationGraphData: (state) => {
    return state.billPayConfirmationGraphData;
  },
  isbillPayConfirmationGraphLoading: (state) => {
    return state.billPayConfirmationGraphLoading;
  },
  billPayClientDeposit: (state) => {
    return state.billPayClientDeposit;
  },
  billPayGl: (state) => {
    return state.billPayGl;
  },
  billPayAccruals: (state) => {
    return state.billPayAccruals;
  },
  billPayAccrualsTool: (state) => {
    return state.billPayAccrualsTool;
  },
  billPayGlList: (state) => {
    return state.billPayGlList;
  },
  billPayGlFileIdList: (state) => {
    return state.billPayGlFileIdList;
  },
  getBillPayCompanyId: (state) => {
    return state.billPayCompanyId;
  },
  getTotalMatchedAmount: (state) => {
    return state.totalMatchedAmount;
  },
  getTotalDepositAmount: (state) => {
    return state.totalDepositAmount;
  },
  getTotalPaymentConfirmationAmount: (state) => {
    return state.totalPaymentConfirmationAmount;
  },
  getDigitalPaymentsCount: (state) => {
    return state.digitalPaymentsCount;
  },
  getCheckPaymentsCount: (state) => {
    return state.checkPaymentsCount;
  },
  getCompanyBillPayNotes: (state) => {
    return state.companyBillPayNotes;
  },
  getCompanyControlWorkbookPdfs: (state) => {
    return state.companyControlWorkbookPdfs;
  },
  getPdfPathUrl: (state) => {
    return state.pdfPathUrl;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
