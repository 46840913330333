import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import ConsultantLayout from 'src/pages/Layout/ConsultantLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';
import store from '../store/store';

const Login = () => import('src/pages/Pages/Login.vue');
const ForgotPassword = () => import('src/pages/Pages/ForgotPassword.vue');
const ResetPassword = () => import('src/pages/Pages/ResetPassword.vue');
const ClientHome = () => import('src/pages/ClientHome/ClientHome.vue');
const Locations = () => import('src/pages/Locations/Locations.vue');
const Location = () => import('src/pages/Locations/Location.vue');
const EnergyStar = () =>
  import('src/pages/Locations/EnergyStar/EnergyStar.vue');
const Accounts = () => import('src/pages/Accounts/Accounts.vue');
const Account = () => import('src/pages/Accounts/Account.vue');
const Alerts = () => import('src/pages/Alerts/Alerts.vue');
const AlertsRO = () => import('src/pages/Alerts/AlertsRO.vue');
const Bills = () => import('src/pages/Bills/Bills.vue');
const Bill = () => import('src/pages/Bills/Bill.vue');
const BillDeliveries = () =>
  import('src/pages/BillDeliveries/BillDeliveries.vue');
const Contracts = () => import('src/pages/Contracts/Contracts.vue');
const Reports = () => import('src/pages/Reports/Reports.vue');
const Users = () => import('src/pages/Users/Users.vue');
const Invite = () => import('src/pages/Invite/InviteUser.vue');
const Register = () => import('src/pages/Pages/Register.vue');
const BillPay = () => import('src/pages/BP/BillPay.vue');
const Budgets = () => import('src/pages/Budgets/Budgets.vue');
const OpenCloseAccounts = () =>
  import('src/pages/OpenClose/OpenCloseAccounts.vue');
const CompanyEnergyStar = () => import('src/pages/EnergyStar/EnergyStar.vue');
const SsoLogin = () => import('src/pages/SingleSignOn/SsoLogin.vue');
const SsoAcs = () => import('src/pages/SingleSignOn/SsoAcs.vue');
const TicketList = () => import('src/pages/CompanySupport/TicketList.vue');
const TicketDetail = () => import('src/pages/CompanySupport/TicketDetail.vue');
const isConsultant = () => {
  return store.getters.isConsultant;
};

let authPage = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/register/:id',
      name: 'Register',
      component: Register
    },
    {
      path: '/resetpassword/:id',
      name: 'Reset',
      component: ResetPassword
    },
    {
      path: '/forgotpassword',
      name: 'Forgot',
      component: ForgotPassword
    },
    {
      path: '/ssologin/:clientId',
      name: 'SSO Login',
      component: SsoLogin
    },
    {
      path: '/ssoacs/:clientId',
      name: 'SSO ACS',
      component: SsoAcs
    }
  ]
};

const Home = () => import('src/pages/Home/Home.vue');

let homePage = {
  path: '/',
  component: ConsultantLayout,
  redirect: '/consultant',
  name: 'Consultant Home',
  children: [
    {
      path: 'consultant',
      name: 'Consultant',
      components: { default: Home }
    }
  ]
};

let clientPage = {
  path: '/',
  component: AuthLayout,
  redirect: '/home',
  name: 'Client Home',
  children: [
    {
      path: 'home',
      name: 'Home',
      components: { default: ClientHome }
    }
  ]
};

let locationsPage = {
  path: '',
  component: DashboardLayout,
  name: 'Dashboard Locations Page',
  children: [
    {
      path: '/locations',
      name: 'Locations',
      components: { default: Locations }
    },
    {
      path: '/locations/:id',
      name: 'Location',
      components: { default: Location }
    },
    {
      path: '/locations/:id/energystar',
      name: 'Energy Star',
      components: { default: EnergyStar }
    },
    {
      path: '/companies/:compId/locations',
      name: 'Locations-Consultant',
      components: { default: Locations }
    },
    {
      path: '/companies/:compId/locations/:id',
      name: 'Location-Consultant',
      components: { default: Location }
    },
    {
      path: '/companies/:compId/locations/:id/energystar',
      name: 'Energy Star-Consultant',
      components: { default: EnergyStar }
    }
  ]
};

let accountsPage = {
  path: '/',
  component: DashboardLayout,
  redirect: '/accounts',
  name: 'Dashboard Accounts',
  children: [
    {
      path: 'accounts',
      name: 'Accounts',
      components: { default: Accounts }
    },
    {
      path: 'accounts/:id',
      name: 'Account',
      components: { default: Account }
    },
    {
      path: 'companies/:compId/accounts',
      name: 'Accounts-Consultant',
      components: { default: Accounts }
    },
    {
      path: 'companies/:compId/accounts/:id',
      name: 'Account-Consultant',
      components: { default: Account }
    }
  ]
};

let alertsPage = {
  path: '/',
  component: DashboardLayout,
  redirect: '/alerts',
  name: 'Dashboard Alerts',
  children: [
    {
      path: 'alerts',
      name: 'Alerts',
      components: { default: Alerts }
    },
    {
      path: 'companies/:compId/alerts',
      name: 'Alerts-Consultant',
      components: { default: Alerts }
    }
  ]
};

let alertsRoPage = {
  path: '/',
  component: DashboardLayout,
  redirect: '/alertsro',
  name: 'Dashboard Alerts Readonly',
  children: [
    {
      path: 'alertsro',
      name: 'Alerts ',
      components: { default: AlertsRO }
    },
    {
      path: 'companies/:compId/alertsro',
      name: 'Alerts-Consultant-Readonly',
      components: { default: AlertsRO }
    }
  ]
};

let billPayPage = {
  path: '/',
  component: DashboardLayout,
  redirect: '/billpay',
  name: 'Dashboard BP',
  children: [
    {
      path: 'billpay',
      name: 'Bill Pay',
      components: { default: BillPay }
    },
    {
      path: 'companies/:compId/billpay',
      name: 'Bill Pay-Consultant',
      components: { default: BillPay }
    }
  ]
};

let apPage = {
  path: '/',
  component: DashboardLayout,
  redirect: '/billdeliveries',
  name: 'Dashboard Payable',
  children: [
    {
      path: 'billdeliveries',
      name: 'Bill Deliveries',
      components: { default: BillDeliveries }
    },
    {
      path: 'companies/:compId/billdeliveries',
      name: 'Bill Deliveries-Consultant',
      components: { default: BillDeliveries }
    }
  ]
};

let billsPage = {
  path: '/',
  component: DashboardLayout,
  redirect: '/bills',
  name: 'Dashboard Bills',
  children: [
    {
      path: 'bills',
      name: 'Bills',
      components: { default: Bills }
    },
    {
      path: 'bills/:id',
      name: 'Bill',
      components: { default: Bill }
    },
    {
      path: 'companies/:compId/bills',
      name: 'Bills-Consultant',
      components: { default: Bills }
    },
    {
      path: 'companies/:compId/bills/:id',
      name: 'Bill-Consultant',
      components: { default: Bill }
    }
  ]
};

let contractsPage = {
  path: '/',
  component: DashboardLayout,
  redirect: '/contracts',
  name: 'Dashboard Contracts',
  children: [
    {
      path: 'contracts',
      name: 'Contracts',
      components: { default: Contracts }
    },
    {
      path: 'companies/:compId/contracts',
      name: 'Contracts-Consultant',
      components: { default: Contracts }
    }
  ]
};

let budgetsPage = {
  path: '/',
  component: DashboardLayout,
  redirect: '/budgets',
  name: 'Dashboard Budgets',
  children: [
    {
      path: 'budgets',
      name: 'Budgets',
      components: { default: Budgets }
    },
    {
      path: 'companies/:compId/budgets',
      name: 'Budgets-Consultant',
      components: { default: Budgets }
    }
  ]
};

let reportsPage = {
  path: '/',
  component: DashboardLayout,
  redirect: '/reports',
  name: 'Dashboard Reports',
  children: [
    {
      path: 'reports',
      name: 'Reports',
      components: { default: Reports }
    },
    {
      path: 'companies/:compId/reports',
      name: 'Reports-Consultant',
      components: { default: Reports }
    }
  ]
};

let openCloseAccountsPage = {
  path: '/',
  component: DashboardLayout,
  redirect: '/opencloseaccounts',
  name: 'Dashboard Open Close Accounts',
  children: [
    {
      path: 'companies/:compId/opencloseaccounts',
      name: 'Open/Close Accounts-Consultant',
      components: { default: OpenCloseAccounts }
    },
    {
      path: 'opencloseaccounts',
      name: 'Open/Close Accounts',
      components: { default: OpenCloseAccounts }
    }
  ]
};

let CompanyEnergyStarPage = {
  path: '/',
  component: DashboardLayout,
  redirect: '/companyEnergyStar',
  name: 'Dashboard Energy Star',
  children: [
    {
      path: 'companies/:compId/companyEnergyStar',
      name: 'Energy Star Dashboard-Consultant',
      components: { default: CompanyEnergyStar }
    },
    {
      path: 'companyEnergyStar',
      name: 'Energy Star Dashboard',
      components: { default: CompanyEnergyStar }
    }
  ]
};

let companySupportPage = {
  path: '/',
  component: DashboardLayout,
  redirect: '/tickets',
  name: 'Dashboard Company Support Tickets',
  children: [
    {
      path: 'tickets',
      name: 'Company Support',
      components: { default: TicketList }
    },
    {
      path: 'tickets/:jiraIssueId',
      name: 'Ticket Detail',
      components: { default: TicketDetail }
    },
    {
      path: 'companies/:compId/tickets',
      name: 'Company Support - Consultant',
      components: { default: TicketList }
    },
    {
      path: 'companies/:compId/tickets/:jiraIssueId',
      name: 'Ticket Detail - Consultant',
      components: { default: TicketDetail }
    }
  ]
};

let usersPage = {
  path: '/',
  component: DashboardLayout,
  redirect: '/users',
  name: 'Dashboard Users',
  children: [
    {
      path: 'companies/:compId/users',
      name: 'Users',
      components: { default: Users }
    }
  ]
};

let inviteUserPage = {
  path: '/',
  component: DashboardLayout,
  redirect: '/inviteuser',
  name: 'Dashboard inviteUser',
  children: [
    {
      path: 'inviteuser',
      name: 'Invite User',
      components: { default: Invite }
    },
    {
      path: 'companies/:compId/inviteuser',
      name: 'Invite User-Consultant',
      components: { default: Invite }
    }
  ]
};

const routes = [
  {
    path: '/',
    redirect: isConsultant ? '/consultant' : '/home',
    name: 'Main'
  },
  authPage,
  homePage,
  clientPage,
  locationsPage,
  accountsPage,
  alertsPage,
  alertsRoPage,
  apPage,
  billPayPage,
  billsPage,
  contractsPage,
  budgetsPage,
  reportsPage,
  usersPage,
  openCloseAccountsPage,
  CompanyEnergyStarPage,
  inviteUserPage,
  companySupportPage,
  { path: '*', component: NotFound }
];

export default routes;
