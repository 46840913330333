import axios from 'axios';
import jwt_decode from 'jwt-decode';
import router from '../../routes/router';

const state = {
  userInfo: {
    roles: null,
    userId: null
  },
  sessionInfo: {
    isAuthorized: false,
    expireIn: null
  },
  company: {
    id: null,
    name: ''
  },
  companyHasSupportAccess: false,
  token: null,
  error: null
};

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USER(state, data) {
    state.userInfo = data;
  },
  SET_SESSION(state, data) {
    state.sessionInfo = data;
  },
  SET_COMPANY(state, company) {
    state.company = company;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SAVED_LOCATION_GRID_SETTINGS(state, data) {
    state.savedLocationGridSettings = data;
  },
  SET_COMPANY_SUPPORT_ACCESS(state, data) {
    state.companyHasSupportAccess = data;
  }
};

const actions = {
  async setLogin({ commit }, infoData) {
    return await axios
      .post('/api/login', infoData)
      .then((res) => {
        if (res.status === 200) {
          handleLoginSuccess(commit, res);
        }
        return res;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
  async verifyLogin({ commit }, infoData) {
    const queryParams = new URLSearchParams(infoData).toString();
    return await axios
      .post(`/api/verify2fa?${queryParams}`)
      .then((res) => {
        if (res.status === 200) {
          handleLoginSuccess(commit, res);
        }
        return res;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
  async initiateSsoLogin({}, clientId) {
    try {
      const response = await axios.post(`/api/ssologin/${clientId}`);
      window.location.href = response.data.redirectUrl;
    } catch (error) {
      console.error('SSO login initiation failed:', error);
    }
  },
  async handleSsoAcs({ commit }, { clientId, samlResponse }) {
    try {
      const response = await axios.post(`/api/ssoacs/${clientId}`, {
        SAMLResponse: samlResponse
      });
      if (response.status === 200) {
        localStorage.setItem('ssoLoginPath', `/ssologin/${clientId}`);
        handleLoginSuccess(commit, response);
        return response;
      } else {
        console.error('SSO ACS handling failed:', response);
      }
    } catch (error) {
      console.error('SSO ACS handling failed:', error);
    }
  },
  async setRefreshAuth({ commit }) {
    return await axios
      .post('/api/refresh')
      .then((res) => {
        if (res.status === 200) {
          const today = new Date();
          let tokenData = jwt_decode(res.data.data.accessToken);
          const {
            'http://schemas.microsoft.com/ws/2008/06/identity/claims/role':
              roles,
            UserId: userId,
            CompanyId: companyId
          } = tokenData;

          commit('SET_TOKEN', res.data.data.accessToken);
          commit('SET_USER', { roles, userId });
          commit('SET_SESSION', {
            isAuthorized: true,
            expireIn: new Date(today.setMinutes(today.getMinutes() + 9))
          });
          axios.defaults.headers[
            'Authorization'
          ] = `Bearer ${res.data.data.accessToken}`;
          localStorage.setItem('isAuthorized', true);
        } else {
          localStorage.removeItem('isAuthorized');
          commit('SET_TOKEN', null);
          commit('SET_USER', { roles: null, userId: null });
          commit('SET_COMPANY', {});
          router.push('/login');
        }
        return res;
      })
      .catch((err) => {
        console.log('Error: ', err.response.statusText);
        commit('SET_ERROR', err.response.status);
        return err;
      });
  },
  async setLogout({ commit }) {
    await axios
      .post(`/api/logout`)
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem('isAuthorized');
          localStorage.removeItem('prevRoute');
          localStorage.removeItem('gridSettings');
          localStorage.removeItem('status');
          localStorage.removeItem('statusDate');
          commit('SET_TOKEN', null);
          commit('SET_USER', { roles: null, userId: null });
          commit('SET_COMPANY', {});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async setCompanySupportAccess({ commit, state }, compId) {
    if (compId === undefined && this.getters.isConsultant) {
      commit('SET_COMPANY_SUPPORT_ACCESS', false);
      return;
    }
    const companyId = Boolean(state.company.id) ? state.company.id : compId;
    await axios
      .get(`company_support/enabled/?companyId=${companyId}`)
      .then((res) => {
        commit('SET_COMPANY_SUPPORT_ACCESS', res.data.data);
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  async setCompany({ commit, state }, compId) {
    const companyId = Boolean(state.company.id) ? state.company.id : compId;
    if (compId === undefined && this.getters.isConsultant) {
      commit('SET_COMPANY', { id: null, name: '' });
      return;
    }
    await axios
      .get(`/companies/${companyId}`)
      .then((res) => {
        if (res.status === 200) {
          const company = res.data.data;
          if (!company) {
            return;
          }
          commit('SET_COMPANY', {
            id: company.id,
            parentId: company.parentId,
            name: company.name,
            sustainabilityAccess: company.sustainabilityAccess,
            billPayAccess: company.billPayAccess,
            apAccess: company.apAccess,
            budgetsAccess: company.budgetsAccess,
            alertsAccess: company.alertsAccess,
            energyStarAccess: company.energyStarAccess,
            openCloseAccess: company.openCloseAccess,
            accrualsAccess: company.accrualsAccess,
            weatherNormalizationAccess: company.weatherNormalizationAccess,
            isEsActive: company.isEsActive,
            companyHasSupportAccess: state.companyHasSupportAccess,
            susScopeTooltipAccess: company.susScopeTooltipAccess
          });
        }
      })
      .catch((err) => {
        console.log('Error: ', err.response.statusText);
        commit('SET_ERROR', err.response.status);
      });
  },
  async inviteUser({ commit }, data) {
    return await axios
      .post(`/api/invite`, data)
      .then((res) => {
        return res.status;
      })
      .catch((error) => {
        console.log(error);
        return error.status;
      });
  },
  async forgotPassword({ commit }, data) {
    return await axios
      .post(`/api/forgot_password`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.status;
      });
  },
  async registerUser({ commit }, data) {
    return await axios
      .post(`/api/register/${data.tokenId}`, data.user)
      .then((res) => {
        return res.status;
      })
      .catch((error) => {
        console.log(error);
        return error.status;
      });
  },
  async resetPassword({ commit }, data) {
    const newCredential = {
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword
    };
    return await axios
      .post(`/api/reset_password/${data.tokenId}`, newCredential)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error.status;
      });
  }
};

/**
 *  Handles the actions to be performed upon successful login. This includes:
 *  * Decoding the JWT access token
 *  * Updating the Vuex store with user, token, company, and session information
 *  * Setting the authorization header for subsequent API requests
 *  * Fetching and storing grid settings for the user
 * @param {Object} commit
 * @param {Object} res
 */
function handleLoginSuccess(commit, res) {
  const today = new Date();
  let tokenData = jwt_decode(res.data.data.accessToken);
  const {
    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': roles,
    UserId: userId,
    CompanyId: companyId
  } = tokenData;
  commit('SET_TOKEN', res.data.data.accessToken);
  commit('SET_USER', { roles, userId: userId });
  commit('SET_COMPANY', { id: companyId, name: '' });
  commit('SET_SESSION', {
    isAuthorized: true,
    expireIn: new Date(today.setMinutes(today.getMinutes() + 9))
  });
  localStorage.setItem('isAuthorized', true);
  axios.defaults.headers[
    'Authorization'
  ] = `Bearer ${res.data.data.accessToken}`;

  // call the table setting data
  axios
    .get('/user/allGridSettings')
    .then((res) => {
      let savedSettings = {};
      if (res.status === 200) {
        res.data.data.map((item) => {
          savedSettings[item.gridName] = item.gridSettings.split(',');
        });
        localStorage.setItem('gridSettings', JSON.stringify(savedSettings));
      } else {
        //creates an empty gridsettings for users with no saved grid settings
        localStorage.setItem('gridSettings', JSON.stringify(savedSettings));
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

const getters = {
  getToken: (state) => {
    return state.token;
  },
  isAuthorized: (state) => {
    return localStorage.getItem('isAuthorized') === 'true' ? true : false;
  },
  getExpirationDate: (state) => {
    return state.sessionInfo.expireIn;
  },
  isSuperConsultant: (state) => {
    return (
      state.userInfo.roles !== null &&
      (state.userInfo.roles.includes('super consultant') ||
        state.userInfo.roles.includes('Super Consultant'))
    );
  },
  isConsultant: (state) => {
    return (
      state.userInfo.roles !== null &&
      (state.userInfo.roles.includes('consultant') ||
        state.userInfo.roles.includes('Consultant'))
    );
  },
  isLimitedClient: (state) => {
    return (
      state.userInfo.roles !== null &&
      (state.userInfo.roles.includes('limited client') ||
        state.userInfo.roles.includes('Limited Client'))
    );
  },
  isAdmin: (state) => {
    return (
      state.userInfo.roles !== null &&
      (state.userInfo.roles.includes('admin') ||
        state.userInfo.roles.includes('Admin'))
    );
  },
  getUserLogged: (state) => {
    return state.userInfo;
  },
  getCompany: (state) => {
    return state.company;
  },
  getParentId: (state) => {
    return state.company.parentId;
  },
  getSavedLocationGridSettings: (state) => {
    return state.savedLocationGridSettings;
  },
  enableAlertsReadonly: () => {
    return false; // feature flag for alerts readonly page
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
