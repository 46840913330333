import axios from 'axios';
import {
  capitalizeFirstLetter,
  formatDate,
  formatForGrid,
  getMonthDifference,
  getDayDifference
} from '../../util/dataFormatting';
import {
  getContractStatus,
  getExtendedContractIcon
} from '../../pages/Contracts/contractsUtils';
import { deepCopy } from '@/components/Table/utils/index';

const state = {
  contracts: [],
  vendors: [],
  getConsultants: [],
  documents: [],
  companyDocumentsTypes: [],
  contractsFormOptions: [],
  associatedDocumentsTypes: [],
  linkedLocations: [],
  contractsLocations: [],
  locationTypes: [],
  documentData: [],
  contractData: [],
  extendedContractData: [],
  isContractsGridLoad: true,
  isDocumentsGridLoad: true,
  isContractsFormOptionsLoad: true,
  isEditContractFormLoad: true,
  isLinkedLocationsLoad: true,
  isContractsLocationsLoad: true,
  isLocationTypesLoad: true,
  submitContractLoading: false,
  error: null
};

const mutations = {
  SET_CONTRACTS(state, data) {
    state.contracts = data;
  },
  SET_VENDORS(state, data) {
    state.vendors = data;
  },
  SET_EXTENDED_CONTRACT_DATA(state, data) {
    state.extendedContractData = data;
  },
  SET_SUBMIT_CONTRACT_LOADING(state, data) {
    state.submitContractLoading = data;
  },
  SET_GET_CONSULTANTS(state, data) {
    state.getConsultants = data;
  },
  SET_DOCUMENTS(state, data) {
    state.documents = data;
  },
  SET_COMPANY_DOCUMENTS_TYPES(state, data) {
    state.companyDocumentsTypes = data;
  },
  SET_CONTRACTS_FORM_OPTIONS(state, data) {
    state.contractsFormOptions = data;
  },
  SET_ASSOCIATED_DOCUMENTS_TYPES(state, data) {
    state.associatedDocumentsTypes = data;
  },
  SET_LINKED_LOCATIONS(state, data) {
    state.linkedLocations = data;
  },
  SET_CONTRACTS_LOCATIONS(state, data) {
    state.contractsLocations = data;
  },
  SET_LOCATION_TYPES(state, data) {
    state.locationTypes = data;
  },
  SET_CONTRACT_DATA(state, data) {
    state.contractData = data;
  },
  SET_DOCUMENT_DATA(state, data) {
    state.documentData = data;
  },
  SET_CONTRACTS_LOAD(state, load) {
    state.isContractsGridLoad = load;
  },
  SET_DOCUMENTS_GRID_LOAD(state, load) {
    state.isDocumentsGridLoad = load;
  },
  SET_CONTRACTS_FORM_OPTIONS_LOAD(state, load) {
    state.isContractsFormOptionsLoad = load;
  },
  SET_EDIT_CONTRACT_FORM_LOAD(state, load) {
    state.isEditContractFormLoad = load;
  },
  SET_CONTRACTS_LOCATIONS_LOAD(state, load) {
    state.isContractsLocationsLoad = load;
  },
  SET_LOCATION_TYPES_LOAD(state, load) {
    state.isLocationTypesLoad = load;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

const actions = {
  async setContracts({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/contracts`
          : '/contracts'
      )
      .then((res) => {
        if (res.status === 200) {
          let contracts = res.data.data
            .map((contract) => {
              switch (contract.serviceType) {
                case 'electric':
                  contract.serviceType = 'fas fa-bolt';
                  contract.serviceUnit = 'kWh';
                  contract.commodity = 'Electric';
                  break;
                case 'natural_gas':
                  contract.serviceType = 'fad fa-fire';
                  contract.serviceUnit = 'therms';
                  contract.commodity = 'NG';
                  break;
                case 'water':
                  contract.serviceType = 'fad fa-tint';
                  contract.serviceUnit = 'gallons';
                  contract.commodity = 'Water';
                  break;
                case 'telecom':
                  contract.serviceType = 'fas fa-phone';
                  contract.commodity = 'Telecom';
                  break;
                case 'trash':
                  contract.serviceType = 'fad fa-trash-can';
                  contract.commodity = 'Trash';
                  break;
                case 'sewer':
                  contract.serviceType = 'fad fa-manhole';
                  contract.commodity = 'Sewer';
                  break;
              }
              if (!Boolean(contract.vendorName)) {
                contract.vendorName = '';
              }
              if (!Boolean(contract.serviceType)) {
                contract.serviceType = '';
              }

              contract.passedMonths =
                getMonthDifference(new Date(contract.startDate), new Date()) -
                1;

              contract.passedMonthsPercentage = parseInt(
                (contract.passedMonths / contract.termMonth) * 100
              );

              contract.remainingMonths = getMonthDifference(
                new Date(),
                new Date(contract.endDate)
              );

              contract.remainingMonthsPercentage = parseInt(
                (contract.remainingMonths / contract.termMonth) * 100
              );

              contract.totalDays = getDayDifference(
                contract.startDate,
                contract.endDate
              );

              contract.passedDays = getDayDifference(
                contract.startDate,
                new Date()
              );

              contract.passedDaysPercentage =
                (contract.passedDays / contract.totalDays) * 100;

              contract.remainingDaysPercentage =
                (contract.daysTillExpire / contract.totalDays) * 100;

              if (contract.serviceUnit !== 'kWh') {
                contract.serviceUnit = capitalizeFirstLetter(
                  contract.serviceUnit
                );
              }
              //GUID is used to generate unique contract id's. There is no NULL in Guid instead it appears as 00000-00.... Technically this means it's null
              contract.associatedContractId =
                contract.associatedContractId ===
                '00000000-0000-0000-0000-000000000000'
                  ? null
                  : contract.associatedContractId;
              contract.contractRate = Boolean(contract.contractRate)
                ? `$${contract.contractRate}`
                : null;
              contract.contractAnnualUsage = Boolean(
                contract.contractAnnualUsage
              )
                ? `${formatForGrid(contract.contractAnnualUsage)}`
                : null;
              contract = getContractStatus(contract);
              contract.isViewed = Boolean(contract.isViewed)
                ? 'fas fa-check'
                : '';
              //Mark extended contracts as checked
              contract.extendedIcon = Boolean(contract.associatedContractId)
                ? 'fas fa-check'
                : '';

              contract.consultantName = Boolean(contract.consultantName)
                ? contract.consultantName
                    .split(';')
                    .filter((item) => item !== '')
                    .join(', ')
                : contract.consultantName;
              return contract;
            })
            .filter((contract) => contract.isExtended !== true)
            .sort(
              (first, second) =>
                -first.startDate.localeCompare(second.startDate)
            );

          commit('SET_CONTRACTS', contracts);
        }
        commit('SET_CONTRACTS_LOAD', false);
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.response.status);
      });
  },
  async setVendors({ commit }) {
    await axios
      .get('/companies/getAllVendorsInfo')
      .then((res) => {
        commit('SET_VENDORS', res.data.data);
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  },
  async setGetConsultants({ commit }) {
    await axios
      .get('/companies/all_get_consultants')
      .then((res) => {
        commit('SET_GET_CONSULTANTS', res.data.data);
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.response.status);
      });
  },
  async setContractsFormOptions({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/contracts/info`
          : '/contracts/info'
      )
      .then((res) => {
        if (res.status === 200) {
          let contractsFormOptions = res.data.data;
          let serviceTypes = contractsFormOptions.serviceTypes;
          let uniqueTypes = [];

          serviceTypes.map((serviceType) => {
            serviceType.serviceName = capitalizeFirstLetter(
              serviceType.serviceName
            ).replace('Electric', 'Electricity');

            if (
              !uniqueTypes.find(
                (uniqueType) =>
                  uniqueType.serviceName === serviceType.serviceName
              )
            ) {
              uniqueTypes.push(serviceType);
            }

            return serviceType;
          });
          contractsFormOptions.serviceTypes = uniqueTypes;
          commit('SET_CONTRACTS_FORM_OPTIONS', contractsFormOptions);
          commit('SET_CONTRACTS_FORM_OPTIONS_LOAD', false);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.response.status);
      });
  },
  async submitSupplierContract({ commit }, formData) {
    commit('SET_SUBMIT_CONTRACT_LOADING', true);
    return await axios
      .post('/contracts/add', formData)
      .then((res) => {
        commit('SET_SUBMIT_CONTRACT_LOADING', false);
        return res.status;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        commit('SET_SUBMIT_CONTRACT_LOADING', false);
        console.log(err);
        return err.response.status;
      });
  },
  async setExtendedContractData({ commit }, contractId) {
    return await axios
      .get(`/contracts/${contractId}/extended`)
      .then((response) => {
        const extendedContractData = response.data.data.map((contract) => {
          contract.startDate = formatDate(contract.startDate) ?? null;
          contract.endDate = formatDate(contract.endDate) ?? null;
          contract.vendorName = contract.vendorName ?? '';
          contract.contractSignDate =
            formatDate(contract.contractSignDate) ?? null;
          // Perform the transformations for serviceType
          if (contract.serviceType) {
            contract.serviceType = capitalizeFirstLetter(
              contract.serviceType
                .replace('electric', 'Electricity')
                .replace('natural_gas', 'Natural Gas')
            );
          }

          // Transform contractRateType
          switch (contract.contractRateType) {
            case '0':
              contract.contractRateType = 'Fixed';
              break;
            case '1':
              contract.contractRateType = 'Not Fixed';
              break;
            case '2':
              contract.contractRateType = 'Variable';
              break;
          }

          return contract;
        });

        commit('SET_EXTENDED_CONTRACT_DATA', extendedContractData);
        return extendedContractData;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        console.log(err);
        return err.response.status;
      });
  },

  async setContractData({ commit }, url) {
    await axios
      .get(`/contracts/${url.contractId}`)
      .then((res) => {
        if (res.status === 200) {
          let contractData = res.data.data[0];

          contractData.startDate = formatDate(contractData.startDate);
          contractData.endDate = formatDate(contractData.endDate);
          contractData.vendorName = Boolean(contractData.vendorName)
            ? contractData.vendorName
            : '';

          if (contractData.contractSignDate !== '') {
            contractData.contractSignDate = formatDate(
              contractData.contractSignDate
            );
          }

          Boolean(contractData.serviceType)
            ? (contractData.serviceType = capitalizeFirstLetter(
                contractData.serviceType
                  .replace('electric', 'Electricity')
                  .replace('natural_gas', 'Natural Gas')
              ))
            : null;

          switch (contractData.contractRateType) {
            case '0':
              contractData.contractRateType = 'Fixed';
              break;
            case '1':
              contractData.contractRateType = 'Not Fixed';
              break;
            case '2':
              contractData.contractRateType = 'Variable';
              break;
          }

          if (contractData.associatedDocuments.length === 0) {
            contractData.associatedDocuments = 0;
          }
          commit('SET_CONTRACT_DATA', contractData);
          commit('SET_EDIT_CONTRACT_FORM_LOAD', false);
        }
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.response.status);
      });
  },
  async updateSupplierContract({ commit }, url) {
    commit('SET_SUBMIT_CONTRACT_LOADING', true);
    return await axios
      .put(`/contracts/${url.contractId}/update`, url.formData)
      .then((res) => {
        commit('SET_SUBMIT_CONTRACT_LOADING', false);
        return res.status;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        commit('SET_SUBMIT_CONTRACT_LOADING', false);
        console.log(err);
        return err.response.status;
      });
  },
  async extendSupplierContract({ commit }, url) {
    commit('SET_SUBMIT_CONTRACT_LOADING', true);
    return await axios
      .post(`/contracts/${url.contractId}/extend`, url.formData)
      .then((res) => {
        commit('SET_SUBMIT_CONTRACT_LOADING', false);
        return res.status;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        commit('SET_SUBMIT_CONTRACT_LOADING', false);
        console.log(err);
        return err.response.status;
      });
  },
  async setDocuments({ commit }, url) {
    await axios
      .get(
        url.associatedDocument
          ? `/contracts/${url.contractId}/associated_documents`
          : url.isConsultant
          ? `/companies/${url.companyId}/contracts/company_documents`
          : '/contracts/company_documents'
      )
      .then((res) => {
        if (res.status === 200) {
          let documents = res.data.data;
          commit('SET_DOCUMENTS', documents);
          commit('SET_DOCUMENTS_GRID_LOAD', false);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.response.status);
      });
  },
  async setCompanyDocumentsTypes({ commit }) {
    await axios
      .get('/contracts/company_documents/types')
      .then((res) => {
        if (res.status === 200) {
          let documentsTypes = res.data.data;
          commit('SET_COMPANY_DOCUMENTS_TYPES', documentsTypes);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.response.status);
      });
  },
  async setAssociatedDocumentsTypes({ commit }) {
    await axios
      .get('/contracts/associated_documents/types')
      .then((res) => {
        if (res.status === 200) {
          let documentsTypes = res.data.data;
          commit('SET_ASSOCIATED_DOCUMENTS_TYPES', documentsTypes);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.response.status);
      });
  },
  async setDocumentData({ commit }, url) {
    return await axios
      .get(
        url.associatedDocument
          ? `/contracts/associated_documents/${url.documentId}`
          : `/contracts/company_documents/${url.documentId}`
      )
      .then((res) => {
        if (res.status === 200) {
          return url.associatedDocument ? res.data.data : res.data.data[0];
        }
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.response.status);
      });
  },
  async submitDocument({ commit }, url) {
    return await axios
      .post(
        url.associatedDocument
          ? '/contracts/associated_documents/add'
          : '/contracts/company_documents/add',
        url.formData
      )
      .then((res) => {
        return res.status;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        console.log(err);
        return err.response.status;
      });
  },
  async updateDocument({ commit }, url) {
    return await axios
      .put(
        url.associatedDocument
          ? `/contracts/associated_documents/${url.documentId}/update`
          : `/contracts/company_documents/${url.documentId}/update`,
        url.formData
      )
      .then((res) => {
        return res.status;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        console.log(err);
        return err.response.status;
      });
  },
  async deleteDocument({ commit }, url) {
    return await axios
      .delete(
        url.associatedDocument
          ? `/contracts/associated_documents/${url.documentId}/delete`
          : `/contracts/company_documents/${url.documentId}/delete`
      )
      .then((res) => {
        return res.status;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        console.log(err);
        return err.response.status;
      });
  },
  async setContractsLocations({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/locations`
          : '/locations'
      )
      .then((res) => {
        if (res.status === 200) {
          const contractsLocations = deepCopy(res.data.data).sort(
            (first, second) => {
              return Number(first.number) - Number(second.number);
            }
          );
          commit('SET_CONTRACTS_LOCATIONS', contractsLocations);
          commit('SET_CONTRACTS_LOCATIONS_LOAD', false);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.response.status);
      });
  },
  async setLinkedLocations({ commit }, url) {
    return await axios
      .get(`/contracts/${url.contractId}/linked_locations`)
      .then((res) => {
        if (res.status === 200) {
          commit('SET_LINKED_LOCATIONS', res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.response.status);
      });
  },
  async linkLocation({ commit }, formData) {
    return await axios
      .post('/contracts/linked_locations/link', formData)
      .then((res) => {
        return res.status;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        console.log(err);
        return err.response.status;
      });
  },
  async unlinkLocation({ commit }, formData) {
    return await axios
      .post('/contracts/linked_locations/unlink', formData)
      .then((res) => {
        return res.status;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        console.log(err);
        return err.response.status;
      });
  },
  async setLocationTypes({ commit }) {
    await axios
      .get('/locations/site_type')
      .then((res) => {
        if (res.status === 200) {
          let locationTypes = res.data.data
            .map((location) => {
              if (location.name[0] === ' ') {
                location.name = location.name.slice(1);
              }
              return location;
            })
            .sort((first, second) => (first.name > second.name ? 1 : -1));
          commit('SET_LOCATION_TYPES', locationTypes);
          commit('SET_LOCATION_TYPES_LOAD', false);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.response.status);
      });
  },
  async setContractsLoads({ commit }) {
    commit('SET_CONTRACTS_LOAD', true);
    commit('SET_CONTRACTS_FORM_OPTIONS_LOAD', true);
    commit('SET_CONTRACTS_LOCATIONS_LOAD', true);
    commit('SET_LOCATION_TYPES_LOAD', true);
    commit('SET_EDIT_CONTRACT_FORM_LOAD', true);
  },
  async deleteContract({ commit }, contractId) {
    return await axios
      .delete(`/contracts/delete/`, { params: { id: contractId } })
      .then((res) => {
        return res.status;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        console.log(err);
        return err.response.status;
      });
  },
  async addNewLocation({ commit }, formData) {
    return await axios
      .post('/locations', formData)
      .then((res) => {
        return res.status;
      })
      .catch((err) => {
        commit('SET_ERROR', err.response.status);
        console.log(err);
        return err.response.status;
      });
  },
  async setContractsLoads({ commit }) {
    commit('SET_CONTRACTS_LOAD', true);
    commit('SET_CONTRACTS_FORM_OPTIONS_LOAD', true);
    commit('SET_CONTRACTS_LOCATIONS_LOAD', true);
    commit('SET_LOCATION_TYPES_LOAD', true);
    commit('SET_EDIT_CONTRACT_FORM_LOAD', true);
  },
  async markContractAsViewed({ commit }, contract) {
    return await axios
      .put(`contracts/${contract.id}/update`, contract)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
};

const getters = {
  contracts: (state) => {
    return state.contracts;
  },
  vendors: (state) => {
    return state.vendors;
  },
  submitContractLoadingState: (state) => {
    return state.submitContractLoading;
  },
  getConsultants: (state) => {
    return state.getConsultants;
  },
  documents: (state) => {
    return state.documents;
  },
  companyDocumentsTypes: (state) => {
    return state.companyDocumentsTypes;
  },
  contractsFormOptions: (state) => {
    return state.contractsFormOptions;
  },
  associatedDocumentsTypes: (state) => {
    return state.associatedDocumentsTypes;
  },
  linkedLocations: (state) => {
    return state.linkedLocations;
  },
  contractsLocations: (state) => {
    return state.contractsLocations;
  },
  locationTypes: (state) => {
    return state.locationTypes;
  },
  contractData: (state) => {
    return state.contractData;
  },
  extendedContractData: (state) => {
    return state.extendedContractData;
  },
  documentData: (state) => {
    return state.documentData;
  },
  isContractsGridLoad: (state) => {
    return state.isContractsGridLoad;
  },
  isDocumentsGridLoad: (state) => {
    return state.isDocumentsGridLoad;
  },
  isContractsFormOptionsLoad: (state) => {
    return state.isContractsFormOptionsLoad;
  },
  isContractsLocationsLoad: (state) => {
    return state.isContractsLocationsLoad;
  },
  isLocationTypesLoad: (state) => {
    return state.isLocationTypesLoad;
  },
  isEditContractFormLoad: (state) => {
    return state.isEditContractFormLoad;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
